<template>
  <div id="howtobuy" >
    <v-slide-y-transition mode="out-in">
      <v-layout column >
       <!-- ####################### SKELETON LOADERS ####################### -->
       <v-layout column v-if="loading">
         <p class="mt-0"></p>

         <v-layout column >

           <v-layout >
             <v-spacer></v-spacer>
             <v-skeleton-loader
               class="mx-auto"
               type="image"
               width="300"
               height="50"
             ></v-skeleton-loader>

             <v-spacer></v-spacer>
           </v-layout >

            <p class="mt-0"></p>

           <v-spacer></v-spacer>
             <template v-for="n in 3" >
               <v-skeleton-loader
                 class="mx-auto"
                 type="card"
                 width="100%"
                 :key="n"
               ></v-skeleton-loader>
               <v-spacer :key="n + 'b'"></v-spacer>
             </template>
           <v-spacer></v-spacer>
         </v-layout>

       </v-layout>

       <!-- ############################################## COMPONENTS ##############################################  -->

         <v-layout column align-center v-else>
           <!-- v-show="!loadingData" -->
           <p class="mt-2"></p>
           <p v-if="scrollY > 0" class="mt-0"></p>
           <p v-if="scrollY > 0" class="mt-4"></p>

           <v-sheet
             class="d-flex"
             :dark="dark"
             :color="dark ? '#314141' : '#badadb'"
             style="width: 100%;height: auto;"
             tile
             >
             <v-layout column align-center :style="windowWidth < 770 ? 'margin-left:5%;margin-right:5%' : 'margin-left:25%;margin-right:25%'">
                 <p class="mt-4"></p>
                 <p class="mt-4"></p>
                 <p class="howToTitle">How to buy Token Monster (TMO) guide.</p>
                 <p class="mt-2"></p>
                 <p class="howToTitleText">Token Monster is here to support it's users and newomers. We have created this guide to offer help for those who need assistance in how to setp your wallet, purchase BNB and finally swap BNB to TMO Tokens. <br><br>
                   Let's start from the beginning. Follow the guide below step by step.

                 </p>
                 <p class="mt-2"></p>
            </v-layout>
           </v-sheet>


           <!-- ####################### STEP 1  ####################### -->

           <v-sheet
             class="d-flex"
             :dark="dark"
             :color="dark ? '#1d4b1f' : '#66BB6A'"
             style="width: 100%;height: auto;"
             tile
             >
             <v-layout column align-center :style="windowWidth < 770 ? 'margin-left:5%;margin-right:5%' : 'margin-left:25%;margin-right:25%'">
                 <p class="mt-4"></p>
                 <p class="mt-4"></p>
                 <p class="howToTitle">Step 1</p>
                 <p class="mt-2"></p>
                 <p class="howToTitleText">First and foremost you will need to have a wallet. This is where you will store all your crypto.
                   The two most popular and reliable options are MetaMask, Binance Chain Wallet and TrustWallet (for mobile devices). <br><br>
                   Links are provided for you below.
                 </p>
                 <p class="mt-2"></p>

                 <v-layout style="width:100%">

                   <v-layout column align-center>
                     <v-btn fab x-large @click="openLink('https://metamask.io/download.html')">
                       <img src="/img/icons/metamask.png" style="max-width:50px;max-height:50px;"/>
                     </v-btn>
                     <p class="mt-0"></p>
                     <span :style="windowWidth < 770 ? 'font-size:12px' : ''" class="font-weight-bold">MetaMask</span>
                   </v-layout>

                   <v-spacer ></v-spacer>

                   <v-layout column align-center>
                     <v-btn fab x-large @click="openLink('https://chrome.google.com/webstore/detail/binance-chain-wallet/fhbohimaelbohpjbbldcngcnapndodjp')">
                       <img src="/img/icons/binance.png" style="max-width:50px;max-height:50px;"/>
                     </v-btn>
                     <p class="mt-0"></p>
                     <span :style="windowWidth < 770 ? 'font-size:12px' : ''" class="font-weight-bold">Binance Chain Wallet</span>
                   </v-layout>

                   <v-spacer ></v-spacer>

                   <v-layout column align-center>
                     <v-btn fab x-large @click="openLink('https://trustwallet.com/')">
                       <img src="/img/icons/trustwallet.png" style="max-width:50px;max-height:50px;"/>
                     </v-btn>
                     <p class="mt-0"></p>
                     <span :style="windowWidth < 770 ? 'font-size:12px' : ''" class="font-weight-bold">Trust Wallet</span>
                   </v-layout>

                 </v-layout>

                 <p class="mt-4"></p>
            </v-layout>
           </v-sheet>

           <!-- ####################### STEP 2  ####################### -->

           <v-sheet
             class="d-flex"
             :dark="dark"
             :color="dark ? '#314141' : '#badadb'"
             style="width: 100%;height: auto;"
             tile
             >
             <v-layout column align-center :style="windowWidth < 770 ? 'margin-left:5%;margin-right:5%' : 'margin-left:25%;margin-right:25%'">
               <p class="mt-4"></p>
               <p class="mt-4"></p>
               <p class="howToTitle">Step 2</p>
               <p class="mt-2"></p>
               <p class="howToTitleText">Since Token Monster (TMO) trades on the Binance Smart Chain (BSC), we will need to connect your wallet to the BSC Mainnet.<br><br>
                 Below are the links which will lead you to a fast guide on how to connect the wallet you chose to the BSC Mainnet.<br><br>
               </p>
               <v-layout style="width:100%">

                 <v-layout column align-center>
                   <v-btn fab x-large @click="openLink('https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain')">
                     <img src="/img/icons/metamask.png" style="max-width:50px;max-height:50px;"/>
                   </v-btn>
                   <p class="mt-0"></p>
                   <span :style="windowWidth < 770 ? 'font-size:12px' : ''" class="font-weight-bold">MetaMask</span>
                 </v-layout>

                 <v-spacer ></v-spacer>

                 <v-layout column align-center>
                   <v-btn fab x-large @click="openLink('https://academy.binance.com/en/articles/how-to-use-binance-chain-wallet')">
                     <img src="/img/icons/binance.png" style="max-width:50px;max-height:50px;"/>
                   </v-btn>
                   <p class="mt-0"></p>
                   <span :style="windowWidth < 770 ? 'font-size:12px' : ''" class="font-weight-bold">Binance Chain Wallet</span>
                 </v-layout>

                 <v-spacer ></v-spacer>

                 <v-layout column align-center>
                   <v-btn fab x-large @click="openLink('https://www.binance.com/en/blog/421499824684901157/How-to-Set-Up-and-Use-Trust-Wallet-for-Binance-Smart-Chain')">
                     <img src="/img/icons/trustwallet.png" style="max-width:50px;max-height:50px;"/>
                   </v-btn>
                   <p class="mt-0"></p>
                   <span :style="windowWidth < 770 ? 'font-size:12px' : ''" class="font-weight-bold">Trust Wallet</span>
                 </v-layout>

               </v-layout>

               <p class="mt-4"></p>

               <p class="howToTitleText">If you have installed and connected with Metamask in Chrome or Brave you can use the fast Configuration link below.<br>
                 This will add the BSC Mainnet to MetaMask Networks with the click of a button.
               </p>
               <v-btn style="width:260px;" color="green lighten-5" x-large @click="addBSCNetwork">
                 <v-icon left >mdi-plus</v-icon>ADD BSC NETWORK
               </v-btn>
               <p class="mt-4"></p>
               <p class="howToTitleText">
                 Note: This works for Desktop browsers Chrome and Brave only.<br>
                 For adding BSC Mainnet to your mobile apps MetaMask or TrustWallet, please see the links above.
               </p>
               <p class="mt-4"></p>
            </v-layout>
           </v-sheet>

           <!-- ####################### STEP 3  ####################### -->

           <v-sheet
             class="d-flex"
             :dark="dark"
             :color="dark ? '#1d4b1f' : '#66BB6A'"
             style="width: 100%;height: auto;"
             tile
             >
             <v-layout column align-center :style="windowWidth < 770 ? 'margin-left:5%;margin-right:5%' : 'margin-left:25%;margin-right:25%'">
               <p class="mt-4"></p>
               <p class="mt-4"></p>
               <p class="howToTitle">Step 3</p>
               <p class="mt-2"></p>
               <p class="howToTitleText">Finally we are ready for the cool stuff. Now that you have a wallet that is connected to the BSC Mainnet, you will need to fund your wallet with Binance Coin (BNB) to purchase Token Monster (TMO). There are several ways to get BNB:<br>
               </p>

               <v-alert
                  outlined
                  text
                >
                 <span style="font-size:20px" class="font-weight-bold">1. Send BNB from another exchange into your wallet</span>
               </v-alert>
               <p class="howToTitleText">
                 To send BNB, you will first have to know your wallet address.<br>
                 For MetaMask, the address is located right at the top as shown in screenshot.
               </p>

               <v-img src="img/howtobuy/metamask_address.png" width="293" height="400">
               </v-img>

               <p class="mt-4"></p>

               <p class="howToTitleText">
                 For Binance Chain Wallet, the address is located in the red area. You can copy the address by clicking the yellow button/icon.
               </p>
               <v-img src="img/howtobuy/binance_address.png" width="293" height="346">
               </v-img>

               <p class="mt-4"></p>

               <p class="howToTitleText">
                With TrustWallet, you will first have to tap 'Receive', then select BNB and finally your wallet address will appear.
               </p>
               <v-img src="img/howtobuy/trustwallet_address.png" width="360" height="351">
               </v-img>
               <p class="mt-4"></p>
               <v-alert
                 dense
                 outlined
                 type="error"
               >
                 Important Notice
               </v-alert>
               <p class="howToTitleText">
                 BNB is a BSC/BEP20 Token.<br>
                 Make sure you are sending BSC/BEP20 only to your wallet adress, else funds will be lost!
               </p>

               <p class="mt-4"></p>

               <v-alert
                  outlined
                  text
                >
                <span style="font-size:20px" class="font-weight-bold">2. Buy BNB direct to your wallet with Credit Card</span>
               </v-alert>
               <p class="howToTitleText">
                 If you want to purchase BNB directly to your wallet with a Credit Card, please follow the instructions below.<br><br>
                 Select your preferred currency and there will be the option to do Bank Transfer or a CC purchase (depending on the currency you have selected).<br>
                 Make Sure you select BNB from the BSC NETWORK then select BUY NOW and follow the verification and information steps.<br><br>
                 <a href="https://global.transak.com/" target="_blank">Purchase BNB Now</a>
               </p>

               <v-layout row wrap pa-2>
                 <v-img src="img/howtobuy/transak_01.png" width="360" height="448">
                 </v-img>
                 <p style="width:10px"></p>
                 <v-img :style="windowWidth < 770 ? 'margin-top:10px' : 'marginleft:10px'" src="img/howtobuy/transak_02.png" width="360" height="448">
                 </v-img>
              </v-layout>

                <p class="mt-4"></p>
             </v-layout>
           </v-sheet>


           <!-- ####################### STEP 4  ####################### -->

           <v-sheet
             class="d-flex"
             :dark="dark"
             :color="dark ? '#314141' : '#badadb'"
             style="width: 100%;height: auto;"
             tile
             >
             <v-layout column align-center :style="windowWidth < 770 ? 'margin-left:5%;margin-right:5%' : 'margin-left:15%;margin-right:15%'">
               <p class="mt-4"></p>
               <p class="mt-4"></p>
               <p class="howToTitle">Step 4</p>
               <p class="mt-2"></p>
               <p class="howToTitleText">Now that you have BNB in your wallet, it is time to exchange it for Token Monster (TMO).<br><br>
                 Using PancakeSwap with MetaMask is by far the easiest. The links below will show how to connect and swap with PancakeSwap.<br>
               </p>

               <v-alert
                  outlined
                  text
                >
                 <span style="font-size:20px" class="font-weight-bold">With Browser: Connect your Wallet to PancakeSwap</span>
               </v-alert>

               <v-layout style="width:100%">
                 <v-layout column align-center>
                   <v-btn fab x-large @click="openLink('https://docs.pancakeswap.finance/get-started/connection-guide')">
                     <img src="/img/icons/pancakeswap.png" style="max-width:50px;max-height:50px;"/>
                   </v-btn>
                   <p class="mt-0"></p>
                   <span class="font-weight-bold">PancakeSwap Connection Guide</span>
                 </v-layout>
               </v-layout>

               <p class="mt-4"></p>

               <v-alert
                  outlined
                  text
                >
                 <span style="font-size:20px" class="font-weight-bold">With Browser: Swap BNB to TMO</span>
               </v-alert>

               <p class="howToTitleText">Now that you are connected PancakeSwap with your wallet of choice, it's time to swap some BNB to TMO.<br><br>
                  In order to Swap top the to PancakeSwap Exchange. The link Below will add the TMO to your saves tokens List.
               </p>

               <v-layout style="width:100%">
                 <v-layout column align-center>
                   <v-btn fab x-large @click="openLink('https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x49c248641eb904c9d5a33904d388212a89218187')">
                     <img src="/img/icons/pancakeswap_xch.png" style="max-width:50px;max-height:50px;"/>
                   </v-btn>
                   <p class="mt-0"></p>
                   <span class="font-weight-bold">PancakeSwap Exchange</span>
                 </v-layout>
               </v-layout>

               <p class="mt-2"></p>

               <p class="howToTitleText">You will see a popup like the window below. Check "I understand" and Click import.
               </p>

                <p class="mt-2"></p>

               <v-img src="img/howtobuy/pancakeswap_01.png" width="360" height="448">
               </v-img>

               <p class="mt-4"></p>
               <p class="howToTitleText">Now that you have added TMO to PancakeSwap you are ready to swap BNB to TMO. <br><br>
                 Enter the amount of BNB you wan to swap if the "From" Field<br>
                 OR<br>
                 Enter the amount of TMO Tokens you wan to swap if the "To" Field<br>
                 PancakeSwap will calculate and the SWAP Button will be enabled.

               </p>
               <p class="mt-2"></p>

                <v-img src="img/howtobuy/pancakeswap_02.png" width="360" height="448">
                </v-img>

                <p class="mt-4"></p>

                <v-alert
                   outlined
                   text
                 >
                  <span style="font-size:20px" class="font-weight-bold">With Mobile: Connect your Wallet to PancakeSwap</span>
                </v-alert>

                <v-img src="img/icons/metamask_full.png" width="300" height="93">
                </v-img>

                <p class="howToTitleText">To purchase Token Monster (TMO) with MetaMask, we will go to PancakeSwap via the InApp browser. Below are a series of screenshots to help navigate on the mobile device.
                </p>

                <v-layout row wrap pa-2 :style="windowWidth < 770 ? 'margin-left:40px' : ''">
                  <v-img src="img/howtobuy/mobile_metamask_01.jpeg" max-width="213" max-height="450">
                  </v-img>
                  <p style="width:20px"></p>
                  <v-img :style="windowWidth < 770 ? 'margin-top:10px' : 'marginleft:10px'" src="img/howtobuy/mobile_metamask_02.jpeg" max-width="213" max-height="450">
                  </v-img>
                  <p style="width:20px"></p>
                  <v-img :style="windowWidth < 770 ? 'margin-top:10px' : 'marginleft:10px'" src="img/howtobuy/mobile_metamask_03.jpeg" max-width="213" max-height="450">
                  </v-img>
                  <p style="width:20px"></p>
                  <v-img :style="windowWidth < 770 ? 'margin-top:10px' : 'marginleft:10px'" src="img/howtobuy/mobile_metamask_04.jpeg" max-width="213" max-height="450">
                  </v-img>
               </v-layout>

               <v-layout row wrap pa-2 :style="windowWidth < 770 ? 'margin-left:40px' : ''">
                 <v-img src="img/howtobuy/mobile_metamask_05.jpeg" max-width="213" max-height="450">
                 </v-img>
                 <p style="width:20px"></p>
                 <v-img :style="windowWidth < 770 ? 'margin-top:10px' : 'marginleft:10px'" src="img/howtobuy/mobile_metamask_06.jpeg" max-width="213" max-height="450">
                 </v-img>
                 <p style="width:20px"></p>
                 <v-img :style="windowWidth < 770 ? 'margin-top:10px' : 'marginleft:10px'" src="img/howtobuy/mobile_metamask_07.jpeg" max-width="213" max-height="450">
                 </v-img>
              </v-layout>

                <p class="mt-4"></p>

                <v-img src="img/icons/trustwallet_full.png" width="300" height="136">
                </v-img>

                <p class="howToTitleText">To purchase Token Monster (TMO) with TrustWallet, we will go to PancakeSwap via the InApp DApps browser. Below are a series of screenshots to help navigate on the mobile device.
                </p>


                <v-layout row wrap pa-2 :style="windowWidth < 770 ? 'margin-left:40px' : ''">
                  <v-img src="img/howtobuy/mobile_trustwallet_01.jpeg" max-width="213" max-height="450">
                  </v-img>
                  <p style="width:20px"></p>
                  <v-img :style="windowWidth < 770 ? 'margin-top:10px' : 'marginleft:10px'" src="img/howtobuy/mobile_trustwallet_02.jpeg" max-width="213" max-height="450">
                  </v-img>
                  <p style="width:20px"></p>
                  <v-img :style="windowWidth < 770 ? 'margin-top:10px' : 'marginleft:10px'" src="img/howtobuy/mobile_trustwallet_03.jpeg" max-width="213" max-height="450">
                  </v-img>
               </v-layout>

               <v-layout row wrap pa-2 :style="windowWidth < 770 ? 'margin-left:40px' : ''">
                 <v-img src="img/howtobuy/mobile_trustwallet_04.jpeg" max-width="213" max-height="450">
                 </v-img>
                 <p style="width:20px"></p>
                 <v-img :style="windowWidth < 770 ? 'margin-top:10px' : 'marginleft:10px'" src="img/howtobuy/mobile_trustwallet_05.jpeg" max-width="213" max-height="450">
                 </v-img>
                 <p style="width:20px"></p>
                 <v-img :style="windowWidth < 770 ? 'margin-top:10px' : 'marginleft:10px'" src="img/howtobuy/mobile_trustwallet_06.jpeg" max-width="213" max-height="450">
                 </v-img>
              </v-layout>

               <p class="mt-4"></p>
             </v-layout>
           </v-sheet>


           <!-- ####################### PancakeSwap Errors  ####################### -->

           <v-sheet
             class="d-flex"
             :dark="dark"
             :color="dark ? '#1d4b1f' : '#66BB6A'"
             style="width: 100%;height: auto;"
             tile
             >
             <v-layout column align-center :style="windowWidth < 770 ? 'margin-left:5%;margin-right:5%' : 'margin-left:25%;margin-right:25%'">
               <p class="mt-4"></p>
               <p class="mt-4"></p>
               <p class="howToTitle">Known PancakeSwap Errors and how to fix them.</p>
               <p class="mt-2"></p>
               <p class="howToTitleText">
                 PancakeSwap has compiled a list of errors that can occur during the buy or sell of tokens.<br><br>
                 If you experience an error please click the button below to find a possible solution.
               </p>

              <p class="mt-4"></p>

               <v-btn style="width:300px;" color="green lighten-5" x-large @click="openLink('https://docs.pancakeswap.finance/help/troubleshooting')">
                 <img src="/img/icons/pancakeswap_xch.png" style="max-width:32px;max-height:32px;margin-right:10px"/>PancakeSwap Errors
               </v-btn>

              <p class="mt-4"></p>
              <p class="mt-4"></p>

            </v-layout>
           </v-sheet>


          <!-- <v-bottom-navigation v-if="showBottomNav"
            :input-value="showBottomNav"
            color="teal accent-4"
            background-color="teal lighten-5"
            fixed
          >

          <v-btn to="/news" style="padding-left:20px;padding-right:0px">
            <span>News</span>
            <v-icon>mdi-newspaper-variant-multiple-outline</v-icon>
          </v-btn>

          <v-btn to="/videos" style="padding-right:0px;padding-left:0px">
            <span>Videos</span>
            <v-icon>mdi-play-circle-outline</v-icon>
          </v-btn>

          <v-btn to="/" style="padding-right:0px;padding-left:0px">
            <span>Home</span>
            <v-icon>mdi-soccer</v-icon>
          </v-btn>

          <v-btn to="/matches" style="padding-right:0px;padding-left:0px">
            <span>Matches</span>
            <v-icon>mdi-soccer-field</v-icon>
          </v-btn>

          <v-btn to="/scorecards" style="padding-right:20px;padding-left:0px">
            <span>Scores</span>
            <v-icon>mdi-counter</v-icon>
          </v-btn>
        </v-bottom-navigation> -->

         </v-layout>

         <!-- ############################################ DIALOGS ################################################# -->

      </v-layout>
    </v-slide-y-transition>
  </div>
</template>

<script>
import store from '@/store/index'
import {scroller} from 'vue-scrollto/src/scrollTo'
export default {
  name: 'howtobuy',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    isMobileDevice: Boolean,
    scrollY: Number
  },
  data () {
    return {
      lang: this.$store.state.lang,
      loading: true,
      showBottomNav: false,
      toggle: 'left',
    }
  },
  components: {
  },
  watch: {
    windowWidth () {
      if (this.windowWidth < 770) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
    }
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    getUser () {
      return this.$store.state.user
    },
    getPlatform () {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform
    }
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
  },
  created () {
    store.commit('ShowNav')
    // store.commit('ShowNavHome')
    // console.log(this.getPlatform)
    this.init()
  },
  methods: {
    init () {
      if (this.windowWidth < 770) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
      this.loading = false
      this.scrollToTop()
    },
    scrollToTop () {
      const firstScrollTo = scroller()
      setTimeout(() => {
        firstScrollTo('#howtobuy')
      }, 200)
    },
    async addBSCNetwork () {
      if (this.getUser.walletProvider === 'MetaMask') {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x38' }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x38', // A 0x-prefixed hexadecimal string
                  chainName: 'BSC Mainnet',
                  nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB', // 2-6 characters long
                    decimals: 18,
                  },
                  rpcUrls: ['https://bsc-dataseed1.binance.org/'],
                  blockExplorerUrls:['https://bscscan.com/']
                }],
              })
            } catch (addError) {
              // handle "add" error
            }
          }
          // handle other "switch" errors
        }
      } else if (this.getUser.walletProvider === 'BinanceChainWallet') {
        try {
          await window.BinanceChain.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x38' }],
          });
        } catch (switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if (switchError.code === 4902) {
            try {
              await window.BinanceChain.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: '0x38', // A 0x-prefixed hexadecimal string
                  chainName: 'BSC Mainnet',
                  nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB', // 2-6 characters long
                    decimals: 18,
                  },
                  rpcUrls: ['https://bsc-dataseed1.binance.org/'],
                  blockExplorerUrls:['https://bscscan.com/']
                }],
              })
            } catch (addError) {
              // handle "add" error
            }
          }
          // handle other "switch" errors
        }
      }
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
  .howToTitle {
    font-family: 'Comfortaa';
    font-size: 30px;
    font-weight: 900;
    text-align: center;
  }
  .howToTitleText {
    font-family: 'Comfortaa';
    font-size: 20px;
    font-weight: 900;
    text-align: center;
  }
</style>
