<template>
  <div id="promote" :style="dark ? 'background-color: #2a3a3b;height:100%' : 'background-color: #badadb;height:100%'" >
    <v-slide-y-transition mode="out-in">
      <v-layout column pl-2 pr-2 :style="windowWidth < 770 ? '' : 'margin-left:10%;margin-right:10%'">
       <!-- ####################### SKELETON LOADERS ####################### -->
       <v-layout column v-if="loading">
         <p class="mt-0"></p>

         <v-layout column >

           <v-layout >
             <v-spacer></v-spacer>
             <v-skeleton-loader
               class="mx-auto"
               type="image"
               width="300"
               height="50"
             ></v-skeleton-loader>

             <v-spacer></v-spacer>
           </v-layout >

            <p class="mt-0"></p>

           <v-spacer></v-spacer>
             <template v-for="n in 3" >
               <v-skeleton-loader
                 class="mx-auto"
                 type="card"
                 width="100%"
                 :key="n"
               ></v-skeleton-loader>
               <v-spacer :key="n + 'b'"></v-spacer>
             </template>
           <v-spacer></v-spacer>
         </v-layout>

       </v-layout>

       <!-- ############################################## COMPONENTS ##############################################  -->

         <v-layout column align-center v-else pt-4>
           <!-- v-show="!loadingData" -->
           <p class="mt-4"></p>
           <p v-if="scrollY > 0" class="mt-0"></p>
           <p v-if="scrollY > 0" class="mt-4"></p>

           <v-card v-if="!joined"
             class="mx-auto"
             :dark="dark"
             height="450"
             max-width="450"
           >
               <v-layout column align-center pl-4 pr-4 v-if="!loadingBuyer">
                 <p class="promotionTitle font-weight-bold mt-4">
                   Token Monster (TMO)<br>Private Sale
                 </p>
                 <p class="mt-2"></p>

                 <p class="font-weight-medium">
                   The Private Sale has been closed..
                 </p>

                 <p class="mt-4"></p>

                 <vue-countdown :time="saleEnd" v-slot="{ days, hours}">
                   <v-layout column align-center pl-2 pr-2>
                     <p style="margin-top:-15px;" :class="dark ? 'clockDarkText text-center;' : 'clockText text-center'">{{ days }} Days {{ hours }} Hrs Left</p>

                   </v-layout>
                 </vue-countdown>
                 <p class="mt-4"></p>
                 <v-btn :color="dark ? '#B71C1C' : 'red lighten-3'" large block>
                   Closed</v-btn>

               <p class="mt-0"></p>
            </v-layout>

            <v-layout column align-center pl-4 pr-4 v-if="loadingBuyer">

              <p class="promotionTitle font-weight-bold mt-4">
                Token Monster (TMO)<br>Private Sale
              </p>
              <p class="mt-2"></p>

              <v-layout column align-center pa-4>
                <p style="font-size: 14px">Loading your data...</p>
                <v-progress-linear
                 color="#388E3C"
                 indeterminate
                 rounded
                 height="6"
               ></v-progress-linear>
              </v-layout>

              <p class="mt-0"></p>
           </v-layout>

          </v-card>

           <!-- <v-card v-else-if="!mmConnected && !binanceConnected && !walletConnected && joined"
             class="mx-auto"
             :dark="dark"
             height="450"
             max-width="450"
           >
             <v-layout column align-center pl-4 pr-4 v-if="!isMobileDevice">
               <p class="promotionTitle font-weight-bold mt-4">
                 Connect to a wallet
               </p>
               <p class="mt-2"></p>
               <MetaMaskConnect :isMobileDevice="isMobileDevice" style="width:100%;" ref="mmConnect" buttonType="large" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark">
               </MetaMaskConnect>
               <p class="mt-0"></p>
               <BinanceConnect :isMobileDevice="isMobileDevice" style="width:100%;" ref="binanceConnect" buttonType="large" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark">
               </BinanceConnect>
                <p class="mt-0"></p>
                <WalletConnect style="width:100%;" ref="walletConnect" buttonType="large" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark">
                </WalletConnect>
                <p class="mt-0"></p>
             </v-layout>

             <v-layout column align-center pl-4 pr-4 v-if="isMobileDevice">
               <p class="promotionTitle font-weight-bold mt-4">
                 Connect to a wallet
               </p>
               <p class="mt-2"></p>
               <WalletConnect style="width:100%;" ref="walletConnect" buttonType="large" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark">
               </WalletConnect>
               <p class="mt-0"></p>
               <MetaMaskConnect :isMobileDevice="isMobileDevice" style="width:100%;" ref="mmConnect" buttonType="large" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark">
               </MetaMaskConnect>
               <p class="mt-2"></p>
               <p class="font-weight-medium mt-4">
                 If you encounter problems while connecting on mobile, please try the in app Dapp broswer in MetaMask or Trust Wallet mobile app.<br>You will need to use this link in the Dapp browser:
               </p>
                <p class="mt-0"></p>
                <span :style="windowWidth < 770 ? 'font-size:14px;' : 'font-size:16px'" class="wrap-text font-weight-bold">
                  {{ pageLink }}
                  <v-icon color="grey" small style="cursor: pointer;margin-top:-2px" icon v-clipboard="() => pageLink" @success="handleSuccess" @error="handleError">mdi-content-copy</v-icon>
                </span>
             </v-layout>

           </v-card> -->

           <v-card v-if="joined && view === 1"
             class="mx-auto"
             :dark="dark"
             :min-width="windowWidth < 770 ? '' : 950"
             max-width="550"
           >
             <v-layout column align-center pl-2 pr-2 pb-4>

               <v-col cols="12" sm="12" md="12">
                    <v-form dark v-model="valid" ref="form" dense>

                      <v-layout>
                        <v-spacer></v-spacer>
                        <p class="promotionTitle font-weight-bold mt-4">
                          Token Monster (TMO) - Private Sale
                        </p>
                        <v-spacer></v-spacer>
                      </v-layout>


                      <p class="mt-0"></p>
                      <p style="font-size:16px" class="text-center font-weight-bold mt-4">
                        Please, fill out the form below and submit your request.
                      </p>
                      <p class="mt-0"></p>

                      <!-- <v-text-field
                        label="Your Wallet Address"
                        v-model="getAccounts[0]"
                        maxlength="50"
                        append-icon="mdi-wallet-outline"
                        outlined
                      ></v-text-field> -->

                      <v-text-field
                        v-model="buyerName"
                        label="Your Name"
                        placeholder="Enter your name"
                        maxlength="50"
                        append-icon="mdi-account-outline"
                        outlined

                      ></v-text-field>

                      <v-text-field style="font-size:18px"
                        v-model="emailAddress"
                        outlined
                        color="cyan darken-4"
                        :rules="emailRules"
                        label="Email Address"
                        append-icon="mdi-email-outline"
                        @input="change0"
                        @change="change0"
                      ></v-text-field>

                      <!-- <v-text-field
                        v-model="tgUserName"
                        label="Telegram username (optional)"
                        placeholder="Your Telegram username (optional)"
                        maxlength="50"
                        outlined
                      ></v-text-field> -->

                      <v-layout column>
                        <p style="font-size:18px" class="text-center font-weight-bold mt-4">
                          Private Sale Contribution Details
                        </p>
                        <p style="font-size:14px;margin-top:-10px" class="text-center font-weight-medium">
                          TMO Rate:	1 TMO = 0.01 USD<br>
                          Minimum Contribution:	0.1 BNB<br>
                          Maximum Contribution:	5 BNB
                        </p>
                        <!-- <p style="font-size:13px" class="text-center font-weight-bold">
                          Rate USD: 1000 TMO per USD<br>
                          Minimum Contribution:	10 USD<br>
                          Maximum Contribution:	1000 USD
                        </p> -->
                      </v-layout>

                      <v-layout :column="windowWidth < 768" >
                        <v-spacer :column="windowWidth > 768" ></v-spacer>
                        <v-text-field :style="windowWidth > 768 ? 'max-width:150px' : ''"
                          v-model="bnbAmount"
                          label="Your Contribution"
                          type="number"
                          :min="0"
                          :max="5"
                          outlined
                          :rules="[v => !!v]"
                          suffix="BNB"
                          @change="checkBuyerAmount"
                        ></v-text-field>

                        <p style="font-size:16px;margin-left:20px" class="text-center font-weight-medium mt-3">
                          {{ Math.round(bnbAmount * (getLatestBNB/0.01)) }} TMO (Current market Rate)
                        </p>
                        <v-spacer :column="windowWidth > 768" ></v-spacer>

                      </v-layout>

                      <p class="mt-4"></p>

                      <p style="font-size:16px;margin-left:20px;color:red" class="text-center font-weight-medium mt-3">
                        No transcation will be triggered when clicking NEXT. This is only an inital Request form.
                      </p>

                      <p class="mt-4"></p>

                  <v-btn @click="createNewTokenNext" :color="dark ? '#388E3C' : 'green lighten-3'" large block :disabled="!valid">
                    <v-icon left size="25">mdi-arrow-right-bold</v-icon>Next</v-btn>
                  </v-form>
                </v-col>
             </v-layout>
           </v-card>

           <v-card v-if="joined && view === 2"
             class="mx-auto"
             :dark="dark"
             :min-width="windowWidth < 770 ? '' : 750"
             max-width="550"
           >

             <v-layout column  pl-4 pr-4 pb-2>
               <p class="promotionTitle font-weight-bold mt-4">
                 Your Private Sale Confirmation
               </p>

               <!-- <p >
                 <strong>Your Wallet Addrress:</strong>  {{ getAccounts[0] }}
               </p> -->

               <p >
                 <strong>Your Name:</strong>   {{ buyerName }}
               </p>

               <p >
                 <strong>Your Email:</strong>   {{ emailAddress }}
               </p>

               <!-- <p >
                 <strong>Telegram Username:</strong>   {{ tgUserName }}
               </p> -->

               <p >
                <strong>*Estimated Token Amount:</strong>   {{ Math.round(bnbAmount * (getLatestBNB/0.01)) }} TMO (Current market Rate)<br>
                <strong>Total BNB:</strong>   {{ bnbAmount }}
               </p>

               <p > <!-- Waiting Payment -->
                 <strong>Status:</strong> {{ privateSaleStatuses[buyerStatus].name }}
               </p>

               <p v-if="buyerStatus === 3"> <strong>WALLET TO SEND {{ bnbAmount }} BNB: </strong><br>
                 <span :style="windowWidth < 770 ? 'font-size:14px;' : 'font-size:16px'" class="wrap-text font-weight-medium">
                   {{ '0xE6A959B10eA787Db1Bbf36641ce4ce27ec4162d1' }}
                   <v-icon color="grey" small style="cursor: pointer;margin-top:-2px" icon v-clipboard="() => '0xE6A959B10eA787Db1Bbf36641ce4ce27ec4162d1'" @success="handleSuccess2" @error="handleError">mdi-content-copy</v-icon>
                 </span>
              </p>


                <v-layout column v-if="buyerStatus === 3">

                  <v-text-field
                    v-model="paymentTxId"
                    label="Transaction ID"
                    placeholder="Enter the Transaction ID"
                    maxlength="100"
                    outlined
                    :rules="[v => !!v]"
                  ></v-text-field>

                  <v-btn :disabled="paymentTxId === ''" @click="updateTxId()" :loading="loadingData" :color="dark ? '#388E3C' : 'green lighten-3'" large>
                    <v-icon left size="25">mdi-arrow-right-bold</v-icon>I have sent the payment
                  </v-btn>
                </v-layout>

                <v-layout column v-if="buyerStatus === 4">
                  <v-alert
                    dense
                    outlined
                    type="info"
                  >
                    Thank you for submitting the Transaction ID.<br>Please wait for the payment received confirmation.
                  </v-alert>
                </v-layout>

                <v-layout column v-if="buyerStatus === 5">
                  <v-alert
                    dense
                    outlined
                    type="success"
                  >
                    Payment Confirmed.<br>You will receive your TMO Tokens 4 hours before the listing on PCS.
                  </v-alert>
                </v-layout>

                <v-layout column v-if="buyerStatus === 6">
                  <v-alert
                    dense
                    outlined
                    type="success"
                  >
                    Tokens have been Sent.<br>Your wallet address: {{ buyerWallet }}
                  </v-alert>
                </v-layout>

                <p class="mt-4"></p>

               <p style="font-size:13px">
                 *At the time of receiving your TMO the total Tokens received can vary as per market rate.
               </p>

             </v-layout>

             <v-layout column pa-2>

               <p class="mt-0"></p>
               <v-btn @click="createRequest()" :loading="loadingData" :color="dark ? '#388E3C' : 'green lighten-3'" large v-if="!alreadyExists">
                 <v-icon left size="25">mdi-arrow-right-bold</v-icon>Submit Request
               </v-btn>
               <p class="mt-0"></p>
               <v-btn @click="view = 1" outlined :color="dark ? 'green lighten-3' : 'green lighten-3'" large v-if="!alreadyExists" >
                <v-icon left size="25">mdi-arrow-left-bold</v-icon>Back
               </v-btn>

               <!-- CLAIM BOTTON CAN GO HERE -->

             </v-layout>

           </v-card>

           <v-card v-if="joined && view === 3"
             class="mx-auto"
             :dark="dark"
             :min-width="windowWidth < 770 ? '' : 750"
             max-width="800"
           >
             <v-layout column  pl-4 pr-4 pb-4>
               <p class="promotionTitle font-weight-bold mt-4" v-if="!alreadyExists">
                 Great! Your Request has been submitted.
               </p>
               <p class="promotionTitle font-weight-bold mt-4" v-if="alreadyExists">
                 Your have already submitted your request.
               </p>

               <p class="text-center font-weight-medium mt-0">
                 This is the unique link that can be used check the status of your request.<br><br>
                 <span :style="windowWidth < 770 ? 'font-size:14px;' : 'font-size:16px'" class="wrap-text font-weight-bold">
                   {{ windowWidth > 770 ? tokenLinkSlug : tokenLinkSlug.substr(0, 25) + '...' }}
                   <v-icon color="grey" small style="cursor: pointer;margin-top:-2px" icon v-clipboard="() => tokenLinkSlug" @success="handleSuccess" @error="handleError">mdi-content-copy</v-icon>
                 </span>
               </p>

               <v-btn  @click="view = 4" :loading="loadingData" :color="dark ? '#388E3C' : 'green lighten-3'" large>
                 <v-icon left size="25">mdi-arrow-right-bold</v-icon>Ok
               </v-btn>

               <p class="mt-0"></p>

               <v-btn @click="loadRequest(newPostKey.id)" :loading="loadingData" outlined :color="dark ? '#388E3C' : 'green lighten-3'" large v-if="alreadyExists">
                 <v-icon left size="25">mdi-arrow-right-bold</v-icon>Open Request
               </v-btn>

             </v-layout>
           </v-card>

           <v-card v-if="joined && view === 4"
             class="mx-auto"
             :dark="dark"
             width="windowWidth < 770 ? '' : 750"
             max-width="550"
           >
             <v-layout column pl-4 pr-4 pb-4>
               <p class="promotionTitle font-weight-bold mt-4">
                 Awesome, You're all done!
               </p>

               <!-- some nice image -->
               <v-layout column align-center>
                 <v-img
                   src="img/home/awesome.png"
                   class="white--text align-end"
                   style="opacity:0.9"
                   :width="windowWidth < 770 ? 100 : 170"
                 >
                 </v-img>
               </v-layout>
               <p class="mt-2"></p>
               <p class="text-center font-weight-medium mt-4">
                 You will be informed about the status of your request and the next steps.
               </p>
               <p class="mt-4"></p>

               <v-btn  to="/" :loading="loadingData" :color="dark ? '#388E3C' : 'green lighten-3'" large>
                 Done
               </v-btn>

             </v-layout>

           </v-card>

           <p class="mt-4"></p>

         </v-layout>

         <!-- ############################################ DIALOGS ################################################# -->

         <v-dialog v-model="contractLoadingDialog" persistent max-width="400">
           <v-card
            :dark="dark"
           >
           <v-toolbar flat y>
             <v-card-title style="font-size:18px" class="font-weight-bold">Loading Contract Data</v-card-title>
             <v-spacer></v-spacer>
             <v-btn icon @click="contractLoadingDialog = false">
               <v-icon>mdi-close</v-icon>
             </v-btn>
           </v-toolbar>

           <v-layout column align-center pa-4>
             <p style="font-size: 12px">Checking the contract information the {{ tokenNetwork }} network.</p>
             <v-progress-linear
              color="#388E3C"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
           </v-layout>

             <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn
                  color="grey darken-1"
                  text
                  @click="contractLoadingDialog = false"
                >
                  Close
                </v-btn>
             </v-card-actions>

           </v-card>
         </v-dialog>


      </v-layout>
    </v-slide-y-transition>


    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
      <v-icon color="green">mdi-check-circle-outline</v-icon>
      <span >{{ snackbarText }}</span>
            <v-btn text color="pink" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

  </div>
</template>

<script>
// import store from '@/store/index'
import { isMobile } from 'mobile-device-detect'
import firebase from "firebase/app"
import "firebase/auth"
import { db } from '@/main'
import VueCountdown from '@chenfengyuan/vue-countdown'
const now = new Date()
const endDateTime = new Date('2021-09-30 00:00:00')
// import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import {scroller} from 'vue-scrollto/src/scrollTo'
// import MetaMaskConnect from '@/views/app/MetaMaskConnect'
// import BinanceConnect from '@/views/app/BinanceConnect'
// import WalletConnect from '@/views/app/WalletConnect'
// import TokenMonsterAPI from '@/clients/TokenMonsterAPI'
const dateformat = require('dateformat')
// const itemsPromotion = require('./promotion')
// const Web3 = require('web3')
// const web3 = new Web3('https://bsc-dataseed1.binance.org:443')
// import BinanceAPI from '@/clients/BinanceAPI'
export default {
  name: 'promote',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    scrollY: Number
  },
  data () {
    return {
      lang: this.$store.state.lang,
      saleEnd: endDateTime - now,
      isMobileDevice: false,
      loading: true,
      loadingData: false,
      loadingBuyer: false,
      currentUser: null,
      showBottomNav: false,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      contractLoadingDialog: false,
      contractExistsDialog: false,
      learnMoreDialog: false,
      signUpView: 1,
      installMM: false,
      count: 0,
      show: false,
      joined: false,
      view: 1,
      valid: false,
      newPostKey: {},
      requestStatus: 2,
      tokenNetwork: 'BSC',
      tokenAddress: '',
      walletAddress:  '',
      tokenName: '',
      buyerName: '',
      emailAddress: '',
      emailRules: [
        v => !!v || 'Email is required',
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email address must be valid'
      ],
      tgUserName: '',
      bnbAmount: 0,
      tokenSymbol: '',
      tokenSlug: '',
      tokenLinkSlug: '',
      checkExists: false,
      contractValid: true,
      totalSupply: 0,
      showTotalSupply: false,
      correctTotalSupply: false,
      decimals: 0,
      totalHolders:  0,
      showTotalHolders: false,
      totalBurned: 0,
      showTotalBurned: false,
      websiteLink: '',
      telegramLink: 'https://t.me/',
      twitterLink: 'https://twitter.com/',
      redditLink: '',
      discordLink: '',
      logoUrl: '',
      logoUpload: false,
      previewLogo: false,
      logoImage: '',
      files: [],
      filesValid: false,
      todayDate1: new Date().toISOString().substr(0, 10),
      todayDate: new Date().toISOString().substr(0, 10),
      presaleDate: new Date().toISOString().substr(0, 10),
      presaleTime: new Date().toISOString().substr(11, 5),
      launchDate: new Date().toISOString().substr(0, 10),
      launchTime: new Date().toISOString().substr(11, 5),
      expDate: new Date().toISOString().substr(0, 10),
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      checkPresale: false,
      tokenPresale: '',
      buyerComments: '',
      promoteYes: false,
      promotionPackage: 0,
      tokenTxId: '',
      showTxFields: false,
      binanceChainID: '',
      countDown: 5,
      items: this.$store.state.packages,
      alreadyExists: false,
      pageLink: 'https://tokenmonster.app/privatesale',
      buyerStatus: 0,
      privateSaleStatuses: [
        {name: 'Draft', id: 0},
        {name: 'Approved', id: 1},
        {name: 'Requested', id: 2},
        {name: 'Waiting Payment', id: 3},
        {name: 'Payment Sent', id: 4},
        {name: 'Payment Confirmed', id: 5},
        {name: 'Tokens Sent', id: 6}
      ],
      buyerWallet: '',
      paymentTxId: ''
    }
  },
  components: {
    // MetaMaskConnect,
    // BinanceConnect,
    // WalletConnect
    VueCountdown
  },
  watch: {
    windowWidth () {
      if (this.windowWidth < 768) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
    },
    tokenNetwork () {
      if (this.tokenAddress !== '') {
        this.lookupTotalSupply()
      }
    },
    mmConnected () {
      if (!this.mmConnected && !this.binanceConnected) {
        console.log('Not connected triggered')
      } else {
        console.log('triggered')
        // this.updateDisplayName()
      }
    },
    binanceConnected () {
      if (!this.mmConnected && !this.binanceConnected) {
        console.log('Not connected triggered')
      } else {
        console.log('triggered')
        // this.updateDisplayName()
      }
    }
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    isLoggedIn () {
      return this.$store.state.user.isLoggedIn
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    mmInstalled () {
      return this.$store.state.user.mmInstalled
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    walletConnected () {
      return this.$store.state.user.walletConnected
    },
    getAccounts () {
      return this.$store.state.user.accounts
    },
    getUser () {
      return this.$store.state.user
    },
    getPaymentAddress () {
      return this.$store.state.paymentAddress
    },
    getPlatform () {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform
    },
    getLatestBNB () {
      return this.$store.state.latestBNB
    },
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
  },
  created () {
    // store.commit('HideNav')
    // store.commit('ShowNavHome')
    //
    this.isMobileDevice = isMobile
    this.currentUser = firebase.auth().currentUser
    // console.log(this.getPlatform)
    this.scrollToTop()
    this.init()
  },
  methods: {
    init () {
      if (this.windowWidth < 768) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
      this.loading = false
      for (var i in this.items) {
        this.items[i].selected = false
      }
      // if firebase logged in >>> goto add coin form
      var minDate = new Date().getTime() - (1000 * 60 * 60 * 24 * 90) // - 90 days 3 months
      this.todayDate = dateformat(minDate, 'yyyy-mm-dd')

      if (this.$route.params.id === undefined || this.$route.params.id === null) {
        //
        this.$router.push('/privatesale/0')
      } else if (this.$route.params.id === '0') {
        //
      } else {
        this.$router.replace('/privatesale/' + this.$route.params.id)
        this.loadingBuyer = true
        this.loadBuyerRequest()
      }
      // this.reportPrivateSale()
    },
    updateDisplayName () {
      if (this.getUser.displayName === '') {
        setTimeout(() => {
          this.updateDisplayName()
          if (this.$route.params.id === '0' || this.$route.params.id === undefined || this.$route.params.id === null) {
            // this.checkRequestHistoryUser()
          }
        }, 1000)
      } else {
        this.buyerName =  this.getUser.displayName
      }
    },
    checkBuyerAmount () {
      console.log(this.bnbAmount)
      if (this.bnbAmount === 0 || this.bnbAmount < 0.1 || this.bnbAmount > 5 || this.bnbAmount < 0){
        this.snackbarText = "Invalid BNB Amount."
        this.snackbar = true
        this.bnbAmount = 0
      }
    },
    checkRequestHistoryUser () {
      let userAddress = this.getAccounts[0]
      let usersRef = db.collection('privatesale')
      usersRef.where('wallet', '==', userAddress).orderBy('created', 'asc').limit(1).get()
        .then(snapshot => {
          // console.log(snapshot)
          if (snapshot.empty) {
            //
            console.log('No Request exists')
          }
          snapshot.forEach(doc => {
            // Already requested
            console.log('Request exists')
            this.alreadyExists = true
            this.buyerName = doc.data().name
            this.emailAddress = doc.data().email
            this.tgUserName = doc.data().tg_user
            this.bnbAmount = doc.data().amount_BNB
            this.tokenLinkSlug =  'https://tokenmonster.app/privatesale/' + doc.data().slug
            this.newPostKey.id = doc.data().slug
            this.view =  3
          })
        })
    },
    reportPrivateSale () {
      let usersRef = db.collection('privatesale')
      usersRef.orderBy('created', 'asc').get()
        .then(snapshot => {
          // console.log(snapshot)
          if (snapshot.empty) {
            //
            console.log('No Request exists')
          }
          var amount_BNB = 0
          snapshot.forEach(doc => {
            // Already requested
            console.log(doc.id, '=>', doc.data())
            amount_BNB += parseFloat(doc.data().amount_BNB)
          })
            console.log('Total Contributed: ' + snapshot.size)
            console.log('Total BNB: ' + amount_BNB)
        })
    },
    loadRequest (id) {
      this.$router.replace('/privatesale/' + id)
      this.view =  2
    },
    loadBuyerRequest () {
      let slug = this.$route.params.id
      let usersRef = db.collection('privatesale')
      usersRef.where('slug', '==', slug).orderBy('created', 'asc').limit(1).get()
        .then(snapshot => {
          // console.log(snapshot)
          if (snapshot.empty) {
            //
            console.log('No Request exists')
          }
          snapshot.forEach(doc => {
            // Already requested
            console.log('Request exists')
            this.alreadyExists = true
            this.buyerName = doc.data().name
            this.emailAddress = doc.data().email
            this.buyerWallet = doc.data().wallet
            this.tgUserName = doc.data().tg_user
            this.bnbAmount = doc.data().amount_BNB
            this.buyerStatus = doc.data().status
            this.tokenLinkSlug =  'https://tokenmonster.app/privatesale/' + doc.data().slug
            this.newPostKey.id = doc.data().slug
            this.joined = true
            this.loadingBuyer = false
            this.view =  2
          })
        })
    },
    scrollToTop () {
      const firstScrollTo = scroller()
      setTimeout(() => {
        firstScrollTo('#promote')
      }, 200)
    },
    checkEmailExist () {

    },
    checkSelected (index) {
      // console.log(this.items[index].selected)
      var count = 0
      if (this.items[index].selected) {
        this.promoteYes = true
        this.promotionPackage = index + 1
      } else {
        count += 1
      }
      for (var i in this.items) {
        if (parseInt(i) !== index) {
          this.items[i].selected = false
          count += 1
        }
      }
      if (count === 5) {
        this.promoteYes = false
      }
    },
    socialLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider).then(() => {
        // console.log(result)
        // Check if user in user bucket
        // do the stuff first
        // then reload
        window.location.reload()
      }).catch((err) => {
        alert('Oops. ' + err.message)
      })
    },
    handleFiles () {
      // console.log(this.files)
      if (this.files === undefined || this.files === null) {
        this.logoUrl = ''
        this.previewLogo = false
        this.filesValid = false

      } else if (this.files.size > 500000) { // === 500 KB
        this.files = []
        this.showRowAlert = true
        this.showRowAlertText = 'Maximum file size of 500KB exeeded for ' + this.files.name + '!'

      } else {
        this.filesValid = true
        this.logoUrl = this.files.name
        this.useImage()
      }
    },
    useImage () {
      // console.log(this.files)
      if (this.logoUrl === '') return
      this.logoUrl = this.files.name
      this.getBase64(this.files).then(
        data => this.logoUrl = data
      )
      this.previewLogoChanged()
    },
    checkUpperCase () {
      this.tokenSymbol = this.tokenSymbol.toUpperCase()
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    },
    createNewTokenNext () {
      // promote view (optional)
      // this will take you to the top 10 list bla bla pre and post-launch lists
      this.view = 2
      this.scrollToTop()
    },
    previewLogoChanged () {
      if (this.logoUrl.indexOf('http') > -1 || this.logoUrl !== '') {
        this.previewLogo = true
      } else {
        this.previewLogo = false
      }
    },
    createRequest () {
      this.loadingData = true
      this.newPostKey = db.collection('privatesale').doc()

      this.tokenLinkSlug =  'https://tokenmonster.app/privatesale/' + this.newPostKey.id

      let dispatchObj = {
        // postkey: this.newPostKey.id,
        uid: this.getUser.uid,
        wallet: '',
        name: this.buyerName,
        email: this.emailAddress,
        tg_user: this.tgUserName,
        amount_BNB: this.bnbAmount,
        estimated_TMO: Math.round(this.bnbAmount * (this.getLatestBNB/0.01)),
        slug: this.newPostKey.id,
        symbol: 'TMO',
        network: 'BSC',
        status: this.requestStatus,
        created_by: this.getUser.displayName,
        created: new Date().getTime(),
        modified_by: '',
        modified: 0
      }
      console.log(dispatchObj)
      db.collection('privatesale').doc(this.newPostKey.id).set(dispatchObj)
      .then(() => {
          // console.log(ref)
          // console.log('New Token in bucket created')
          this.snackbarText = 'Private Sale request added succesfully!'
          this.snackbar = true
          this.loadingData = false
          this.view = 3
        })
      .catch(error => {
          alert(error)
          this.loadingData = false
          // Handle error
        })
    },
    getRandomString(length) {
      var randomChars = 'bcdfghjklmnpqrstvwxz'
      var result = '';
      for ( var i = 0; i < length; i++ ) {
          result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
      }
      return result
    },
    getIndicesOf(searchStr, str, caseSensitive) {
        var searchStrLen = searchStr.length
        if (searchStrLen == 0) {
            return []
        }
        var startIndex = 0, index, indices = []
        if (!caseSensitive) {
            str = str.toLowerCase()
            searchStr = searchStr.toLowerCase();
        }
        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            indices.push(index)
            startIndex = index + searchStrLen
        }
        return indices;
    },
    transferETHNow () {
      // console.log('ETH Transaction')
      window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: this.getAccounts[0],
            to: this.getPaymentAddress,
            value: '0x3782DACE9D90000',
            gasPrice: '0x09184e72a000',
            gas: '0x2710',
          },
        ],
      })
      .then((txHash) => console.log(txHash))
      .catch((error) => console.log(error))
    },
    transferBNBNow () {
      console.log('BNB Transaction')
      // Check Network ChainID
      // console.log(window.ethereum.networkVersion, 'window.ethereum.networkVersion'); ///  must be 56

      if (this.getUser.walletProvider === 'MetaMask'|| this.getUser.walletProvider === 'WalletConnect') {
        window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [
            {
              input: 'SEND BNB',
              from: this.getAccounts[0],
              to: this.getPaymentAddress,
              value: this.items[this.promotionPackage - 1].price_hex,
              gas: '0x5208',
              gasPrice: '0xBA43B7400',
              chainId: '0x38'
            },
          ],
        })
        .then((txHash) => {
          this.tokenTxId = txHash
          this.showTxFields = true
          this.resultInterval = setInterval(() => {
          this.countDown -= 1
            if (this.countDown === 0) {
              clearInterval(this.resultInterval)
              this.updateTxId()
            }
          }, 1000)
        })
        .catch((error) => console.log(error))

      } else if (this.getUser.walletProvider === 'BinanceChainWallet') {
        window.BinanceChain.request({
           method: 'eth_sendTransaction',
           params: [
             {
               from: this.getAccounts[0],
               to: this.getPaymentAddress,
               value: this.items[this.promotionPackage - 1].price_hex,
               gas: '0x5208',
               gasPrice: '0xBA43B7400'
             },
           ],
         })
         .then((txHash) => {
           this.tokenTxId = txHash
           this.showTxFields = true
           this.resultInterval = setInterval(() => {
           this.countDown -= 1
             if (this.countDown === 0) {
               clearInterval(this.resultInterval)
               this.updateTxId()
             }
           }, 1000)
         })
         .catch((error) => console.log(error))
      }

      // Most likely gonna be a API call axios
      /* let fromAddress = this.getAccounts[0]
      let toAddress = '0x6EDd445C22b80972C08F17EE2c066B31d3F9E8Dc'
      let amount =  this.items[this.promotionPackage - 1].price_bnb
      let asset = 'BNB'
      let memo = this.currentUser.uid */
       // window.web3 = new Web3(window.BinanceChain)
       // do stuff
       // console.log(window.BinanceChain)
       // console.log(web3)
       //

      /* Promise.resolve(BinanceAPI.transfer(fromAddress, toAddress, amount, asset, memo))
      .then(result => {
        console.log(result)
      })
      .catch(err => {
        this.loading = false
        console.log('Error getting documents.', err)
        // show friendly error in user screen
      }) */

      // using the event emitter
      /* console.log(this.getAccounts[0])
      web3.eth.sendTransaction({
          from: this.getAccounts[0],
          to: '0x6EDd445C22b80972C08F17EE2c066B31d3F9E8Dc',
          value: '1000000000000000',
          chainId: '0x38',
          symbol: 'BNB'
      })
      .on('transactionHash', function(hash) {
        console.log(hash)
      })
      .on('receipt', function(receipt) {
        console.log(receipt)
      })
      .on('confirmation', function(confirmationNumber, receipt) {
        console.log(confirmationNumber)
        console.log(receipt)
      })
      .on('error', console.error) */ // If a out of gas error, the second parameter is the receipt.
    },
    updateTxId () {
      console.log('Update Tx Id')
      this.loadingData = true
      let dispatchObj = {
        status: 4,
        tx_id: this.paymentTxId,
        modified: new Date().getTime()
      }
      db.collection('privatesale').doc(this.newPostKey.id).update(dispatchObj)
      .then(() => {
        // console.log('Tx Id in token bucket updated')
        this.snackbarText = 'Tx Id Confirmed!'
        this.buyerStatus = 4
        this.snackbar = true
        this.loadingData = false
        // this.view = 4
      })
      .catch(error => {
          console.log(error)
        })
    },
    handleSuccess(e) {
      console.log(e);
      this.snackbarText = 'Link copied to clipboard.'
      this.snackbar = true
    },
    handleSuccess2(e) {
      console.log(e);
      this.snackbarText = 'Address copied to clipboard.'
      this.snackbar = true
    },
    handleError(e) {
      console.log(e);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    change0 () {
      // validate
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      // console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style>
  .promotionTitle {
    font-family: 'Comfortaa';
    font-size: 24px;
    font-weight: 900;
    text-align: center;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
  .wrapperDark{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrapperLight{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrap-text {
    word-break: normal;
  }
</style>
