<template>
  <div id="promote" :style="dark ? 'background-color: #2a3a3b;height:100%' : 'background-color: #badadb;height:100%'" >
    <v-slide-y-transition mode="out-in">
      <v-layout column pl-2 pr-2 :style="windowWidth < 770 ? '' : 'margin-left:10%;margin-right:10%'">
       <!-- ####################### SKELETON LOADERS ####################### -->
       <v-layout column v-if="loading">
         <p class="mt-0"></p>

         <v-layout column >

           <v-layout >
             <v-spacer></v-spacer>
             <v-skeleton-loader
               class="mx-auto"
               type="image"
               width="300"
               height="50"
             ></v-skeleton-loader>

             <v-spacer></v-spacer>
           </v-layout >

            <p class="mt-0"></p>

           <v-spacer></v-spacer>
             <template v-for="n in 3" >
               <v-skeleton-loader
                 class="mx-auto"
                 type="card"
                 width="100%"
                 :key="n"
               ></v-skeleton-loader>
               <v-spacer :key="n + 'b'"></v-spacer>
             </template>
           <v-spacer></v-spacer>
         </v-layout>

       </v-layout>

       <!-- ############################################## COMPONENTS ##############################################  -->

         <v-layout column align-center v-else pt-4>
           <!-- v-show="!loadingData" -->
           <p class="mt-4"></p>
           <p v-if="scrollY > 0" class="mt-0"></p>
           <p v-if="scrollY > 0" class="mt-4"></p>

           <v-card v-if="!mmConnected && !binanceConnected && !walletConnected"
             class="mx-auto"
             :dark="dark"
             height="450"
             max-width="450"
           >
             <v-layout column align-center pl-4 pr-4>
               <p class="promotionTitle font-weight-bold mt-4">
                 Connect to a wallet
               </p>
               <p class="mt-2"></p>
               <!-- <v-btn style="width:100%;" large :color="dark ? '#388E3C' : 'green lighten-5'"  @click="checkEmailExist">
                 <v-icon left>mdi-at</v-icon>Via Email
               </v-btn>
               <p class="mt-0"></p>
               <v-btn style="width:100%;" large :color="dark ? '#388E3C' : 'green lighten-5'"  to="/signup">
                 <v-icon left>mdi-cellphone-text</v-icon>Via Mobile
               </v-btn>
               <p class="mt-0"></p>
               <v-btn style="width:100%;" large :color="dark ? '#388E3C' : 'green lighten-5'" @click="socialLogin" >
                 <v-icon left>mdi-google</v-icon>Via Google
               </v-btn>
               <p class="mt-1"></p>
               <v-divider :dark="dark" style="display: inline;"></v-divider>
               <p class="mt-1"></p> -->
               <MetaMaskConnect :isMobileDevice="isMobileDevice" style="width:100%;" ref="mmConnect" buttonType="large" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark">
               </MetaMaskConnect>
               <p class="mt-0"></p>
               <BinanceConnect :isMobileDevice="isMobileDevice" style="width:100%;" ref="binanceConnect" buttonType="large" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark">
               </BinanceConnect>
                <p class="mt-0"></p>
                <WalletConnect style="width:100%;" ref="walletConnect" buttonType="large" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark">
                </WalletConnect>
                <p class="mt-0"></p>
             </v-layout>
           </v-card>

           <v-card v-if="(mmConnected || binanceConnected || walletConnected ) && view === 1"
             class="mx-auto"
             :dark="dark"
             :min-width="windowWidth < 770 ? '' : 950"
             max-width="550"
           >
             <v-layout column align-center pl-2 pr-2 pb-4>

               <v-col cols="12" sm="12" md="12">
                    <v-form dark v-model="valid" ref="form" dense>

                      <v-layout>
                        <v-spacer></v-spacer>
                        <p class="promotionTitle font-weight-bold mt-4">
                          Add a Token / Project
                        </p>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-icon v-if="tokenNetwork === 'ETH'" large color="blue-grey lighten-1">
                          mdi-ethereum
                        </v-icon>
                        <v-img v-if="tokenNetwork === 'BSC'" src="/img/icons/binance-coin-logo.png" style="max-width:32px;max-height:32px;margin-top:18px">
                        </v-img>
                        <v-img v-if="tokenNetwork === 'MATIC'" src="/img/icons/polygon.png" style="max-width:32px;max-height:32px;margin-top:18px">
                        </v-img>
                        <v-img v-if="tokenNetwork === 'KCC'" src="/img/icons/kucoin.png" style="max-width:32px;max-height:32px;margin-top:18px">
                        </v-img>
                      </v-layout>


                      <p class="mt-0"></p>
                      <p style="font-size:16px" class="text-center font-weight-bold mt-4">
                        Tokens / Projects can be added for free. Promoting is optional.
                      </p>
                      <p class="mt-0"></p>
                      <v-radio-group
                        v-model="tokenNetwork"
                        row
                      >
                        <v-radio
                          label="Binance Smart Chain (BSC)"
                          value="BSC"
                        ></v-radio>
                        <v-radio
                          label="Ethereum (ETH)"
                          value="ETH"
                        ></v-radio>
                        <v-radio
                          label="KuCoin (KCC)"
                          value="KCC"
                        ></v-radio>
                        <v-radio
                          label="Polygon (MATIC)"
                          value="MATIC"
                        ></v-radio>
                      </v-radio-group>

                      <v-text-field
                        v-model="tokenAddress"
                        :label="tokenNetwork === 'BSC' ? 'BSC Contract Address' : tokenNetwork === 'ETH' ? 'ETH Contract Address' : tokenNetwork === 'MATIC' ? 'Polygon (MATIC) Contract Address' : tokenNetwork === 'KCC' ? 'KuCoin (KCC) Contract Address' : ''"
                        :placeholder="tokenNetwork === 'BSC' ? 'Enter the BSC Contract Address' : tokenNetwork === 'ETH' ? 'Enter the ETH Contract Address' : tokenNetwork === 'MATIC' ? 'Enter the Polygon (MATIC) Contract Address': tokenNetwork === 'KCC' ? 'Enter the KuCoin (KCC) Contract Address': ''"
                        hint="You can provide this later if your contract is not yet deployed"
                        persistent-hint
                        maxlength="50"
                        outlined
                        @change="lookupTotalSupply"
                      ></v-text-field>

                      <v-layout pb-4 :column="windowWidth < 770" v-if="!correctTotalSupply">

                        <v-chip v-if="!contractValid && !checkExists" :style="windowWidth < 770 ? 'max-width:290px' : ''"
                          class="ma-2"
                          label
                        >
                          <v-icon left>
                            mdi-help-rhombus-outline
                          </v-icon>
                          Unknown Contract Address
                        </v-chip>

                        <v-chip v-if="showTotalSupply" :style="windowWidth < 770 ? 'max-width:290px' : ''"
                          class="ma-2"
                          label
                        >
                          <v-icon left>
                            mdi-grain
                          </v-icon>
                          Total Supply: {{ numberWithCommas(totalSupply) }} {{ windowWidth > 770 ? tokenSymbol  : '' }}
                        </v-chip>

                        <v-spacer></v-spacer>

                        <v-chip v-if="showTotalBurned" :style="windowWidth < 770 ? 'max-width:290px' : ''"
                          class="ma-2"
                          label
                        >
                          <v-icon left>
                            mdi-fire
                          </v-icon>
                          Total Burned: {{ totalBurned }}%
                        </v-chip>

                        <v-spacer></v-spacer>

                        <v-chip v-if="showTotalHolders" :style="windowWidth < 770 ? 'max-width:290px' : ''"
                          class="ma-2"
                          label
                        >
                          <v-icon left>
                            mdi-account-group
                          </v-icon>
                          Tot. Holders: {{ numberWithCommas(totalHolders) }}
                        </v-chip>

                      </v-layout>

                      <v-layout pt-4 pb-4 :column="windowWidth < 770" v-if="correctTotalSupply">

                        <v-text-field
                          v-model="totalSupply"
                          label="Total Supply"
                          maxlength="100"
                          outlined
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="totalBurned"
                          label="Total Burned"
                          maxlength="100"
                          outlined
                        ></v-text-field>
                        <v-spacer></v-spacer>
                        <v-text-field
                          v-model="totalHolders"
                          label="Total Holders"
                          maxlength="100"
                          outlined
                        ></v-text-field>

                      </v-layout>

                      <v-layout v-if="showTotalSupply" column align-center pb-4>
                        <v-btn color="orange" outlined small @click="correctTotalSupply = true" v-if="!correctTotalSupply">
                          Click here if {{ windowWidth > 770 ? 'this information is' : '' }} not correct
                        </v-btn>
                        <v-btn style="margin-top:-20px" color="green" outlined small @click="correctTotalSupply = false" v-if="correctTotalSupply">
                          Save this information
                        </v-btn>
                      </v-layout>

                      <v-text-field
                        v-model="tokenName"
                        label="Token Name"
                        placeholder="Enter the token name"
                        maxlength="50"
                        outlined
                        :disabled="contractValid && tokenAddress !== ''"
                        :rules="[v => !!v]"
                      ></v-text-field>

                      <v-text-field
                        v-model="tokenSymbol"
                        label="Token Symbol"
                        placeholder="Enter the token symbol"
                        maxlength="15"
                        outlined
                        :disabled="contractValid && tokenAddress !== ''"
                        :rules="[v => !!v]"
                        @change="checkUpperCase"
                      ></v-text-field>

                      <p class="font-weight-bold mt-2">
                        Links to Website and Social
                      </p>
                      <v-text-field dense
                        v-model="websiteLink"
                        label="Website"
                        placeholder="Enter the link to the website"
                        maxlength="50"
                        outlined
                      ></v-text-field>
                      <v-text-field dense
                        v-model="telegramLink"
                        label="Telegram"
                        placeholder="Enter link to Telegram"
                        maxlength="50"
                        outlined
                      ></v-text-field>
                      <v-text-field dense
                        v-model="twitterLink"
                        label="Twitter"
                        placeholder="Enter link to Twitter"
                        maxlength="50"
                        outlined
                      ></v-text-field>
                      <v-text-field dense
                        v-model="redditLink"
                        label="Reddit"
                        placeholder="Enter link to Reddit"
                        maxlength="50"
                        outlined
                      ></v-text-field>
                      <v-text-field dense
                        v-model="discordLink"
                        label="Discord"
                        placeholder="Enter link to Discord"
                        maxlength="50"
                        outlined
                      ></v-text-field>

                      <p  class="font-weight-bold ">
                        Token Logo URL or click to Upload
                      </p>

                      <v-layout row wrap pa-3>

                        <v-text-field :style="windowWidth < 770 ? 'width:100%' : 'width:60%'"
                          v-model="logoUrl" v-if="!logoUpload"
                          label="Logo URL"
                          placeholder="Logo URL or click to upload"
                          maxlength="1000"
                          outlined
                          @change="previewLogoChanged"
                          @input="previewLogoChanged"
                          :rules="[v => !!v]"
                        ></v-text-field>
                        <v-file-input v-model="files" outlined small-chips required v-if="logoUpload" :style="windowWidth < 770 ? 'max-width:300px' : ' '"
                              accept="image/png,image/jpg,image/jpeg" @change="handleFiles"
                              show-size label="Select your logo"
                              hint="Recommended size 250x250. Max 500KB."
                              persistent-hint>
                        </v-file-input>
                      <v-spacer v-if="windowWidth > 770"></v-spacer>
                      <v-layout :column="windowWidth > 770" :style="windowWidth < 770 ? 'width:100%;' : 'width:30%;margin-top:8px'">

                        <v-btn v-if="!previewLogo"
                          :disabled="logoUrl.indexOf('http') > -1"
                          :color="dark ? '#388E3C' : 'green lighten-3'"
                          @click="logoUpload = !logoUpload"
                          :style="windowWidth < 770 ? 'width:100%' : 'margin-bottom:2px'">{{ !logoUpload ? 'Upload Logo' : 'Logo URL' }}
                        </v-btn>
                        <v-layout column align-center v-else-if="previewLogo" style="margin-top:-20px">
                          <v-img style="max-height:100px;max-width:100px;min-height:100px;min-width:100px;"
                             :src="logoUrl"
                           ></v-img>
                        </v-layout>
                      </v-layout>
                  </v-layout>

                  <p class="font-weight-bold mt-2">
                    Token Launch Information
                  </p>

                  <v-checkbox
                    v-model="checkPresale"
                    :label="`Will there be a pre-sale? ${checkPresale ? 'Yes' : 'No'}`"
                  ></v-checkbox>

                  <!-- ######################### PRESALE DATE #########################-->

                  <v-layout :column="windowWidth < 770" v-if="checkPresale" pb-2>
                      <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="presaleDate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="presaleDate"
                            label="Token Presale Start Date"
                            append-icon="mdi-calendar-month"
                            readonly
                            outlined
                            v-on="on"
                            hint="You can always change this later if needed"
                            persistent-hint
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="presaleDate" :min="todayDate1" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                          <v-btn text color="primary" @click="$refs.dialog.save(presaleDate)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>

                      <v-spacer v-if="windowWidth > 770"></v-spacer>

                      <v-dialog
                        ref="dialog1"
                        v-model="modal1"
                        :return-value.sync="presaleTime"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="presaleTime"
                            label="Token Presale Time (Your Local Time)"
                            append-icon="mdi-clock-time-four-outline"
                            readonly
                            outlined
                            hint="Listing will show both Local and UTC"
                            persistent-hint
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="modal1"
                          v-model="presaleTime"
                          full-width
                          format="24hr"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal1 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog1.save(presaleTime)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-layout>

                    <v-text-field v-if="checkPresale"
                      v-model="tokenPresale"
                      label="Token Pre-sale Link"
                      placeholder="Enter pre-sale link"
                      maxlength="150"
                      hint="Put 'To be Announced' if still unknown, you can add this later"
                      persistent-hint
                      outlined
                    ></v-text-field>

                    <p v-if="checkPresale" class="mt-2"></p>

                 <!-- #########################LAUNCH DATE #########################-->

                  <v-layout :column="windowWidth < 770">
                      <v-dialog
                        ref="dialog2"
                        v-model="modal2"
                        :return-value.sync="launchDate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="launchDate"
                            label="Token Launch Date"
                            append-icon="mdi-calendar-month"
                            readonly
                            outlined
                            v-on="on"
                            hint="You can always change this later if needed"
                            persistent-hint
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="launchDate"  scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                          <v-btn text color="primary" @click="$refs.dialog2.save(launchDate)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>

                      <v-spacer v-if="windowWidth > 770"></v-spacer>

                      <v-dialog
                        ref="dialog3"
                        v-model="modal3"
                        :return-value.sync="launchTime"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="launchTime"
                            label="Token Launch Time (Your Local Time)"
                            append-icon="mdi-clock-time-four-outline"
                            readonly
                            outlined
                            hint="Listing will show both Local and UTC"
                            persistent-hint
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="modal3"
                          v-model="launchTime"
                          full-width
                          format="24hr"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            @click="modal3 = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog3.save(launchTime)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>

                    </v-layout>

                    <p class="font-weight-bold mt-2">
                      Write something special about this token
                    </p>

                    <tiptap-vuetify
                      v-model="tokenDescription"
                      :extensions="extensions"
                      placeholder="Write or paste your text here..."
                      maxlength="5000"
                      :toolbar-attributes="dark ? { color: 'grey darken-2' } : { color: 'grey lighten-2' }"
                    />

                  <br><br>

                  <v-btn @click="createNewTokenNext" :color="dark ? '#388E3C' : 'green lighten-3'" large block :disabled="!valid || checkExists || !contractValid">
                    <v-icon left size="25">mdi-arrow-right-bold</v-icon>Next</v-btn>
                  </v-form>
                </v-col>
             </v-layout>
           </v-card>

           <v-card v-if="(mmConnected || binanceConnected || walletConnected) && view === 2"
             class="mx-auto"
             :dark="dark"
             :min-width="windowWidth < 770 ? '' : 750"
             max-width="550"
           >

             <v-layout column  pl-4 pr-4 pb-4>
               <p class="promotionTitle font-weight-bold mt-4">
                 Promote this Token
               </p>

               <v-layout pb-4>
                 <v-img :style="windowWidth < 770 ? 'max-height:60px;max-width:60px;' :'max-height:100px;max-width:100px;'"
                    :src="logoUrl"
                  ></v-img>

                  <v-layout column pl-3>
                    <p :style="windowWidth < 770 ? 'font-size:18px;' : 'font-size:22px;margin-top:10px'" class="font-weight-bold">
                      {{ tokenName }} ({{ tokenSymbol }})
                    </p>
                    <p :style="windowWidth < 770 ? 'font-size:12px;margin-top:-10px' : 'font-size:14px'" class="wrap-text font-weight-medium">
                      {{ tokenNetwork }}: {{ windowWidth > 770 ? tokenAddress : tokenAddress.substr(0, 20) + '...' }} <v-icon color="grey" small style="margin-top:-2px">mdi-content-copy</v-icon>
                    </p>

                  </v-layout >
               </v-layout >

               <p style="font-size:18px" class="font-weight-bold mt-4">
                 Select a promotion package
               </p>

               <v-list dense two-line rounded :color="dark ? 'grey darken-3' : 'green lighten-5'" >
                  <template v-for="(item, index) in items">

                    <v-list-item :style="windowWidth < 770 ? 'padding-left:0px' : ''"
                      :key="index"
                    >
                      <v-list-item-avatar style="border-radius:0">
                        <v-icon :size="windowWidth < 770 ? 25 : 35">{{ item.avatar }}</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content >
                        <span :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text" v-html="item.title"></span>
                        <v-list-item-subtitle style="margin-top:5px" v-html="item.title_price_bnb"></v-list-item-subtitle>
                      </v-list-item-content>

                      <!-- <v-list-item-content v-else-if="tokenNetwork === 'ETH'">
                        <span :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text" v-html="item.title"></span>
                        <v-list-item-subtitle style="margin-top:5px" v-html="item.title_price_eth"></v-list-item-subtitle>
                      </v-list-item-content> -->

                      <v-list-item-action>
                        <v-checkbox @click="checkSelected(index)"
                          v-model="item.selected"
                        ></v-checkbox>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider
                      v-if="parseInt(index + 1) < items.length"
                      :key="index + 'd'"
                    ></v-divider>

                  </template>
                </v-list>

                <span style="font-size:12px" class="font-weight-medium mt-2">
                  You can always select a promotion after you have already added the token
                </span>

               <v-layout column pt-2>
                 <p class="mt-0"></p>
                 <v-btn v-if="promoteYes" @click="createToken()" :loading="loadingData" :color="dark ? '#388E3C' : 'green lighten-3'" large>
                   <v-icon left size="25">mdi-arrow-right-bold</v-icon>Complete
                 </v-btn>
                 <p v-if="!promoteYes" class="mt-0"></p>
                 <v-btn v-if="!promoteYes" @click="createToken()" :loading="loadingData" :color="dark ? '#388E3C' : 'green lighten-3'" large>
                   <v-icon left size="25">mdi-arrow-right-bold</v-icon>Skip for now
                 </v-btn>
                 <p class="mt-0"></p>
                 <v-btn @click="view = 1" outlined :color="dark ? 'green lighten-3' : 'green lighten-3'" large >
                  <v-icon left size="25">mdi-arrow-left-bold</v-icon>Back
                 </v-btn>

               </v-layout>
             </v-layout>
           </v-card>

           <v-card v-if="(mmConnected || binanceConnected || walletConnected) && view === 3"
             class="mx-auto"
             :dark="dark"
             :min-width="windowWidth < 770 ? '' : 750"
             max-width="800"
           >
             <v-layout column  pl-4 pr-4 pb-4>
               <p class="promotionTitle font-weight-bold mt-4">
                 Great! {{ tokenName }} ({{ tokenSymbol }}) has been added
               </p>

               <p class="text-center font-weight-medium mt-2">
                 The Token will now start to show up on the "New" list on Token Monster.<br>You can manage/edit the token, check the total views and likes in<br>My Account>Manage Tokens
               </p>

               <p class="text-center font-weight-medium mt-0">
                 When the token reaches 300 likes it will start to show up on the other lists on /tokens. Users can always search for the token by name, symbol and address.
               </p>
               <p class="text-center font-weight-medium mt-0">
                 This is the unique link that can be used on the social channels.<br>
                 <span :style="windowWidth < 770 ? 'font-size:14px;' : 'font-size:16px'" class="wrap-text font-weight-bold">
                   {{ windowWidth > 770 ? tokenLinkSlug : tokenSlug.substr(0, 35) + '...' }}
                   <v-icon color="grey" small style="cursor: pointer;margin-top:-2px" icon v-clipboard="() => tokenLinkSlug" @success="handleSuccess" @error="handleError">mdi-content-copy</v-icon>
                 </span>
               </p>

               <p class="text-center font-weight-bold mt-4" v-if="promoteYes">
                 In order for the "Promotion Packcage {{ promotionPackage }}" to become effective, please transfer {{ items[promotionPackage - 1].price_bnb }} BNB
               </p>
               <p class="text-center font-weight-medium" style="margin-top:-10px" v-if="promoteYes">
                 The Token will be promoted on the {{ promotionPackage === 1 || promotionPackage === 2 ||  promotionPackage === 3 ? '"Promoted Tokens" and "Launch Queue" list' : 'Promoted list'}}.<br>
                 <span style="font-size:12px">(The 300 likes does not apply to promoted tokens)</span>
               </p>

               <v-layout pt-4 pb-2 v-if="promoteYes">
                  <v-spacer></v-spacer>
                  <!-- <v-btn style="width:250px" :color="dark ? '#388E3C' : 'green lighten-3'" large @click="transferETHNow()" v-if="tokenNetwork === 'ETH'">
                    <v-icon left >mdi-send</v-icon>Transfer Now
                  </v-btn> -->
                  <v-btn v-if="getUser.networkChainID === '0x38'" style="width:250px" :color="dark ? '#388E3C' : 'green lighten-3'" large @click="transferBNBNow()">
                    <v-icon left >mdi-send</v-icon>Transfer Now
                  </v-btn>
                  <v-btn v-if="getUser.networkChainID !== '0x38'" text :color="dark ? '#388E3C' : 'green lighten-3'" large>
                    <v-icon left >mdi-send</v-icon>Please, switch to the BSC Mainnet
                  </v-btn>
                  <v-spacer></v-spacer>
               </v-layout>

               <p style="font-size:14px" class="text-center font-weight-medium mt-4" v-if="promoteYes && showTxFields">
                 For faster processing, please confirm the Tansaction ID below.
               </p>

               <v-text-field v-if="promoteYes && showTxFields"
                 v-model="tokenTxId"
                 label="Transaction ID"
                 placeholder="Enter the TxId"
                 maxlength="100"
                 outlined
                 :rules="[v => !!v]"
               ></v-text-field>

               <v-btn v-if="promoteYes && showTxFields" @click="updateTxId()" :loading="loadingData" :color="dark ? '#388E3C' : 'green lighten-3'" large>
                 <v-icon left size="25">mdi-arrow-right-bold</v-icon>Confirm Tx Id ({{ countDown }})
               </v-btn>

               <v-btn v-if="!promoteYes" @click="view = 4" :loading="loadingData" :color="dark ? '#388E3C' : 'green lighten-3'" large>
                 <v-icon left size="25">mdi-arrow-right-bold</v-icon>Ok
               </v-btn>

             </v-layout>
           </v-card>

           <v-card v-if="(mmConnected || binanceConnected ||  walletConnected) && view === 4"
             class="mx-auto"
             :dark="dark"
             width="windowWidth < 770 ? '' : 750"
             max-width="550"
           >
             <v-layout column pl-4 pr-4 pb-4>
               <p class="promotionTitle font-weight-bold mt-4">
                 Awesome, You're all done!
               </p>

               <!-- some nice image -->
               <v-layout column align-center>
                 <v-img
                   src="img/home/awesome.png"
                   class="white--text align-end"
                   style="opacity:0.9"
                   :width="windowWidth < 770 ? 100 : 170"
                 >
                 </v-img>
               </v-layout>
               <p class="mt-2"></p>
               <p class="text-center font-weight-medium mt-4">
                 When your token reached 300 likes we will conduct some vetting and ask you to provide Telegram Admin account so we can contact you in case of any issues.
               </p>
               <p class="mt-4"></p>

               <v-btn  to="/tokens/new" :loading="loadingData" :color="dark ? '#388E3C' : 'green lighten-3'" large>
                 <v-icon left size="25">mdi-format-list-bulleted-type</v-icon>Go to the Token List
               </v-btn>
               <p class="mt-0"></p>
               <v-btn  @click="createAnother()" :dark="dark" :loading="loadingData" outlined :color="dark ? 'white' : 'green lighten-3'" large>
                 <v-icon left size="25">mdi-plus</v-icon>Add another token
               </v-btn>

             </v-layout>

           </v-card>

           <p class="mt-4"></p>

         </v-layout>

         <!-- ############################################ DIALOGS ################################################# -->

         <v-dialog v-model="contractLoadingDialog" persistent max-width="400">
           <v-card
            :dark="dark"
           >
           <v-toolbar flat y>
             <v-card-title style="font-size:18px" class="font-weight-bold">Loading Contract Data</v-card-title>
             <v-spacer></v-spacer>
             <v-btn icon @click="contractLoadingDialog = false">
               <v-icon>mdi-close</v-icon>
             </v-btn>
           </v-toolbar>

           <v-layout column align-center pa-4>
             <p style="font-size: 12px">Checking the contract informationon the {{ tokenNetwork }} network.</p>
             <v-progress-linear
              color="#388E3C"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
           </v-layout>

             <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn
                  color="grey darken-1"
                  text
                  @click="contractLoadingDialog = false"
                >
                  Close
                </v-btn>
             </v-card-actions>

           </v-card>
         </v-dialog>

         <v-dialog v-model="contractExistsDialog" persistent max-width="400">
           <v-card
            :dark="dark"
           >
           <v-toolbar flat y>
             <v-card-title style="font-size:18px" class="font-weight-bold">Contract Address Exists!</v-card-title>
             <v-spacer></v-spacer>
             <v-btn icon @click="contractExistsDialogClose">
               <v-icon>mdi-close</v-icon>
             </v-btn>
           </v-toolbar>

           <v-layout column align-center pa-4>
             <p>The contract address that you are trying to add is already registered on Token Monster.<br>
                Please check the address.</p>
           </v-layout>

             <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn
                  color="grey darken-1"
                  text
                  @click="contractExistsDialogClose"
                >
                  Close
                </v-btn>
               <v-btn color="green darken-3" text @click="contractExistsLearnMore">Learn more</v-btn>
             </v-card-actions>

           </v-card>
         </v-dialog>

      </v-layout>
    </v-slide-y-transition>


    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
      <v-icon color="green">mdi-check-circle-outline</v-icon>
      <span >{{ snackbarText }}</span>
            <v-btn text color="pink" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

  </div>
</template>

<script>
// import store from '@/store/index'
import { isMobile } from 'mobile-device-detect'
import firebase from "firebase/app"
import "firebase/auth"
import { db } from '@/main'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import {scroller} from 'vue-scrollto/src/scrollTo'
import MetaMaskConnect from '@/views/app/MetaMaskConnect'
import BinanceConnect from '@/views/app/BinanceConnect'
import WalletConnect from '@/views/app/WalletConnect'
import TokenMonsterAPI from '@/clients/TokenMonsterAPI'
const dateformat = require('dateformat')
// const itemsPromotion = require('./promotion')
// const Web3 = require('web3')
// const web3 = new Web3('https://bsc-dataseed1.binance.org:443')
// import BinanceAPI from '@/clients/BinanceAPI'
export default {
  name: 'promote',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    scrollY: Number
  },
  data () {
    return {
      lang: this.$store.state.lang,
      isMobileDevice: false,
      loading: true,
      loadingData: false,
      currentUser: null,
      showBottomNav: false,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      contractLoadingDialog: false,
      contractExistsDialog: false,
      learnMoreDialog: false,
      signUpView: 1,
      installMM: false,
      count: 0,
      show: false,
      view: 1,
      valid: false,
      newPostKey: {},
      tokenStatus: 2,
      tokenNetwork: 'BSC',
      tokenAddress: '',
      tokenName: '',
      tokenSymbol: '',
      tokenSlug: '',
      tokenLinkSlug: '',
      checkExists: false,
      contractValid: true,
      totalSupply: 0,
      showTotalSupply: false,
      correctTotalSupply: false,
      decimals: 0,
      totalHolders:  0,
      showTotalHolders: false,
      totalBurned: 0,
      showTotalBurned: false,
      websiteLink: '',
      telegramLink: 'https://t.me/',
      twitterLink: 'https://twitter.com/',
      redditLink: '',
      discordLink: '',
      logoUrl: '',
      logoUpload: false,
      previewLogo: false,
      logoImage: '',
      files: [],
      filesValid: false,
      todayDate1: new Date().toISOString().substr(0, 10),
      todayDate: new Date().toISOString().substr(0, 10),
      presaleDate: new Date().toISOString().substr(0, 10),
      presaleTime: new Date().toISOString().substr(11, 5),
      launchDate: new Date().toISOString().substr(0, 10),
      launchTime: new Date().toISOString().substr(11, 5),
      expDate: new Date().toISOString().substr(0, 10),
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      checkPresale: false,
      tokenPresale: '',
      tokenDescription: '',
      promoteYes: false,
      promotionPackage: 0,
      tokenTxId: '',
      showTxFields: false,
      binanceChainID: '',
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      countDown: 5,
      items: this.$store.state.packages
      /* items: [
        {
          avatar: 'mdi-numeric-1-box-multiple-outline',
          title: '<strong>7 Days</strong> - 3 days pre and 4 days post Launch',
          title_price_bnb: 'Price 0.25 BNB',
          price_bnb: 0.15,
          price_hex: '0x214E8348C4F0000',
          title_price_eth: 'Price 0.035 ETH',
          price_eth: 0.25,
          selected: false
        },
        {
          avatar: 'mdi-numeric-2-box-multiple-outline',
          title: '<strong>14 Days</strong> - 7 days pre and 7 days post Launch',
          title_price_bnb: 'Price 0.35 BNB',
          price_bnb: 0.25,
          price_hex: '0x3782DACE9D90000',
          title_price_eth: 'Price 0.045 ETH',
          price_eth: 0.35,
          selected: false
        },
        {
          avatar: 'mdi-numeric-3-box-multiple-outline',
          title: '<strong>30 Days</strong> - 15 days pre and 15 days post Launch',
          title_price_bnb: 'Price 0.45 BNB',
          price_bnb: 0.35,
          price_hex: '0x4DB732547630000',
          title_price_eth: 'Price 0.055 ETH',
          price_eth: 0.45,
          selected: false
        },
        {
          avatar: 'mdi-numeric-4-box-multiple-outline',
          title: '<strong>15 Days</strong> - Token already Launched',
          title_price_bnb: 'Price 0.3 BNB',
          price_bnb: 0.2,
          price_hex: '0x2C68AF0BB140000',
          title_price_eth: 'Price 0.04 ETH',
          price_eth: 0.03,
          selected: false
        },
        {
          avatar: 'mdi-numeric-5-box-multiple-outline',
          title: '<strong>30 Days</strong> - Token already Launched',
          title_price_bnb: 'Price 0.4 BNB',
          price_bnb: 0.3,
          price_hex: '0x429D069189E0000',
          title_price_eth: 'Price 0.05 ETH',
          price_eth: 0.04,
          selected: false
        }
      ], */
    }
  },
  components: {
    MetaMaskConnect,
    BinanceConnect,
    WalletConnect,
    TiptapVuetify
  },
  watch: {
    windowWidth () {
      if (this.windowWidth < 768) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
    },
    tokenNetwork () {
      if (this.tokenAddress !== '') {
        this.lookupTotalSupply()
      }
    }
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    isLoggedIn () {
      return this.$store.state.user.isLoggedIn
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    walletConnected () {
      return this.$store.state.user.walletConnected
    },
    getAccounts () {
      return this.$store.state.user.accounts
    },
    getUser () {
      return this.$store.state.user
    },
    getPaymentAddress () {
      return this.$store.state.paymentAddress
    },
    getPlatform () {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform
    }
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
  },
  created () {
    // store.commit('HideNav')
    // store.commit('ShowNavHome')
    //
    this.isMobileDevice = isMobile
    this.currentUser = firebase.auth().currentUser
    // console.log(this.getPlatform)
    this.scrollToTop()
    this.init()
  },
  methods: {
    init () {
      if (this.windowWidth < 768) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
      this.loading = false
      for (var i in this.items) {
        this.items[i].selected = false
      }
      // if firebase logged in >>> goto add coin form
      var minDate = new Date().getTime() - (1000 * 60 * 60 * 24 * 90) // - 90 days 3 months
      this.todayDate = dateformat(minDate, 'yyyy-mm-dd')
    },
    scrollToTop () {
      const firstScrollTo = scroller()
      setTimeout(() => {
        firstScrollTo('#promote')
      }, 200)
    },
    checkEmailExist () {

    },
    checkSelected (index) {
      // console.log(this.items[index].selected)
      var count = 0
      if (this.items[index].selected) {
        this.promoteYes = true
        this.promotionPackage = index + 1
      } else {
        count += 1
      }
      for (var i in this.items) {
        if (parseInt(i) !== index) {
          this.items[i].selected = false
          count += 1
        }
      }
      if (count === 5) {
        this.promoteYes = false
      }
    },
    socialLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider).then(() => {
        // console.log(result)
        // Check if user in user bucket
        // do the stuff first
        // then reload
        window.location.reload()
      }).catch((err) => {
        alert('Oops. ' + err.message)
      })
    },
    handleFiles () {
      // console.log(this.files)
      if (this.files === undefined || this.files === null) {
        this.logoUrl = ''
        this.previewLogo = false
        this.filesValid = false

      } else if (this.files.size > 500000) { // === 500 KB
        this.files = []
        this.showRowAlert = true
        this.showRowAlertText = 'Maximum file size of 500KB exeeded for ' + this.files.name + '!'

      } else {
        this.filesValid = true
        this.logoUrl = this.files.name
        this.useImage()
      }
    },
    useImage () {
      // console.log(this.files)
      if (this.logoUrl === '') return
      this.logoUrl = this.files.name
      this.getBase64(this.files).then(
        data => this.logoUrl = data
      )
      this.previewLogoChanged()
    },
    checkUpperCase () {
      this.tokenSymbol = this.tokenSymbol.toUpperCase()
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    },
    createNewTokenNext () {
      // promote view (optional)
      // this will take you to the top 10 list bla bla pre and post-launch lists
      this.view = 2
      this.scrollToTop()
    },
    previewLogoChanged () {
      if (this.logoUrl.indexOf('http') > -1 || this.logoUrl !== '') {
        this.previewLogo = true
      } else {
        this.previewLogo = false
      }
    },
    createToken () {
      this.loadingData = true
      this.newPostKey = db.collection('tokens').doc()
      let publishTokenDate = new Date().getTime()
      let expireTokenDate = 0
      if (this.promoteYes) {
        // Check the package and network etc.
        if (this.promotionPackage === 1) {
          publishTokenDate = new Date(this.launchDate + ' ' + this.launchTime).getTime() - 3 * 24 * 60 * 60 * 1000
          expireTokenDate = publishTokenDate + 7 * 24 * 60 * 60 * 1000
        } else if (this.promotionPackage === 2) {
          publishTokenDate = new Date(this.launchDate + ' ' + this.launchTime).getTime() - 7 * 24 * 60 * 60 * 1000
          expireTokenDate = publishTokenDate + 14 * 24 * 60 * 60 * 1000
        } else if (this.promotionPackage === 3) {
          publishTokenDate = new Date(this.launchDate + ' ' + this.launchTime).getTime() - 15 * 24 * 60 * 60 * 1000
          expireTokenDate = publishTokenDate + 30 * 24 * 60 * 60 * 1000
        } else if (this.promotionPackage === 4) {
          // publishTokenDate = new Date().getTime()
          expireTokenDate = new Date().getTime() + 15 * 24 * 60 * 60 * 1000
        } else if (this.promotionPackage === 5) {
          // publishTokenDate = new Date().getTime()
          expireTokenDate = new Date().getTime() + 30 * 24 * 60 * 60 * 1000
        }
      }
      let searchkeys = []
      let splitName = this.tokenName.split(' ')
      for (var i in splitName) {
        searchkeys.push(splitName[i].toLowerCase())
      }
      searchkeys.push(this.tokenName.toLowerCase())
      searchkeys.push(this.tokenSymbol.toLowerCase())
      searchkeys.push(this.tokenAddress.toLowerCase())
      searchkeys.push(this.tokenNetwork.toLowerCase())
      // newsStory = newsStory.replaceAll('<em>', '<i>')
      // newsStory = newsStory.replaceAll('</em>', '</i>')
      var slug = this.tokenName.toLowerCase()
      slug = slug.replaceAll(' ', '-')
      slug = slug.replaceAll('/', '')
      slug = slug.replaceAll('?', '')
      slug = slug.replaceAll('!', '')
      this.tokenSlug = slug + '-' + this.getRandomString(3)
      this.tokenLinkSlug =  'https://tokenmonster.app/token/' + this.tokenSlug

      let dispatchObj = {
        // postkey: this.newPostKey.id,
        uid: this.getUser.uid,
        name: this.tokenName,
        symbol: this.tokenSymbol,
        network: this.tokenNetwork,
        contract_address: this.tokenAddress,
        slug: this.tokenSlug,
        link_website: this.websiteLink,
        link_telegram: this.telegramLink,
        link_twitter: this.twitterLink,
        link_reddit: this.redditLink,
        link_discord: this.discordLink,
        keywords: searchkeys,
        logo: this.logoUrl,
        // presale_date: new Date(this.pubPreSaleDate + ' ' + this.pubPreSaleTime).getTime(),
        launch_date_presale: this.checkPresale ? new Date(this.presaleDate + ' ' + this.presaleTime).getTime() : 0,
        launch_date: new Date(this.launchDate + ' ' + this.launchTime).getTime(),
        presale: this.checkPresale,
        presale_link: this.tokenPresale,
        publish_date: publishTokenDate, // X Days before Launch Date if pakcage 1,2,3
        expire_date: expireTokenDate, // Look at the package
        description: this.tokenDescription,
        status: this.tokenStatus,
        impressions: 0,
        views: 0, // When Detailed clicked
        likes: 0, // When smiley
        dislikes: 0, // when sad face (monster face)
        rating: 0, // Maybe
        shared: 0,
        promoted: this.promoteYes,
        promoted_package: this.promotionPackage,
        promoted_paid: false,
        promoted_tx_id: '',
        created_by: this.getUser.displayName,
        created: new Date().getTime(),
        modified_by: '',
        modified: 0,
        total_supply: parseInt(this.totalSupply),
        total_holders: parseInt(this.totalHolders),
        total_burned: parseFloat(this.totalBurned),
        total_decimals: this.decimals,
        last_price: 0,
        comment_count: 0,
        scam_count: 0
      }
      console.log(dispatchObj)
      db.collection('tokens').doc(this.newPostKey.id).set(dispatchObj)
      .then(() => {
          // console.log(ref)
          // console.log('New Token in bucket created')
          this.snackbarText = 'Token added succesfully!'
          this.snackbar = true
          this.loadingData = false
          this.view = 3
        })
      .catch(error => {
          alert(error)
          this.loadingData = false
          // Handle error
        })
    },
    getRandomString(length) {
      var randomChars = 'bcdfghjklmnpqrstvwxz'
      var result = '';
      for ( var i = 0; i < length; i++ ) {
          result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
      }
      return result
    },
    lookupPackage (id) {
      let text = ''
      if (this.tokenNetwork === 'BSC') {
        text = this.items[parseInt(id) - 1].price_bnb + ' BNB'
      } else if (this.tokenNetwork === 'ETH') {
        text = this.items[parseInt(id) - 1].price_eth + ' ETH'
      }
      return text
    },
    lookupTotalSupply () {
      this.totalSupply = 0
      this.contractValid =  true
      if (this.tokenAddress !== '') {
        // Check in fb if contract address already used
        let query = db.collection('tokens').where('contract_address', '==', this.tokenAddress).limit(1)
        query.get()
        .then(snapshot => {
          if (snapshot.empty) {
            console.log('No matching documents.')
            this.contractLoadingDialog = true
            this.checkExists = false

            if (this.tokenNetwork == 'KCC') {
              this.getTokenInfoKCC()
              return
            }

            Promise.resolve(TokenMonsterAPI.getTotalSupply(this.tokenAddress, this.tokenNetwork))
            .then(result => {
              // console.log(result)
              if (result.data.data.result === "0") {
                this.contractLoadingDialog = false
                this.contractValid =  false
                this.showTotalSupply = false
                this.showTotalHolders = false
                this.showTotalBurned = false
                this.tokenName = ''
                this.tokenSymbol = ''
                return
              } else {
                // console.log(parseInt(result.data.result) / 1000000000)
                var tempTotalSupply = result.data.data.result

                Promise.resolve(TokenMonsterAPI.getTotalHolders(this.tokenAddress, this.tokenNetwork))
                .then(result => {
                  // console.log(result.data.data.toString())
                  var html = result.data.data.toString()
                  var startTitle = html.indexOf('<title>')
                  var endTitle = html.indexOf('</title>')
                  var tokenTotalName = html.substr(startTitle + 7, endTitle)
                  var tab = RegExp('\\t', 'g')
                  tokenTotalName = tokenTotalName.replace(tab, '')
                  // tokenTotalName.replace(/(\r\n|\n|\r)/gm,'')
                  var nameIndexEnd = tokenTotalName.indexOf('(')
                  var nameIndexEnd2 = tokenTotalName.indexOf(')')
                  var tokenName = tokenTotalName.substr(1, nameIndexEnd - 2)
                  tokenName = tokenName.replace(/(\r\n|\n|\r)/gm, '')
                  var check = nameIndexEnd2 - nameIndexEnd - 1
                  var tokenSymbol = tokenTotalName.substr(nameIndexEnd + 1, check)
                  if (this.tokenNetwork === 'ETH') {
                    var len = tokenName.length
                    var ind = tokenName.indexOf('|')
                    tokenName = tokenName.substr(ind + 1, len-(ind + 1))
                    // <span class="text-secondary small">
                  }
                  /* console.log('startTitle')
                  console.log(startTitle)
                  console.log('endTitle')
                  console.log(endTitle)
                  console.log(tokenTotalName)
                  console.log(tokenName) */
                  this.tokenName = tokenName
                  this.tokenSymbol = tokenSymbol

                  var startIndex = html.indexOf('<div class="mr-3">')
                  // var stopIndex = html.indexOf('addresses')
                  var stopIndex = 0
                  var holders = 0
                  if (this.tokenNetwork === 'BSC') {
                    stopIndex = this.getIndicesOf('addresses', html)
                    holders = html.substr(startIndex + 18, (stopIndex[1] - 1) - (startIndex + 18))
                  } else {
                    stopIndex = html.indexOf('sparkholderscontainer')
                    holders = html.substr(startIndex + 18, (stopIndex - 17) - (startIndex + 18))
                  }
                  /* console.log('startIndex')
                  console.log(startIndex)
                  console.log('stopIndex')
                  console.log(stopIndex)
                  console.log(holders) */
                  this.totalHolders = parseInt(holders.replaceAll(',', ''))
                  this.showTotalHolders = true
                  this.contractLoadingDialog = false

                  var startDecimals = html.indexOf("'decimals':")
                  var decTemp
                  var decimals
                  if (startDecimals === -1) {
                    startDecimals = html.indexOf("&decimal=")
                    decTemp = html.substr(startDecimals + 9, 3)
                    decimals = decTemp.replaceAll('\'', '')
                    /* console.log('startDecimals')
                    console.log(startDecimals)
                    console.log(decTemp)
                    console.log(decimals) */
                    this.decimals = parseInt(decimals)
                  } else {
                    decTemp = html.substr(startDecimals + 12, 4)
                    decimals = decTemp.replaceAll('\'', '').replace(',', '')
                    /* console.log('startDecimals')
                    console.log(startDecimals)
                    console.log(decTemp)
                    console.log(decimals) */
                    this.decimals = parseInt(decimals)
                  }
                  // console.log('parseInt(tempTotalSupply)')
                  // console.log(parseInt(Math.pow(10, this.decimals)))
                  this.totalSupply = Math.round(parseInt(tempTotalSupply) / Math.pow(10, this.decimals))
                  this.showTotalSupply = true

                })
                .catch(err => {
                  this.loading = false
                  console.log('Error getting documents.', err)
                  // show friendly error in user screen
                })

                Promise.resolve(TokenMonsterAPI.getTotalBurned(this.tokenAddress, this.tokenNetwork))
                .then(result => {
                  // console.log(result.data.data.toString())
                  var html = result.data.data.toString()
                  var startBurnIndex = html.indexOf('0x00000000000000000000')
                  // console.log('startBurnIndex')
                  // console.log(startBurnIndex)
                  var textBurn = html.substr(startBurnIndex, 270)
                  // console.log('textBurn')
                  // console.log(textBurn)
                  var endBurnIndex = textBurn.indexOf('%')
                  var finalTextBurn = textBurn.substr(endBurnIndex - 7, 7)
                  // console.log('finalTextBurn')
                  // console.log(finalTextBurn)
                  if  (startBurnIndex === -1) {
                    this.totalBurned = 0
                    this.showTotalBurned = true
                  } else {
                    finalTextBurn = finalTextBurn.replace('>', '')
                    this.totalBurned = parseFloat(finalTextBurn)
                    this.showTotalBurned = true
                  }
                  // this.contractLoadingDialog = false
                })
                .catch(err => {
                  this.loading = false
                  console.log('Error getting documents.', err)
                  // show friendly error in user screen
                })
              }
            })
            .catch(err => {
              this.loading = false
              console.log('Error getting documents.', err)
              // show friendly error in user screen
            })
          }
          snapshot.forEach(() => {
            // console.log('Token Address already exists in Token Monster')
            // console.log(doc.id, '=>', doc.data())
            this.contractLoadingDialog = false
            this.contractValid = false
            this.checkExists = true
            this.contractExistsDialog = true
          })
        })
        .catch(err => {
          console.log('Error getting documents', err)
        })
      } else {
        this.contractLoadingDialog = false
        this.contractValid = true
        this.showTotalSupply = false
        this.showTotalHolders = false
        this.showTotalBurned = false
        this.tokenName = ''
        this.tokenSymbol = '',
        this.totalSupply = 0
        this.totalBurned = 0
        this.totalHolders = 0
      }
    },
    getTokenInfoKCC () {
      console.log('Yai, it\'s a KCC token')

      Promise.resolve(TokenMonsterAPI.getTokenInfoKCC(this.tokenAddress, this.tokenNetwork))
      .then(result => {
        console.log(result) // result.data.data.data.tokenInfo
        // result.data.data.data.holderCnt
        var tokenInfo
        if (result.data.data.code === 1) {

          tokenInfo = result.data.data.data.tokenInfo
          this.tokenName = tokenInfo.f
          this.tokenSymbol = tokenInfo.s
          this.decimals = parseInt(tokenInfo.d)
          this.totalSupply = parseInt(tokenInfo.t)
          this.totalHolders = result.data.data.data.holderCnt
          this.totalBurned = 0

          // console.log(typeof tokenInfo.l)
          if (typeof tokenInfo.l !== 'undefined') {
            this.logoUrl = tokenInfo.l
            this.previewLogoChanged()
          }

          this.contractLoadingDialog = false
          this.contractValid =  true
          this.showTotalSupply = true
          this.showTotalHolders = true
          this.showTotalBurned = true

        } else {
          this.contractLoadingDialog = false
          this.contractValid =  false
          this.showTotalSupply = false
          this.showTotalHolders = false
          this.showTotalBurned = false
          this.tokenName = ''
          this.tokenSymbol = ''
          this.logoUrl = ''
          this.previewLogoChanged()
        }
      })
      .catch(err => {
        this.loading = false
        console.log('Error getting KCC token info.', err)
        // show friendly error in user screen
      })
    },
    contractExistsDialogClose () {
      this.tokenAddress = ''
      this.contractExistsDialog = false
    },
    contractExistsLearnMore () {
      this.tokenAddress = ''
      this.contractExistsDialog = false
      this.learnMoreDialog = true
    },
    getIndicesOf(searchStr, str, caseSensitive) {
        var searchStrLen = searchStr.length
        if (searchStrLen == 0) {
            return []
        }
        var startIndex = 0, index, indices = []
        if (!caseSensitive) {
            str = str.toLowerCase()
            searchStr = searchStr.toLowerCase();
        }
        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            indices.push(index)
            startIndex = index + searchStrLen
        }
        return indices;
    },
    transferETHNow () {
      // console.log('ETH Transaction')
      window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: this.getAccounts[0],
            to: this.getPaymentAddress,
            value: '0x3782DACE9D90000',
            gasPrice: '0x09184e72a000',
            gas: '0x2710',
          },
        ],
      })
      .then((txHash) => console.log(txHash))
      .catch((error) => console.log(error))
    },
    transferBNBNow () {
      console.log('BNB Transaction')
      // Check Network ChainID
      // console.log(window.ethereum.networkVersion, 'window.ethereum.networkVersion'); ///  must be 56

      if (this.getUser.walletProvider === 'MetaMask'|| this.getUser.walletProvider === 'WalletConnect') {
        window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [
            {
              input: 'SEND BNB',
              from: this.getAccounts[0],
              to: this.getPaymentAddress,
              value: this.items[this.promotionPackage - 1].price_hex,
              gas: '0x5208',
              gasPrice: '0xBA43B7400',
              chainId: '0x38'
            },
          ],
        })
        .then((txHash) => {
          this.tokenTxId = txHash
          this.showTxFields = true
          this.resultInterval = setInterval(() => {
          this.countDown -= 1
            if (this.countDown === 0) {
              clearInterval(this.resultInterval)
              this.updateTxId()
            }
          }, 1000)
        })
        .catch((error) => console.log(error))

      } else if (this.getUser.walletProvider === 'BinanceChainWallet') {
        window.BinanceChain.request({
           method: 'eth_sendTransaction',
           params: [
             {
               from: this.getAccounts[0],
               to: this.getPaymentAddress,
               value: this.items[this.promotionPackage - 1].price_hex,
               gas: '0x5208',
               gasPrice: '0xBA43B7400'
             },
           ],
         })
         .then((txHash) => {
           this.tokenTxId = txHash
           this.showTxFields = true
           this.resultInterval = setInterval(() => {
           this.countDown -= 1
             if (this.countDown === 0) {
               clearInterval(this.resultInterval)
               this.updateTxId()
             }
           }, 1000)
         })
         .catch((error) => console.log(error))
      }

      // Most likely gonna be a API call axios
      /* let fromAddress = this.getAccounts[0]
      let toAddress = '0x6EDd445C22b80972C08F17EE2c066B31d3F9E8Dc'
      let amount =  this.items[this.promotionPackage - 1].price_bnb
      let asset = 'BNB'
      let memo = this.currentUser.uid */
       // window.web3 = new Web3(window.BinanceChain)
       // do stuff
       // console.log(window.BinanceChain)
       // console.log(web3)
       //

      /* Promise.resolve(BinanceAPI.transfer(fromAddress, toAddress, amount, asset, memo))
      .then(result => {
        console.log(result)
      })
      .catch(err => {
        this.loading = false
        console.log('Error getting documents.', err)
        // show friendly error in user screen
      }) */

      // using the event emitter
      /* console.log(this.getAccounts[0])
      web3.eth.sendTransaction({
          from: this.getAccounts[0],
          to: '0x6EDd445C22b80972C08F17EE2c066B31d3F9E8Dc',
          value: '1000000000000000',
          chainId: '0x38',
          symbol: 'BNB'
      })
      .on('transactionHash', function(hash) {
        console.log(hash)
      })
      .on('receipt', function(receipt) {
        console.log(receipt)
      })
      .on('confirmation', function(confirmationNumber, receipt) {
        console.log(confirmationNumber)
        console.log(receipt)
      })
      .on('error', console.error) */ // If a out of gas error, the second parameter is the receipt.
    },
    updateTxId () {
      console.log('Update Tx Id')
      // this.newPostKey.id)
      let dispatchObj = {
        promoted_tx_id: this.tokenTxId,
        promoted_paid: true
      }
      db.collection('tokens').doc(this.newPostKey.id).update(dispatchObj)
      .then(() => {
        // console.log('Tx Id in token bucket updated')
        this.snackbarText = 'Tx Id Confirmed!'
        this.snackbar = true
        this.view = 4
      })
      .catch(error => {
          console.log(error)
        })
    },
    createAnother () {
      this.view = 1
      this.tokenName = ''
      this.tokenSymbol = ''
      this.tokenAddress = ''
      this.tokenSlug = ''
      this.totalSupply = 0
      this.showTotalSupply = false
      this.totalHolders = 0
      this.showTotalHolders = false
      this.totalBurned = 0
      this.showTotalBurned = false
      this.tokenTxId = ''
      this.websiteLink = ''
      this.telegramLink = 'https =//t.me/'
      this.twitterLink = 'https =//twitter.com/'
      this.redditLink = ''
      this.discordLink = ''
      this.logoUrl = ''
      this.logoUpload = false
      this.previewLogo = false
      this.logoImage = ''
      this.files = []
      this.filesValid = false
      this.todayDate = new Date().toISOString().substr(0, 10)
      this.presalehDate = new Date().toISOString().substr(0, 10)
      this.presaleTime = new Date().toISOString().substr(11, 5)
      this.launchDate = new Date().toISOString().substr(0, 10)
      this.launchTime = new Date().toISOString().substr(11, 5)
      this.expDate = new Date().toISOString().substr(0, 10)
      this.checkPresale = false
      this.tokenPresale = ''
      this.tokenDescription = ''
      this.promoteYes = false
      this.promotionPackage = 0
      this.tokenTxId = ''
      this.showTxFields = false
    },
    handleSuccess(e) {
      console.log(e);
      this.snackbarText = 'Link copied to clipboard.'
      this.snackbar = true
    },
    handleError(e) {
      console.log(e);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      // console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style>
  .promotionTitle {
    font-family: 'Comfortaa';
    font-size: 24px;
    font-weight: 900;
    text-align: center;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
  .wrapperDark{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrapperLight{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrap-text {
    word-break: normal;
  }
</style>
