<template>
  <div id="tokens" :style="dark ? 'background:#272727;height: 100%;' : 'background:#FFF;height: 100%;'" >
    <v-slide-y-transition mode="out-in">
      <v-layout column pl-2 pr-2 :style="windowWidth < 768 ? '' : 'margin-left:15%;margin-right:15%'">
       <!-- ####################### SKELETON LOADERS ####################### -->
       <v-layout column v-if="loading">
         <p class="mt-0"></p>

         <v-layout column >

           <v-layout >
             <v-spacer></v-spacer>
             <v-skeleton-loader
               class="mx-auto"
               type="image"
               width="300"
               height="50"
             ></v-skeleton-loader>

             <v-spacer></v-spacer>
           </v-layout >

            <p class="mt-0"></p>

           <v-spacer></v-spacer>
             <template v-for="n in 3" >
               <v-skeleton-loader
                 class="mx-auto"
                 type="card"
                 width="100%"
                 :key="n"
               ></v-skeleton-loader>
               <v-spacer :key="n + 'b'"></v-spacer>
             </template>
           <v-spacer></v-spacer>
         </v-layout>

       </v-layout>

       <!-- ############################################## COMPONENTS ##############################################  -->

         <v-layout column  v-else pt-2>
           <!-- v-show="!loadingData" -->

           <p class="mt-0"></p>
           <v-text-field
             v-model="search"
             outlined
             :dark="dark"
             placeholder="Search by Address / Token Name / Symbol..."
             maxlength="60"
             single-line
             append-icon="mdi-magnify"
             v-on:keyup="searchClicked"
             @click:append="searchClicked('click')"
           ></v-text-field>

           <v-tabs v-show="!showBottomNav && !searchTrigger && !firstSearch"
              v-model="tab"
              centered
              :dark="dark"
              icons-and-text
              style="margin-top:-20px"
            >
              <v-tabs-slider color="#388E3C"></v-tabs-slider>

              <v-tab :key="1" @click="tab1Clicked">
                Promoted
                <v-icon>mdi-bullhorn</v-icon>
              </v-tab>

              <v-tab :key="2" @click="tab2Clicked">
                Most Popular
                <v-icon>mdi-trending-up</v-icon>
              </v-tab>

              <v-tab :key="3" @click="tab3Clicked">
                Just Launched
                <v-icon>mdi-rocket-launch</v-icon>
              </v-tab>

              <v-tab :key="4" @click="tab4Clicked">
                Launch Queue
                <v-icon>mdi-format-list-bulleted-triangle</v-icon>
              </v-tab>

              <v-tab :key="5" @click="tab5Clicked">
                New
                <v-icon>mdi-expand-all</v-icon>
              </v-tab>

              <v-tab :key="6" @click="tab6Clicked">
                Favorites
                <v-icon>mdi-star</v-icon>
              </v-tab>

            </v-tabs>

            <v-tabs-items v-show="!loading && !searchTrigger && !firstSearch" touchless v-model="tab" :dark="dark" :style="windowWidth < 770 ? 'margin-top:-30px' : ''">
              <v-tab-item
                :key="1"
               >
               <v-layout column pr-1 pl-1 pt-2>

                 <v-layout column align-center v-if="loadingPromoted">
                   <v-progress-circular
                      indeterminate
                      color="#434908"
                    ></v-progress-circular>
                   <p class="mt-2"></p>
                 </v-layout>

                 <v-list shaped color="transparent" style="width:100%">
                   <v-layout :column="windowWidth < 770" :style="windowWidth < 770 ? 'margin-left:16px' : ''">
                     <v-spacer></v-spacer>
                     <v-layout :style="windowWidth < 770 ? 'margin-top:-10px' : ''">
                       <v-spacer v-if="windowWidth > 770"></v-spacer>
                       <span style="margin-top:13px;margin-right:5px">Filter</span>
                       <v-chip-group
                          v-model="toggleNetwork"
                          active-class="primary--text"
                          column
                        >
                          <v-chip filter value="BSC">
                            <v-img src="/img/icons/binance-coin-logo.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                          <v-chip filter value="ETH">
                            <v-icon color="blue-grey lighten-1">
                              mdi-ethereum
                            </v-icon>
                          </v-chip>
                          <v-chip filter value="MATIC">
                            <v-img src="/img/icons/polygon.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                          <v-chip filter value="KCC">
                            <v-img src="/img/icons/kucoin.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                        </v-chip-group>
                      </v-layout>
                    </v-layout>
                   <template v-for="(item, index) in filterPromotedTokens">
                    <v-list-item
                      link
                      :key="index"
                      @click="openTokenDetail(item)"
                    >
                    <v-list-item-avatar style="border-radius: 2px">
                       <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                       <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                     </v-list-item-avatar>

                      <v-list-item-content>
                        <span :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }}) </span>
                        <v-list-item-subtitle class="wrap-text font-weight-bold" style="margin-top:5px">MC ${{ numberWithCommas(item.mc) }} - {{ timeDifference(Math.round(item.launch_date / 1000)) }} - {{ numberWithCommas(item.holders) }} Holders
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-layout>
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" style="margin-right:15px;margin-top:-2px">
                                <v-icon color="pink" v-if="item.scam_count > 0" >
                                  mdi-alert-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Someone has reported a scam alert!</span>
                          </v-tooltip>
                          <v-spacer></v-spacer>
                          <v-layout column align-center :style="windowWidth < 770 ? 'margin-right:10px;margin-top:5px' : 'margin-right:30px;margin-top:5px'">
                            <v-icon v-if="item.network === 'ETH'" color="blue-grey lighten-1">
                              mdi-ethereum
                            </v-icon>
                            <v-img v-if="item.network === 'BSC'" src="/img/icons/binance-coin-logo.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                            <v-img v-if="item.network === 'MATIC'" src="/img/icons/polygon.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                            <v-img v-if="item.network === 'KCC'" src="/img/icons/kucoin.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                          </v-layout>

                        <v-spacer></v-spacer>

                         <v-layout column align-center>
                           <v-btn icon small color="#434908" @click.stop="likeThisItem(item)">
                             <v-icon>
                               {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                             </v-icon>
                           </v-btn>
                           <span style="font-size:10px"> {{ item.likes }} </span>
                         </v-layout>

                        </v-layout>
                      </v-list-item-action>

                    </v-list-item>
                    <v-divider
                      v-if="parseInt(index + 1) < getPromotedTokens.length"
                      :key="index + 'a'"
                    ></v-divider>
                  </template>
                </v-list>

                <p class="mt-4"></p>
              </v-layout>
              </v-tab-item>

              <v-tab-item
                :key="2"
               >
                 <v-layout column pr-1 pl-1 pt-2>

                   <v-layout column align-center v-if="loadingPromoted">
                     <v-progress-circular
                        indeterminate
                        color="#434908"
                      ></v-progress-circular>
                     <p class="mt-2"></p>
                   </v-layout>

                   <v-list shaped color="transparent" style="width:100%" v-if="!loadingPromoted">
                     <v-subheader>Tokens with 300 likes or more</v-subheader>
                     <template v-for="(item, index) in getMostPopularTokens">
                      <v-list-item
                        link
                        :key="index + 'x'"
                        @click="openTokenDetail(item)"
                      >
                      <v-list-item-avatar style="border-radius: 2px">
                         <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                         <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                       </v-list-item-avatar>

                        <v-list-item-content>
                          <v-layout column >
                              <span v-if="item.presale && nowList < item.launch_date_presale" :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                                <v-chip  v-if="item.presale"
                                  color="grey" label
                                  x-small
                                >
                                  Presale
                                </v-chip>
                              </span>
                              <span v-else :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                                <v-chip  v-if="item.presale && nowList < item.launch_date"
                                  color="yellow darken-1" label
                                  x-small
                                >
                                  Presale Live
                                </v-chip>
                              </span>
                           </v-layout>

                          <v-list-item-subtitle v-if="item.launch_date < nowList" class="wrap-text font-weight-bold" style="margin-top:5px">MC ${{ numberWithCommas(item.mc) }} - {{ timeDifference(Math.round(item.launch_date / 1000)) }} - {{ numberWithCommas(item.holders) }} Holders
                          </v-list-item-subtitle>

                          <v-list-item-subtitle v-else class="wrap-text font-weight-bold" style="margin-top:5px">

                            <vue-countdown v-if="item.presale && nowList < item.launch_date_presale" :time="item.launch_date_presale - nowList" v-slot="{ days, hours, minutes, seconds }">
                              <span v-if="windowWidth > 770">Presale in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                              <span v-else><v-icon small style="margin-top:-3px">mdi-clock-start</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                            </vue-countdown>
                            <vue-countdown v-else :time="item.launch_date - nowList" v-slot="{ days, hours, minutes, seconds }">
                              <span v-if="windowWidth > 770">Launching in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                              <span v-else><v-icon small style="margin-top:-3px">mdi-rocket-launch</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                            </vue-countdown>

                          </v-list-item-subtitle>

                          </v-list-item-content>

                        <v-list-item-action>
                          <v-layout>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" style="margin-right:15px;margin-top:-2px">
                                  <v-icon color="pink" v-if="item.scam_count > 0" >
                                    mdi-alert-outline
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Someone has reported a scam alert!</span>
                            </v-tooltip>
                            <v-spacer></v-spacer>
                            <v-layout column align-center :style="windowWidth < 770 ? 'margin-right:10px;margin-top:5px' : 'margin-right:30px;margin-top:5px'">
                              <v-icon v-if="item.network === 'ETH'" color="blue-grey lighten-1">
                                mdi-ethereum
                              </v-icon>
                              <v-img v-if="item.network === 'BSC'" src="/img/icons/binance-coin-logo.png" style="max-width:22px;max-height:22px;padding-right:10px">
                              </v-img>
                              <v-img v-if="item.network === 'MATIC'" src="/img/icons/polygon.png" style="max-width:22px;max-height:22px;padding-right:10px">
                              </v-img>
                              <v-img v-if="item.network === 'KCC'" src="/img/icons/kucoin.png" style="max-width:22px;max-height:22px;padding-right:10px">
                              </v-img>
                            </v-layout>

                          <v-spacer></v-spacer>

                           <v-layout column align-center>
                             <v-btn icon small color="#434908" @click.stop="likeThisItem(item)">
                               <v-icon>
                                 {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                               </v-icon>
                             </v-btn>
                             <span style="font-size:10px"> {{ item.likes }} </span>
                           </v-layout>

                          </v-layout>
                        </v-list-item-action>

                      </v-list-item>
                      <v-divider
                        v-if="parseInt(index + 1) < getMostPopularTokens.length"
                        :key="index + 'b'"
                      ></v-divider>
                    </template>
                  </v-list>

                  <v-layout column align-center v-if="loadingMore">
                    <v-progress-circular
                       indeterminate
                       color="#434908"
                     ></v-progress-circular>
                    <p class="mt-2"></p>
                  </v-layout>

                  <v-btn text color="#388E3C" style="width:250px" v-if="!this.loadingPromoted && promotedLengthOld !== promotedLengthNew" @click="loadMorePopular">
                    <v-icon left size="25">mdi-chevron-right</v-icon>Show More
                  </v-btn>

                  <p class="mt-4"></p>
                  <p class="mt-4"></p>

                </v-layout>
              </v-tab-item>

              <v-tab-item
                :key="3"
               >
               <v-layout column pr-1 pl-1 pt-2>

                 <v-layout column align-center v-if="loadingPromoted">
                   <v-progress-circular
                      indeterminate
                      color="#434908"
                    ></v-progress-circular>
                   <p class="mt-2"></p>
                 </v-layout>

                 <v-list shaped color="transparent" style="width:100%">
                   <v-subheader>Launched in the last 96 hours</v-subheader>
                   <template v-for="(item, index) in getJustLaunchedTokens">
                    <v-list-item
                      link
                      :key="index + 'y'"
                      @click="openTokenDetail(item)"
                    >
                    <v-list-item-avatar style="border-radius: 2px">
                       <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                       <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                     </v-list-item-avatar>

                      <v-list-item-content>

                        <v-layout column >
                            <span v-if="item.presale && nowList < item.launch_date_presale" :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                              <v-chip  v-if="item.presale"
                                color="grey" label
                                x-small
                              >
                                Presale
                              </v-chip>
                            </span>
                            <span v-else :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                              <v-chip  v-if="item.presale && nowList < item.launch_date"
                                color="yellow darken-1" label
                                x-small
                              >
                                Presale Live
                              </v-chip>
                            </span>
                         </v-layout>

                        <v-list-item-subtitle class="wrap-text font-weight-bold" style="margin-top:5px">MC ${{ numberWithCommas(item.mc) }} - Launched: {{ timeDifference(Math.round(item.launch_date / 1000)) }} - {{ numberWithCommas(item.holders) }} Holders</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-layout>
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" style="margin-right:15px;margin-top:-2px">
                                <v-icon color="pink" v-if="item.scam_count > 0" >
                                  mdi-alert-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Someone has reported a scam alert!</span>
                          </v-tooltip>
                          <v-spacer></v-spacer>
                          <v-chip style="margin-right:30px" v-if="item.launch_date < nowList && item.launch_date + (1000 * 60 * 360) > nowList  && windowWidth > 770"
                            color="green lighten-2"
                            :x-small="windowWidth < 770"
                          >
                            <v-icon left :x-small="windowWidth < 770">
                              mdi-rocket-launch-outline
                            </v-icon>
                            Just Launched!
                          </v-chip>

                          <v-layout column align-center :style="windowWidth < 770 ? 'margin-right:10px;margin-top:5px' : 'margin-right:30px;margin-top:5px'">
                            <v-icon v-if="item.network === 'ETH'" color="blue-grey lighten-1">
                              mdi-ethereum
                            </v-icon>
                            <v-img v-if="item.network === 'BSC'" src="/img/icons/binance-coin-logo.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                            <v-img v-if="item.network === 'MATIC'" src="/img/icons/polygon.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                            <v-img v-if="item.network === 'KCC'" src="/img/icons/kucoin.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                          </v-layout>

                        <v-spacer></v-spacer>

                         <v-layout column align-center>

                           <v-btn icon small color="#434908" @click.stop="likeThisItem(item)">
                             <v-icon>
                               {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                             </v-icon>
                           </v-btn>
                           <span style="font-size:10px"> {{ item.likes }} </span>
                         </v-layout>

                        </v-layout>
                      </v-list-item-action>

                    </v-list-item>
                    <v-divider
                      v-if="parseInt(index + 1) < getLaunchQueueTokens.length"
                      :key="index + 'canvas'"
                    ></v-divider>
                  </template>
                </v-list>

                <p class="mt-4"></p>
              </v-layout>
              </v-tab-item>

              <v-tab-item
                :key="4"
               >
               <v-layout column pt-2>

                 <v-layout column align-center v-if="loadingPromoted">
                   <v-progress-circular
                      indeterminate
                      color="#434908"
                    ></v-progress-circular>
                   <p class="mt-2"></p>
                 </v-layout>

                 <v-list shaped color="transparent" style="width:100%">
                   <v-layout :column="windowWidth < 770" :style="windowWidth < 770 ? 'margin-left:16px' : ''">
                     <v-subheader :style="windowWidth < 770 ? 'margin-left:-16px' : ''" >Promoted launch events</v-subheader>
                     <v-spacer></v-spacer>
                     <v-layout :style="windowWidth < 770 ? 'margin-top:-10px' : ''">
                       <v-spacer v-if="windowWidth > 770"></v-spacer>
                       <span style="margin-top:13px;margin-right:5px">Filter</span>
                       <v-chip-group
                          v-model="toggleNetwork"
                          active-class="primary--text"
                          column
                        >
                          <v-chip filter value="BSC">
                            <v-img src="/img/icons/binance-coin-logo.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                          <v-chip filter value="ETH">
                            <v-icon color="blue-grey lighten-1">
                              mdi-ethereum
                            </v-icon>
                          </v-chip>
                          <v-chip filter value="MATIC">
                            <v-img src="/img/icons/polygon.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                          <v-chip filter value="KCC">
                            <v-img src="/img/icons/kucoin.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                        </v-chip-group>
                      </v-layout>
                    </v-layout>
                   <template v-for="(item, index) in filterLaunchQueueTokens">
                    <v-list-item
                      link
                      :key="index + 'z'"
                      @click="openTokenDetail(item)"
                    >
                    <v-list-item-avatar style="border-radius: 2px">
                       <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                       <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                     </v-list-item-avatar>

                      <v-list-item-content>

                        <v-layout column >
                            <span v-if="item.presale && nowList < item.launch_date_presale" :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                              <v-chip  v-if="item.presale"
                                color="grey" label
                                x-small
                              >
                                Presale
                              </v-chip>
                            </span>
                            <span v-else :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                              <v-chip  v-if="item.presale && nowList < item.launch_date"
                                color="yellow darken-1" label
                                x-small
                              >
                                Presale Live
                              </v-chip>
                            </span>
                         </v-layout>

                        <v-list-item-subtitle class="wrap-text font-weight-bold" style="margin-top:5px">

                          <vue-countdown v-if="item.presale && nowList < item.launch_date_presale" :time="item.launch_date_presale - nowList" v-slot="{ days, hours, minutes, seconds }">
                            <span v-if="windowWidth > 770">Presale in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                            <span v-else><v-icon small style="margin-top:-3px">mdi-clock-start</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                          </vue-countdown>
                          <vue-countdown v-else :time="item.launch_date - nowList" v-slot="{ days, hours, minutes, seconds }">
                            <span v-if="windowWidth > 770">Launching in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                            <span v-else><v-icon small style="margin-top:-3px">mdi-rocket-launch</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                          </vue-countdown>

                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-layout>

                          <v-chip style="margin-right:30px" v-if="item.launch_date > nowList && item.launch_date - nowList <  (1000 * 60 * 31) && windowWidth > 770"
                            color="green lighten-2"
                            :x-small="windowWidth < 770"
                          >
                            <v-icon left :x-small="windowWidth < 770">
                              mdi-rocket-launch-outline
                            </v-icon>
                            Launching Soon!
                          </v-chip>

                          <v-chip style="margin-right:30px" v-if="item.launch_date < nowList && item.launch_date + (1000 * 60 * 360) > nowList  && windowWidth > 770"
                            color="green lighten-2"
                            :x-small="windowWidth < 770"
                          >
                            <v-icon left :x-small="windowWidth < 770">
                              mdi-rocket-launch-outline
                            </v-icon>
                            Just Launched!
                          </v-chip>

                          <v-spacer></v-spacer>

                          <v-layout column align-center :style="windowWidth < 770 ? 'margin-right:10px;margin-top:5px' : 'margin-right:30px;margin-top:5px'">
                            <v-icon v-if="item.network === 'ETH'" color="blue-grey lighten-1">
                              mdi-ethereum
                            </v-icon>
                            <v-img v-if="item.network === 'BSC'" src="/img/icons/binance-coin-logo.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                            <v-img v-if="item.network === 'MATIC'" src="/img/icons/polygon.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                            <v-img v-if="item.network === 'KCC'" src="/img/icons/kucoin.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                          </v-layout>

                        <v-spacer></v-spacer>

                         <v-layout column align-center>
                           <v-btn icon small color="#434908" @click.stop="likeThisItem(item)">
                             <v-icon>
                               {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                             </v-icon>
                           </v-btn>
                           <span style="font-size:10px"> {{ item.likes }} </span>
                         </v-layout>

                        </v-layout>
                      </v-list-item-action>

                    </v-list-item>
                    <v-divider
                      v-if="parseInt(index + 1) < getLaunchQueueTokens.length"
                      :key="index + 'd'"
                    ></v-divider>
                  </template>
                </v-list>

                <v-layout column align-center pt-4 v-if="getLaunchQueueTokens.length === 0">
                  <span style="padding-left:10px;font-size:16px;color:#388E3C">There are currently no promoted Launch Events.</span>
                </v-layout>

                <p class="mt-4"></p>
              </v-layout>
              </v-tab-item>

              <v-tab-item
                :key="5"
               >
               <v-layout column pt-2>
                 <v-layout column align-center v-if="loadingPromoted">
                   <v-progress-circular
                      indeterminate
                      color="#434908"
                    ></v-progress-circular>
                   <p class="mt-2"></p>
                 </v-layout>

                 <v-list shaped color="transparent" style="width:100%">
                   <v-layout :column="windowWidth < 770" :style="windowWidth < 770 ? 'margin-left:16px' : ''">
                     <v-subheader :style="windowWidth < 770 ? 'margin-left:-16px' : ''" >Tokens added in the last {{ getUser.newCount }} days</v-subheader>
                     <v-spacer></v-spacer>
                     <v-layout :style="windowWidth < 770 ? 'margin-top:-10px' : ''">
                       <v-spacer v-if="windowWidth > 770"></v-spacer>
                       <span style="margin-top:13px;margin-right:5px">Filter</span>
                       <v-chip-group
                          v-model="toggleNetwork"
                          active-class="primary--text"
                          column
                        >
                          <v-chip filter value="BSC">
                            <v-img src="/img/icons/binance-coin-logo.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                          <v-chip filter value="ETH">
                            <v-icon color="blue-grey lighten-1">
                              mdi-ethereum
                            </v-icon>
                          </v-chip>
                          <v-chip filter value="MATIC">
                            <v-img src="/img/icons/polygon.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                          <v-chip filter value="KCC">
                            <v-img src="/img/icons/kucoin.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                        </v-chip-group>
                      </v-layout>
                    </v-layout>
                   <template v-for="(item, index) in filterNewTokens">
                    <v-list-item
                      link
                      :key="index + 'z'"
                      @click="openTokenDetail(item)"
                    >
                    <v-list-item-avatar style="border-radius: 2px">
                       <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                       <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                     </v-list-item-avatar>

                      <v-list-item-content>

                        <v-layout column >
                            <span v-if="item.presale && nowList < item.launch_date_presale" :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                              <v-chip  v-if="item.presale"
                                color="grey" label
                                x-small
                              >
                                Presale
                              </v-chip>
                            </span>
                            <span v-else :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                              <v-chip  v-if="item.presale && nowList < item.launch_date"
                                color="yellow darken-1" label
                                x-small
                              >
                                Presale Live
                              </v-chip>
                            </span>
                         </v-layout>

                        <v-list-item-subtitle v-if="item.launch_date < nowList" class="wrap-text font-weight-bold" style="margin-top:5px">
                          MC ${{ numberWithCommas(item.mc) }} - Launched: {{ timeDifference(Math.round(item.launch_date / 1000)) }} - {{ numberWithCommas(item.holders) }} Holders
                        </v-list-item-subtitle>

                        <v-list-item-subtitle v-else class="wrap-text font-weight-bold" style="margin-top:5px">

                          <vue-countdown v-if="item.presale && nowList < item.launch_date_presale" :time="item.launch_date_presale - nowList" v-slot="{ days, hours, minutes, seconds }">
                            <span v-if="windowWidth > 770">Presale in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                            <span v-else><v-icon small style="margin-top:-3px">mdi-clock-start</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                          </vue-countdown>
                          <vue-countdown v-else :time="item.launch_date - nowList" v-slot="{ days, hours, minutes, seconds }">
                            <span v-if="windowWidth > 770">Launching in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                            <span v-else><v-icon small style="margin-top:-3px">mdi-rocket-launch</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                          </vue-countdown>

                        </v-list-item-subtitle>

                      </v-list-item-content>

                      <v-list-item-action>
                        <v-layout>
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" style="margin-right:15px;margin-top:-2px">
                                <v-icon color="pink" v-if="item.scam_count > 0" >
                                  mdi-alert-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Someone has reported a scam alert!</span>
                          </v-tooltip>
                          <v-spacer></v-spacer>
                          <v-chip style="margin-right:30px" v-if="item.launch_date > nowList && item.launch_date - nowList <  (1000 * 60 * 31) && windowWidth > 770"
                            color="green lighten-2"
                            :x-small="windowWidth < 770"
                          >
                            <v-icon left :x-small="windowWidth < 770">
                              mdi-rocket-launch-outline
                            </v-icon>
                            Launching Soon!
                          </v-chip>

                          <v-spacer></v-spacer>

                          <v-layout column align-center :style="windowWidth < 770 ? 'margin-right:10px;margin-top:5px' : 'margin-right:30px;margin-top:5px'">
                            <v-icon v-if="item.network === 'ETH'" color="blue-grey lighten-1">
                              mdi-ethereum
                            </v-icon>
                            <v-img v-if="item.network === 'BSC'" src="/img/icons/binance-coin-logo.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                            <v-img v-if="item.network === 'MATIC'" src="/img/icons/polygon.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                            <v-img v-if="item.network === 'KCC'" src="/img/icons/kucoin.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                          </v-layout>

                        <v-spacer></v-spacer>

                         <v-layout column align-center>
                           <v-btn icon small color="#434908" @click.stop="likeThisItem(item)">
                             <v-icon>
                               {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                             </v-icon>
                           </v-btn>
                           <span style="font-size:10px"> {{ item.likes }} </span>
                         </v-layout>

                        </v-layout>
                      </v-list-item-action>

                    </v-list-item>
                    <v-divider
                      v-if="parseInt(index + 1) < getNewTokens.length"
                      :key="index + 'd'"
                    ></v-divider>
                  </template>
                </v-list>

                <v-layout column align-center v-if="loadingMore">
                  <v-progress-circular
                     indeterminate
                     color="#434908"
                   ></v-progress-circular>
                  <p class="mt-2"></p>
                </v-layout>

                <v-btn text color="#388E3C" style="width:250px" v-if="!this.loadingPromoted && newLengthOld !== newLengthNew" @click="loadMoreNew">
                  <v-icon left size="25">mdi-chevron-right</v-icon>Show More
                </v-btn>

                <p class="mt-4"></p>
                <p class="mt-4"></p>
              </v-layout>
              </v-tab-item>

              <v-tab-item
                :key="6"
               >
               <v-layout column pr-1 pl-1 pt-2>

                 <v-layout column align-center v-if="loadingPromoted">
                   <v-progress-circular
                      indeterminate
                      color="#434908"
                    ></v-progress-circular>
                   <p class="mt-2"></p>
                 </v-layout>

                 <v-list shaped color="transparent" style="width:100%" v-if="!loadingPromoted && (mmConnected || binanceConnected || walletConnected)">
                   <v-layout :column="windowWidth < 770" :style="windowWidth < 770 ? 'margin-left:16px' : ''">
                     <v-subheader :style="windowWidth < 770 ? 'margin-left:-16px' : ''" >My Favorites</v-subheader>
                     <v-spacer></v-spacer>
                     <v-layout :style="windowWidth < 770 ? 'margin-top:-10px' : ''">
                       <v-spacer v-if="windowWidth > 770"></v-spacer>
                       <span style="margin-top:13px;margin-right:5px">Filter</span>
                       <v-chip-group
                          v-model="toggleNetwork"
                          active-class="primary--text"
                          column
                        >
                          <v-chip filter value="BSC">
                            <v-img src="/img/icons/binance-coin-logo.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                          <v-chip filter value="ETH">
                            <v-icon color="blue-grey lighten-1">
                              mdi-ethereum
                            </v-icon>
                          </v-chip>
                          <v-chip filter value="MATIC">
                            <v-img src="/img/icons/polygon.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                          <v-chip filter value="KCC">
                            <v-img src="/img/icons/kucoin.png" style="max-width:20px;max-height:20px">
                            </v-img>
                          </v-chip>
                        </v-chip-group>
                      </v-layout>
                    </v-layout>
                   <template v-for="(item, index) in filterFavoriteTokens">
                    <v-list-item
                      link
                      :key="index + 'x'"
                      @click="openTokenDetail(item)"
                    >
                    <v-list-item-avatar style="border-radius: 2px">
                       <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                       <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                     </v-list-item-avatar>

                     <v-list-item-content>

                       <v-layout column >
                           <span v-if="item.presale && nowList < item.launch_date_presale" :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                             <v-chip  v-if="item.presale"
                               color="grey" label
                               x-small
                             >
                               Presale
                             </v-chip>
                           </span>
                           <span v-else :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                             <v-chip  v-if="item.presale && nowList < item.launch_date"
                               color="yellow darken-1" label
                               x-small
                             >
                               Presale Live
                             </v-chip>
                           </span>
                        </v-layout>

                       <v-list-item-subtitle v-if="item.launch_date < nowList" class="wrap-text font-weight-bold" style="margin-top:5px">
                         MC ${{ numberWithCommas(item.mc) }} - Launched: {{ timeDifference(Math.round(item.launch_date / 1000)) }} - {{ numberWithCommas(item.holders) }} Holders
                       </v-list-item-subtitle>

                       <v-list-item-subtitle v-else class="wrap-text font-weight-bold" style="margin-top:5px">

                         <vue-countdown v-if="item.presale && nowList < item.launch_date_presale" :time="item.launch_date_presale - nowList" v-slot="{ days, hours, minutes, seconds }">
                           <span v-if="windowWidth > 770">Presale in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                           <span v-else><v-icon small style="margin-top:-3px">mdi-clock-start</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                         </vue-countdown>
                         <vue-countdown v-else :time="item.launch_date - nowList" v-slot="{ days, hours, minutes, seconds }">
                           <span v-if="windowWidth > 770">Launching in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                           <span v-else><v-icon small style="margin-top:-3px">mdi-rocket-launch</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                         </vue-countdown>

                       </v-list-item-subtitle>

                     </v-list-item-content>

                      <v-list-item-action>
                        <v-layout>
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on" style="margin-right:15px;margin-top:-2px">
                                <v-icon color="pink" v-if="item.scam_count > 0" >
                                  mdi-alert-outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Someone has reported a scam alert!</span>
                          </v-tooltip>
                          <v-spacer></v-spacer>
                          <v-layout column align-center :style="windowWidth < 770 ? 'margin-right:10px;margin-top:5px' : 'margin-right:30px;margin-top:5px'">
                            <v-icon v-if="item.network === 'ETH'" color="blue-grey lighten-1">
                              mdi-ethereum
                            </v-icon>
                            <v-img v-if="item.network === 'BSC'" src="/img/icons/binance-coin-logo.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                            <v-img v-if="item.network === 'MATIC'" src="/img/icons/polygon.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                            <v-img v-if="item.network === 'KCC'" src="/img/icons/kucoin.png" style="max-width:22px;max-height:22px;padding-right:10px">
                            </v-img>
                          </v-layout>

                        <v-spacer></v-spacer>

                         <v-layout column align-center>
                           <v-btn icon small color="#434908" @click.stop="likeThisItem(item)">
                             <v-icon>
                               {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                             </v-icon>
                           </v-btn>
                           <span style="font-size:10px"> {{ item.likes }} </span>
                         </v-layout>

                        </v-layout>
                      </v-list-item-action>

                    </v-list-item>
                    <v-divider
                      v-if="parseInt(index + 1) < getFavoriteTokens.length"
                      :key="index + 'b'"
                    ></v-divider>
                  </template>
                </v-list>

                <v-layout column align-center v-if="loadingMore">
                  <v-progress-circular
                     indeterminate
                     color="#434908"
                   ></v-progress-circular>
                  <p class="mt-2"></p>
                </v-layout>

                <v-layout column align-center pt-4 v-if="!mmConnected && !binanceConnected && !walletConnected">
                  <span style="padding-left:10px;font-size:16px;color:#388E3C">Please connect to see & add your Favorites.</span>
                </v-layout>

                <!-- <v-btn text color="#388E3C" style="width:250px" v-if="!this.loadingPromoted && promotedLengthOld !== promotedLengthNew" @click="loadMorePopular">
                  <v-icon left size="25">mdi-chevron-right</v-icon>Show More
                </v-btn> -->

                <p class="mt-4"></p>
                <p class="mt-4"></p>

              </v-layout>
             </v-tab-item>

            </v-tabs-items>

            <v-layout column align-center v-if="loadingData">
              <v-progress-circular
                 indeterminate
                 color="#434908"
               ></v-progress-circular>
              <p class="mt-2"></p>
            </v-layout>

            <v-layout pa-2 column align-center v-if="!loadingData && search !== '' && searchTrigger && getSearchResults.length > 0">
             <v-layout>
               <span style="color:#388E3C;font-weight:bold;padding-right:15px">{{ getSearchResults.length }} Search results for '{{ search.indexOf('0x') > -1 && windowWidth > 770 ? search : search.substring(0, 8) + '...' }}'</span>
               <v-icon color="red lighten-3" @click="clearSearch">mdi-close-circle-outline</v-icon>
             </v-layout>

               <v-list shaped :dark="dark" color="transparent" style="width:100%">
                 <template v-for="(item, index) in getSearchResults">
                  <v-list-item
                    link
                    :key="index + 'y'"
                    @click="openTokenDetail(item)"
                  >
                  <v-list-item-avatar style="border-radius: 2px">
                     <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                     <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                   </v-list-item-avatar>

                    <v-list-item-content>

                      <v-layout column >
                          <span v-if="item.presale && nowList < item.launch_date_presale" :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                            <v-chip  v-if="item.presale"
                              color="grey" label
                              x-small
                            >
                              Presale
                            </v-chip>
                          </span>
                          <span v-else :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text font-weight-bold"> {{ item.name }} ({{ item.symbol }})
                            <v-chip  v-if="item.presale && nowList < item.launch_date"
                              color="yellow darken-1" label
                              x-small
                            >
                              Presale Live
                            </v-chip>
                          </span>
                       </v-layout>

                      <v-list-item-subtitle v-if="item.launch_date < nowList" class="wrap-text font-weight-bold" style="margin-top:5px">MC ${{ numberWithCommas(item.mc) }} - Launched: {{ timeDifference(Math.round(item.launch_date / 1000)) }} - {{ numberWithCommas(item.holders) }} Holders
                      </v-list-item-subtitle>

                      <v-list-item-subtitle v-else class="wrap-text font-weight-bold" style="margin-top:5px">

                        <vue-countdown v-if="item.presale && nowList < item.launch_date_presale" :time="item.launch_date_presale - nowList" v-slot="{ days, hours, minutes, seconds }">
                          <span v-if="windowWidth > 770">Presale in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                          <span v-else><v-icon small style="margin-top:-3px">mdi-clock-start</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                        </vue-countdown>
                        <vue-countdown v-else :time="item.launch_date - nowList" v-slot="{ days, hours, minutes, seconds }">
                          <span v-if="windowWidth > 770">Launching in: {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                          <span v-else><v-icon small style="margin-top:-3px">mdi-rocket-launch</v-icon> {{ days }} Days {{ hours }} Hrs. {{ minutes }} Min. {{ days === 0 && hours === 0 && minutes === 0 ? seconds + ' Sec.' : '' }}</span>
                        </vue-countdown>

                      </v-list-item-subtitle>

                    </v-list-item-content>

                    <v-list-item-action>
                      <v-layout>
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" style="margin-right:15px;margin-top:-2px">
                              <v-icon color="pink" v-if="item.scam_count > 0" >
                                mdi-alert-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Someone has reported a scam alert!</span>
                        </v-tooltip>
                        <v-spacer></v-spacer>
                        <v-chip style="margin-right:30px" v-if="item.launch_date > nowList && item.launch_date - nowList <  (1000 * 60 * 31) && windowWidth > 770"
                          color="green lighten-2"
                          :x-small="windowWidth < 770"
                        >
                          <v-icon left :x-small="windowWidth < 770">
                            mdi-rocket-launch-outline
                          </v-icon>
                          Launching Soon!
                        </v-chip>

                        <v-spacer></v-spacer>

                        <v-layout column align-center :style="windowWidth < 770 ? 'margin-right:10px;margin-top:5px' : 'margin-right:30px;margin-top:5px'">
                          <v-icon v-if="item.network === 'ETH'" color="blue-grey lighten-1">
                            mdi-ethereum
                          </v-icon>
                          <v-img v-if="item.network === 'BSC'" src="/img/icons/binance-coin-logo.png" style="max-width:22px;max-height:22px;padding-right:10px">
                          </v-img>
                          <v-img v-if="item.network === 'MATIC'" src="/img/icons/polygon.png" style="max-width:22px;max-height:22px;padding-right:10px">
                          </v-img>
                          <v-img v-if="item.network === 'KCC'" src="/img/icons/kucoin.png" style="max-width:22px;max-height:22px;padding-right:10px">
                          </v-img>
                        </v-layout>

                      <v-spacer></v-spacer>

                       <v-layout column align-center>
                         <v-btn icon small color="#434908" @click.stop="likeThisItem(item)">
                           <v-icon>
                             {{ lookupLiked(item) ? 'mdi-thumb-up' : 'mdi-thumb-up-outline' }}
                           </v-icon>
                         </v-btn>
                         <span style="font-size:10px"> {{ item.likes }} </span>
                       </v-layout>

                      </v-layout>
                    </v-list-item-action>

                  </v-list-item>
                  <v-divider
                    v-if="parseInt(index + 1) < getSearchResults.length"
                    :key="index + 'canvas'"
                  ></v-divider>
                </template>
              </v-list>

          </v-layout>

          <v-layout pa-4 column align-center v-if="!loadingData && search !== '' && searchTrigger && getSearchResults.length === 0">
             <span :style="dark ? 'color:#FFF' : 'color:#454545'">No results found for '{{ search.indexOf('0x') > -1 && windowWidth > 770 ? search : search.substring(0, 8) + '...' }}'</span>
             <p class="mt-0"></p>
             <v-btn text color="#388E3C" @click="clearSearch">Back to token lists</v-btn>
          </v-layout>

         </v-layout>

         <!-- ############################################## BOTTOM NAVIGATION ##############################################  -->

         <v-layout column align-center pt-2>
           <!-- SEARCH FIELD -->

           <v-bottom-navigation
             v-if="showBottomNav"
             v-model="tab"
             :input-value="showBottomNav"
             color="#388E3C"
             background-color="lime lighten-5"
             fixed
           >

           <v-btn style="padding-right:0px;margin-left:35px" @click="tab1Clicked">
             <span>Promoted</span>
             <v-icon>mdi-bullhorn</v-icon>
           </v-btn>

           <v-btn style="padding-right:0px;padding-left:0px" @click="tab2Clicked">
             <span>Popular</span>
             <v-icon>mdi-trending-up</v-icon>
           </v-btn>

           <v-btn style="padding-right:0px;padding-left:0px" @click="tab3Clicked">
             <span>Launched</span>
             <v-icon>mdi-rocket-launch</v-icon>
           </v-btn>

           <v-btn style="padding-left:0px;padding-right:-10px" @click="tab4Clicked">
             <span>Queue</span>
             <v-icon>mdi-format-list-bulleted-triangle</v-icon>
           </v-btn>

           <v-btn  style="margin-right:20px;padding-left:0px" @click="tab5Clicked">
             <span>New</span>
             <v-icon>mdi-expand-all</v-icon>
           </v-btn>
           </v-bottom-navigation>

         </v-layout>
      </v-layout>
    </v-slide-y-transition>

    <!-- ############################################ DIALOGS ################################################# -->

     <v-dialog v-model="tokenDetailDialog" fullscreen persistent hide-overlay transition="dialog-bottom-transition">
       <v-card :dark="dark">
         <v-toolbar flat :color="dark ? 'grey darken-4' : 'grey lighten-3'">
           <v-btn icon @click="closeTokenDetail">
             <v-icon>mdi-arrow-left</v-icon>
           </v-btn>
           <v-spacer></v-spacer>
           <v-img v-if="windowWidth > 770" :src="logoSrcHeader" max-width="280" max-height="41"></v-img>
           <v-img v-if="windowWidth < 770" :src="logoSrcHeader" max-width="200" max-height="30"></v-img>
           <v-spacer></v-spacer>
         </v-toolbar>

         <TokenDetail ref="tokenDetailComp" :windowWidth="windowWidth" :windowHeight="windowHeight" :dark="dark"
                        :tokenItem="tokenItem" :drawer="drawer" v-on:connectWalletClicked="connectWallet">
         </TokenDetail>

       </v-card>

     </v-dialog>

     <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
       <v-icon style="margin-left:10px" color="green">mdi-check-circle-outline</v-icon>
       <span >{{ snackbarText }}</span>
             <v-btn text color="#388E3C" @click.native="snackbar = false">Close</v-btn>
     </v-snackbar>

     <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbarWarning">
       <v-icon color="amber">mdi-alert-outline</v-icon>
       <span style="padding-left:10px">{{ snackbarText }}</span>
       <v-spacer></v-spacer>
     </v-snackbar>

  </div>
</template>

<script>
import store from '@/store/index'
// import firebase from 'firebase/app'
import { db } from '@/main'
import TokenDetail from './comp/TokenDetail'
import VueCountdown from '@chenfengyuan/vue-countdown'
export default {
  name: 'tokens',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean
  },
  data () {
    return {
      lang: this.$store.state.lang,
      loading: false,
      loadingData: false,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      nowList: new Date().getTime(),
      loadingPromoted: false,
      loadingMore: false,
      showBottomNav: false,
      searchDialog: false,
      tokenDetailDialog: false,
      tab: null,
      slugLoaded: false,
      tokenItem: {},
      trigger: false,
      search: '',
      firstSearch: false,
      currentRoute: '/tokens',
      searchTrigger: false,
      logoSrcHeader: '',
      limitPromoted: 100, // for now
      limitMostPopular: 50,
      limitJustLaunched: 50,
      limitNew: new Date().getTime() - (1000 * 60 * 60 * 24 * 10),
      promotedLengthOld: 0,
      promotedLengthNew: 1,
      newLengthOld:  0,
      newLengthNew: 1,
      toggleNetwork: undefined
    }
  },
  components: {
    TokenDetail,
    VueCountdown
  },
  watch: {
    windowWidth () {
      if (this.windowWidth < 770) {
        this.showBottomNav = true
      } else {
        this.showBottomNav = false
      }
    },
    mmConnected () {
      if (this.mmConnected && this.tab === 5 && this.getFavoriteTokens.length === 0) {
        setTimeout(() => {
          this.loadFavorites()
        }, 2000)
      }
    },
    binanceConnected () {
      if (this.binanceConnected && this.tab === 5 && this.getFavoriteTokens.length === 0) {
        setTimeout(() => {
          this.loadFavorites()
        }, 2000)
      }
    },
    walletConnected () {
      if (this.walletConnected && this.tab === 5 && this.getFavoriteTokens.length === 0) {
        setTimeout(() => {
          this.loadFavorites()
        }, 2000)
      }
    }
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    getPlatform () {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    walletConnected () {
      return this.$store.state.user.walletConnected
    },
    getUser () {
      return this.$store.state.user
    },
    getLikes () {
      return this.$store.state.user.likes
    },
    getPromotedTokens () {
      return this.$store.state.fb.promotedTokens
    },
    getMostPopularTokens () {
      return this.$store.state.fb.popularTokens
    },
    getLaunchQueueTokens () {
      return this.$store.state.fb.launchQueue
    },
    getJustLaunchedTokens () {
      return this.$store.state.fb.justLaunchedTokens
    },
    getNewTokens () {
      return this.$store.state.fb.newTokens
    },
    getFavoriteTokens () {
      return this.$store.state.fb.favoriteTokens
    },
    getSearchResults () {
      return this.$store.state.fb.searchResults
    },
    getLatestBNB () {
      return this.$store.state.latestBNB
    },
    getLatestETH () {
      return this.$store.state.latestETH
    },
    getLatestMATIC () {
      return this.$store.state.latestMATIC
    },
    filterPromotedTokens () {
      /*var n = 7
      var array = this.getPromotedTokens
      var shuffled = array.sort(function(){return .5 - Math.random()})

      return shuffled.slice(0,n)
      */
      var array = this.getPromotedTokens
      array.sort((a, b) => {
        return b.likes - a.likes
      })
      return array.filter(item => {
        if (this.toggleNetwork === undefined) {
          return true
        } else {
          return item.network === this.toggleNetwork
        }
      })
    },
    filterLaunchQueueTokens () {
      /* var n = 7
      var array = this.getLaunchQueueTokens
      var shuffled = array.sort(function(){return .5 - Math.random()}) */
      var array = this.getLaunchQueueTokens
      array.sort((a, b) => {
        return a.launch_date - b.launch_date
      })
      return array.filter(item => {
        if (this.toggleNetwork === undefined) {
          return true
        } else {
          return item.network === this.toggleNetwork
        }
      })
    },
    filterNewTokens () {
      return this.getNewTokens.filter(item => {
        if (this.toggleNetwork === undefined) {
          return true
        } else {
          return item.network === this.toggleNetwork
        }
      })
    },
    filterFavoriteTokens () {
      return this.getFavoriteTokens.filter(item => {
        if (this.toggleNetwork === undefined) {
          return true
        } else {
          return item.network === this.toggleNetwork
        }
      })
    }
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    // window.removeEventListener('resize', this.handleResize)
  },
  mounted () {
  },
  created () {
    store.commit('ShowNav')
    // store.commit('ShowNavHome')
    // console.log(this.getPlatform)
    this.logoSrcHeader = './img/logo/tokenmonster_logo_01.png'
    // this.loadPromotedTokens()
    this.init()
  },
  methods: {
    init () {
      if (this.windowWidth < 770) {
        this.showBottomNav = true
      } else {
        this.showBottomNav = false
      }
      if (this.$route.params.slug === undefined || this.$route.params.slug === null) {
        // console.log('re route when no slug and just /tokens')
        this.tab = 0
        this.loadPromotedTokens()
        this.$router.replace('/tokens/promoted')
      } else if (this.$route.params.slug === 'promoted') {
        this.tab = 0
        this.loadPromotedTokens()
        this.$router.replace('/tokens/promoted')
      } else if (this.$route.params.slug === 'popular') {
        this.tab = 1
        this.loadMostPopularTokens()
        this.$router.replace('/tokens/popular')
      } else if (this.$route.params.slug === 'launched') {
        this.tab = 2
        this.loadJustLaunchedTokens()
        this.$router.replace('/tokens/launched')
      } else if (this.$route.params.slug === 'queue') {
        this.tab = 3
        this.loadPromotedTokens()
        this.$router.replace('/tokens/queue')
      } else if (this.$route.params.slug === 'new') {
        this.tab = 4
        this.loadNewTokens()
        this.$router.replace('/tokens/new')
      } else if (this.$route.params.slug === 'favorites') {
        this.tab = 5
        if (this.getUser.favorites.length > 0) {
          this.loadFavorites()
        }
        this.$router.replace('/tokens/favorites')
      } else if (this.$route.params.slug === 'search') {
        console.log('######## search ###########')
        this.firstSearch = true
        this.$router.replace('/tokens/search')
      } else {
        this.loading = false
        this.slugLoaded = true
        this.lookupTokenBySlug()
      }
      this.resultInterval = setInterval(() => {
        this.nowList = new Date().getTime()
      }, 60000)
    },
    loadPromotedTokens () {
      // Check if ad reload is needed.
      // To limit the database calls we refresh ads once per hour
      if (this.getPromotedTokens.length > 0) {
        let checkTime = Math.round(new Date().getTime() / 1000)
        if (this.getPromotedTokens[0].checkTime > Math.round(checkTime)) {
          // console.log('No reload needed')
          this.loading = false
          this.loadingPromoted = false
          this.pollPromotedTokens()
          return
        }
      }
      // console.log('Reload needed')
      this.loadingPromoted = true
      let dispatchObj = {
        limit: this.limitPromoted
      }
      this.$store.dispatch('getPromotedTokens', dispatchObj)
        .then(() => {
          var interval = setInterval(() => {
            if (this.getPromotedTokens.length > 0) {
              this.loadingPromoted = false
              this.pollPromotedTokens()
              clearInterval(interval)
            }
          }, 2000)
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.loadingPromoted = false
        })
    },
    async pollPromotedTokens () {
      // console.log('pollPromotedTokens called')
      // console.log(this.getPromotedTokens.length)
      if (this.getPromotedTokens.length === 0) {
        setTimeout(() => {
          this.pollPromotedTokens()
        }, 1000)
        return
      }
      for (var i in this.getPromotedTokens) {
        let dispatchObj = {
          contract_address: this.getPromotedTokens[i].contract_address,
          network: this.getPromotedTokens[i].network,
          total_supply: this.getPromotedTokens[i].total_supply,
          latestBNB: this.getLatestBNB,
          latestETH: this.getLatestETH,
          latestMATIC: this.getLatestMATIC,
          list: 'promotedTokens'
        }
        if (this.getPromotedTokens[i].contract_address !== '') {
          this.$store.dispatch('getTokenPriceQuote', dispatchObj)
            .then(() => {

            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.loadingPromoted = false
            })
        }
        await this.timer(1000)
      }
    },
    loadMostPopularTokens () {
      // Check if ad reload is needed.
      // To limit the database calls we refresh ads once per hour
      if (this.getMostPopularTokens.length > 0) {
        let checkTime = Math.round(new Date().getTime() / 1000)
        if (this.getMostPopularTokens[0].checkTime > Math.round(checkTime)) {
          // console.log('No reload needed')
          this.loading = false
          this.loadingPromoted = false
          setTimeout(() => {
            // console.log('this.pollPromotedTokens() when this.getPromotedTokens.length > 0')
            this.pollPopularTokens()
          }, 2000)
          return
        }
      }
      // console.log('Reload needed')
      this.loadingPromoted = true
      let dispatchObj = {
        limit: this.limitMostPopular
      }
      this.$store.dispatch('getMostPopularTokens', dispatchObj)
        .then(() => {
          var interval = setInterval(() => {
            if (this.getMostPopularTokens.length > 0) {
              this.loadingPromoted = false
              this.promotedLengthNew = this.getMostPopularTokens.length
              this.pollPopularTokens()
              clearInterval(interval)
            }
          }, 2000)
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.loadingPromoted = false
        })
    },
    loadMorePopular () {
      this.loadingMore = true
      this.promotedLengthOld = this.getMostPopularTokens.length
      let dispatchObj = {
        limit: this.limitMostPopular += 50
      }
      this.$store.dispatch('getMostPopularTokens', dispatchObj)
        .then(() => {
          setTimeout(() => {
            // console.log(this.getMostPopularTokens)
            this.loadingMore = false
            this.promotedLengthNew = this.getMostPopularTokens.length
            this.pollPopularTokens()
          }, 2000)
        })
        .catch(error => {
          console.log(error)
          this.loadingMore = false
        })
    },
    async pollPopularTokens () {
      // console.log('pollPromotedTokens called')
      // console.log(this.getPromotedTokens.length)
      if (this.getMostPopularTokens.length === 0) {
        setTimeout(() => {
          this.pollPopularTokens()
        }, 1000)
        return
      }
      for (var i in this.getMostPopularTokens) {
        let dispatchObj = {
          contract_address: this.getMostPopularTokens[i].contract_address,
          network: this.getMostPopularTokens[i].network,
          total_supply: this.getMostPopularTokens[i].total_supply,
          latestBNB: this.getLatestBNB,
          latestETH: this.getLatestETH,
          latestMATIC: this.getLatestMATIC,
          list: 'popularTokens'
        }
        if (this.getMostPopularTokens[i].contract_address !== '') {
          this.$store.dispatch('getTokenPriceQuote', dispatchObj)
            .then(() => {

            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.loadingPromoted = false
            })
        }
        await this.timer(1000)
      }
    },
    loadJustLaunchedTokens () {
      // Check if ad reload is needed.
      // To limit the database calls we refresh ads once per hour
      if (this.getJustLaunchedTokens.length > 0) {
        let checkTime = Math.round(new Date().getTime() / 1000)
        if (this.getJustLaunchedTokens[0].checkTime > Math.round(checkTime)) {
          // console.log('No reload needed')
          this.loading = false
          this.loadingPromoted = false
          this.pollJustLaunchedTokens()
          return
        }
      }
      // console.log('Reload needed')
      this.loadingPromoted = true
      let dispatchObj = {
        limit: this.limitJustLaunched
      }
      this.$store.dispatch('getJustLaunchedTokens', dispatchObj)
        .then(() => {
          setTimeout(() => {
            // console.log(this.getJustLaunchedTokens)
            this.loadingPromoted = false
            this.pollJustLaunchedTokens()
          }, 2000)
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.loadingPromoted = false
        })
    },
    async pollJustLaunchedTokens () {
      // console.log('pollPromotedTokens called')
      // console.log(this.getPromotedTokens.length)
      if (this.getJustLaunchedTokens.length === 0) {
        setTimeout(() => {
          this.pollJustLaunchedTokens()
        }, 1000)
        return
      }
      for (var i in this.getJustLaunchedTokens) {
        let dispatchObj = {
          contract_address: this.getJustLaunchedTokens[i].contract_address,
          network: this.getJustLaunchedTokens[i].network,
          total_supply: this.getJustLaunchedTokens[i].total_supply,
          latestBNB: this.getLatestBNB,
          latestETH: this.getLatestETH,
          latestMATIC: this.getLatestMATIC,
          list: 'justLaunchedTokens'
        }
        if (this.getJustLaunchedTokens[i].contract_address !== '') {
          this.$store.dispatch('getTokenPriceQuote', dispatchObj)
            .then(() => {

            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.loadingPromoted = false
            })
        }
        await this.timer(1000)
      }
    },
    loadNewTokens () {
      // Check if ad reload is needed.
      // To limit the database calls we refresh ads once per hour
      if (this.getNewTokens.length > 0) {
        let checkTime = Math.round(new Date().getTime() / 1000)
        if (this.getNewTokens[0].checkTime > Math.round(checkTime)) {
          // console.log('No reload needed')
          this.loading = false
          this.loadingPromoted = false
          // this.newLengthOld = this.getNewTokens.length
          this.pollNewTokens()
          return
        }
      }
      // console.log('Reload needed')
      this.loadingPromoted = true
      let dispatchObj = {
        limit: this.limitNew
      }
      this.$store.dispatch('getNewTokens', dispatchObj)
        .then(() => {
          var interval = setInterval(() => {
            if (this.getNewTokens.length > 0) {
              this.loadingPromoted = false
              this.newLengthNew = this.getNewTokens.length
              this.pollNewTokens()
              clearInterval(interval)
            }
          }, 2000)
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.loadingPromoted = false
        })
    },
    loadMoreNew () {
      this.loadingMore = true
      this.newLengthOld = this.getNewTokens.length
      let dispatchObj = {
        limit: this.limitNew -= 1000 * 60 * 60 * 24 * 10 // 5 days more
      }
      var count = this.getUser.newCount += 10
      store.commit('SetNewCount', count)
      this.$store.dispatch('getNewTokens', dispatchObj)
        .then(() => {
          var interval = setInterval(() => {
            if (this.getNewTokens.length > 0) {
              this.loadingMore = false
              this.newLengthNew = this.getNewTokens.length
              console.log('############### this.newLengthNew ###############')
              console.log(this.newLengthNew)
              console.log(this.newLengthOld)
              this.pollNewTokens()
              clearInterval(interval)
            }
          }, 2000)
        })
        .catch(error => {
          console.log(error)
          this.loadingMore = false
        })
    },
    async pollNewTokens () {
      // console.log('pollPromotedTokens called')
      // console.log(this.getPromotedTokens.length)
      if (this.getNewTokens.length === 0) {
        setTimeout(() => {
          this.pollNewTokens()
        }, 1000)
        return
      }
      for (var i in this.getNewTokens) {
        let dispatchObj = {
          contract_address: this.getNewTokens[i].contract_address,
          network: this.getNewTokens[i].network,
          total_supply: this.getNewTokens[i].total_supply,
          latestBNB: this.getLatestBNB,
          latestETH: this.getLatestETH,
          latestMATIC: this.getLatestMATIC,
          list: 'newTokens'
        }
        if (this.getNewTokens[i].contract_address !== '') {
          this.$store.dispatch('getTokenPriceQuote', dispatchObj)
            .then(() => {

            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.loadingPromoted = false
            })
        }
        await this.timer(1000)
      }
    },
    loadFavorites () {
      // Check if ad reload is needed.
      // To limit the database calls we refresh ads once per hour
      /* if (this.getFavoriteTokens.length > 0) {
        let checkTime = Math.round(new Date().getTime() / 1000)
        if (this.getFavoriteTokens[0].checkTime > Math.round(checkTime)) {
          console.log('No reloasd needed')
          this.loading = false
          this.loadingPromoted = false
          this.pollNewTokens()
          return
        }
      } */
      // console.log('Reload needed')
      this.loadingPromoted = true
      let dispatchObj = {
        favorites: this.getUser.favorites
      }
      this.$store.dispatch('getFavoriteTokens', dispatchObj)
        .then(() => {
          setTimeout(() => {
            this.loadingPromoted = false
            this.pollFavoriteTokens()
          }, 2000)
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.loadingPromoted = false
        })
    },
    async pollFavoriteTokens () {
      // console.log('pollPromotedTokens called')
      // console.log(this.getPromotedTokens.length)
      if (this.getFavoriteTokens.length === 0) {
        setTimeout(() => {
          this.pollFavoriteTokens()
        }, 1000)
        return
      }
      for (var i in this.getFavoriteTokens) {
        let dispatchObj = {
          contract_address: this.getFavoriteTokens[i].contract_address,
          network: this.getFavoriteTokens[i].network,
          total_supply: this.getFavoriteTokens[i].total_supply,
          latestBNB: this.getLatestBNB,
          latestETH: this.getLatestETH,
          latestMATIC: this.getLatestMATIC,
          list: 'favoriteTokens'
        }
        if (this.getFavoriteTokens[i].contract_address !== '') {
          this.$store.dispatch('getTokenPriceQuote', dispatchObj)
            .then(() => {

            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.loadingPromoted = false
            })
        }
        await this.timer(1000)
      }
    },
    lookupTokenBySlug () {
      // console.log('lookupNewsBySlug called')
      // console.log(this.$route.params.slug)
      this.loadingData = true
      var slug = this.$route.params.slug
      let query
      if (slug.indexOf('0x') > -1) {
        query = db.collection('tokens').where('contract_address', '==', slug).limit(1)
      } else {
        query = db.collection('tokens').where('slug', '==', slug).limit(1)
      }
      // console.log(query)
      query.get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.')
          this.loadingData = false
          this.loading = false
          return
        }
        var obj
        snapshot.forEach(doc => {
          // console.log(doc.id, '=>', doc.data())
          obj = doc.data()
          obj.id = doc.id
          obj.readmore = true
          obj.price = 0
          obj.price_text = '0'
          obj.mc = 0
          obj.holders = ''
          // this.news.push(obj)
          // this.metaTitle = doc.data().title
        })
        // console.log(obj)
        this.openTokenDetailSlug(obj)
        this.loadingData = false
        this.loading = false
        // this.molaVideoDialog = true
      })
      .catch(err => {
        console.log('Error getting documents', err)
      })
    },
    searchDialogClose () {
      // console.log('close dialog called')
      this.searchDialog = false
    },
    searchClicked (e) {
      // console.log('search')
      if (e === 'click' && this.search !== '') {
        this.firstSearch = false
        this.searchAll()
        return
      }
      if (e.keyCode === 13 && this.search !== '') {
        // alert('Enter was pressed')
        this.firstSearch = false
        this.searchAll()
        return
      }
      if (!this.firstSearch) {
        this.searchTrigger = false
      }
    },
    searchAll () {
      this.loadingData = true
      this.searchTrigger = true
      let dispatchObj = {
        search: this.search.toLowerCase()
      }
      this.$store.dispatch('getSearchResults', dispatchObj)
        .then(() => {
          setTimeout(() => {
            this.loading = false
            this.loadingData = false
            this.loadingPromoted = false
            this.pollSearchResult()
          }, 2000)
        })
        .catch(error => {
          console.log(error)
          this.loading = false
          this.loadingData = false
          this.loadingPromoted = false
        })
    },
    async pollSearchResult () {
      // console.log('pollPromotedTokens called')
      // console.log(this.getPromotedTokens.length)
      if (this.getSearchResults.length === 0) {
        setTimeout(() => {
          this.pollSearchResult()
        }, 1000)
        return
      }
      for (var i in this.getSearchResults) {
        let dispatchObj = {
          contract_address: this.getSearchResults[i].contract_address,
          network: this.getSearchResults[i].network,
          total_supply: this.getSearchResults[i].total_supply,
          latestBNB: this.getLatestBNB,
          latestETH: this.getLatestETH,
          latestMATIC: this.getLatestMATIC,
          list: 'searchResults'
        }
        if (this.getSearchResults[i].contract_address !== '') {
          this.$store.dispatch('getTokenPriceQuote', dispatchObj)
            .then(() => {
              //
            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.loadingPromoted = false
            })
        }
        await this.timer(1000)
      }
    },
    clearSearch () {
      this.search = ''
      this.searchTrigger = false
    },
    connectWallet() {
      this.$emit('connectWalletClicked')
    },
    openTokenDetail (item) {
      this.tokenItem = item
      this.tokenDetailDialog = true
      if (item.contract_address !== '') {
        this.$router.replace('/tokens/' + item.contract_address)
      } else {
        this.$router.replace('/tokens/' + item.slug)
      }
      setTimeout(() => {
        this.$refs.tokenDetailComp.checkNewTokenOpened()
      }, 1000)
      this.countViews(item)
    },
    openTokenDetailSlug (item) {
      this.tokenItem = item
      this.tokenDetailDialog = true
      setTimeout(() => {
        this.$refs.tokenDetailComp.checkNewTokenOpened()
      }, 1000)
      this.countViews(item)
    },
    countViews (item) {
      let dispatchObj = {
        views: item.views += 1
      }
      db.collection('tokens').doc(item.id).update(dispatchObj)
      .then(() => {
          // console.log('Item views in token bucket updated')
          // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
          // this.snackbar = true
        })
      .catch(error => {
          console.log(error)
        })
    },
    closeTokenDetail () {
      this.tokenDetailDialog = false
      this.$router.replace(this.currentRoute)
      if (this.getPromotedTokens.length === 0) {
        setTimeout(() => {
          this.init()
        }, 1000)
      }
    },
    likeThisItem (item) {
      if (this.getLikes.indexOf(item.id) > -1) {
        this.snackbarText = 'You have already liked this item'
        this.snackbarWarning = true
        return
      }
      store.commit('SetLikes', item.id)
      let dispatchObj = {
        likes: item.likes += 1
      }
      db.collection('tokens').doc(item.id).update(dispatchObj)
      .then(() => {
          // console.log('Item likes in news bucket updated')
          this.snackbarText = 'Your like has been recorded'
          this.snackbar = true
        })
      .catch(error => {
          console.log(error)
        })
    },
    lookupLiked (item) {
      // console.log(item)
      return this.getLikes.indexOf(item.id) > -1
    },
    tab1Clicked () {
      this.tab = 0
      this.currentRoute = '/tokens/promoted'
      this.loadPromotedTokens()
      this.$router.replace('/tokens/promoted')
    },
    tab2Clicked () {
      this.tab = 1
      this.loadMostPopularTokens()
      this.currentRoute = '/tokens/popular'
      this.$router.replace('/tokens/popular')
    },
    tab3Clicked () {
      this.tab = 2
      this.currentRoute = '/tokens/launched'
      this.loadJustLaunchedTokens() //  Auto refresh >> subscribe to alert
      this.$router.replace('/tokens/launched')
    },
    tab4Clicked () {
      this.tab = 3
      this.currentRoute = '/tokens/queue'
      this.loadPromotedTokens()
      this.$router.replace('/tokens/queue')
    },
    tab5Clicked () {
      this.tab = 4
      this.currentRoute = '/tokens/new'
      this.loadNewTokens()
      this.$router.replace('/tokens/new')
    },
    tab6Clicked () {
      this.tab = 5
      this.currentRoute = '/tokens/favorites'
      if (this.getUser.favorites.length > 0) {
        this.loadFavorites()
      }
      this.$router.replace('/tokens/favorites')
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    timer(ms) {
      return new Promise(res => setTimeout(res, ms));
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      // console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
  .wrapperDark{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .v-tab .v-tab {
    color: #388E3C;
  }
  .v-tab--active .v-icon {
    color: #388E3C;
  }
  .wrapperLight{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrap-text {
    word-break: normal;
  }
</style>
