green<template>
  <div id="managetokens" :style="dark ? 'background:#272727;height: 100%;' : 'background:#FFF;height: 100%;'" >
    <v-layout column pl-1 pr-1 :style="windowWidth < 770 ? '' : 'margin-left:15%;margin-right:15%'">
    <v-slide-y-transition mode="out-in">
       <!-- ####################### SKELETON LOADERS ####################### -->
       <v-layout column v-if="loading">
         <p class="mt-0"></p>

           <v-skeleton-loader
              class="mx-auto"
              width="100%"
              height="60"
              type="image"
              :dark="dark"
            ></v-skeleton-loader>

            <p class="mt-0"></p>

            <v-skeleton-loader
              class="mx-auto"
              width="100%"
              height="150"
              type="image"
              :dark="dark"
            ></v-skeleton-loader>

            <p class="mt-0"></p>

            <template v-for="i in 4">
              <v-skeleton-loader
                :key="i"
                class="mx-auto"
                width="100%"
                height="60"
                type="image"
                :dark="dark"
              ></v-skeleton-loader>
              <p :key="i + 'a'" class="mt-0"></p>
            </template>

       </v-layout>

       <!-- ############################################## COMPONENTS ##############################################  -->

        <v-layout column  v-else pt-2>
           <!-- v-show="!loadingData" -->
           <p class="mt-2"></p>
           <p v-if="scrollY > 0" class="mt-0"></p>
           <p v-if="scrollY > 0" class="mt-4"></p>

        <v-layout column align-center v-if="loadingData">
          <p class="mt-2"></p>
          <v-progress-circular
             indeterminate
             color="#388E3C"
           ></v-progress-circular>
        </v-layout>

        <v-layout column v-else-if="(myTokens.length === 0 && !loadingData) || updateTokenItem">
          <v-sheet
            class="mx-auto"
            width="100%"
            color="transparent"
            :dark="dark"
          >
            <v-layout column align-center pa-2 v-show="view === 1">
              <v-img :src="logoSrcIcon" width="216" height="200"></v-img>
              <p class="title">You have not added any tokens yet....</p>
              <v-col cols="12" sm="8" md="6">
                <p class="subheading text-center" >Manage Tokens is the place where you can update and organise your Tokens.<br><br>
                  When a Token is published or promoted, other Token Monster users can then search for and find these Tokens.</p>
              </v-col>
              <v-btn to="/promote" color="#388E3C" outlined>Add your Token now</v-btn>
            </v-layout>

            <v-layout column align-center v-show="view === 2">

              <p class="mt-0"></p>

              <v-card
                :color="dark ? '#454545' : '#FFF'"
                :dark="dark"
                :min-width="windowWidth < 770 ? '' : 800"
                :width="windowWidth < 770 ? '95%' : '100%'"
              >

              <v-toolbar flat :color="dark ? '#388E3C' : 'green lighten-5'">
                <v-btn icon @click="cancelUpdate">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <p style="font-size:22px;margin-top:10px" class="text-center font-weight-bold" >Update Token Details</p>
                <v-spacer></v-spacer>
              </v-toolbar>

              <v-layout pt-1>
                <v-spacer></v-spacer>
                <v-btn-toggle
                   v-model="toggleView"
                   borderless
                   color="#388E3C"
                 >
                   <v-btn value="details" >
                     <span v-if="windowWidth > 770" style="text-transform: none !important">Token Details</span>
                      <span v-else style="text-transform: none !important">Token</span>
                   </v-btn>

                   <v-btn value="promote">
                     <span v-if="windowWidth > 770" style="text-transform: none !important">Promotion Details</span>
                     <span v-else style="text-transform: none !important">Promotion</span>
                   </v-btn>

                   <v-btn value="vetting">
                     <span v-if="windowWidth > 770" style="text-transform: none !important">Token Vetting</span>
                     <span v-else style="text-transform: none !important">Vetting</span>
                   </v-btn>

                 </v-btn-toggle>
                 <v-spacer></v-spacer>
              </v-layout>

                <v-col cols="12" sm="12" md="12" style="padding-top:0px" v-if="toggleView === 'details'">
                  <v-form v-model="valid" ref="form" dense >

                    <v-radio-group :disabled="!tokenAddressFirst && tokenAddress !== ''"
                      v-model="tokenNetwork"
                      row
                    >
                      <v-radio
                        label="Binance Smart Chain (BSC)"
                        value="BSC"
                      ></v-radio>
                      <v-radio
                        label="Ethereum"
                        value="ETH"
                      ></v-radio>
                      <v-radio
                        label="KuCoin (KCC)"
                        value="KCC"
                      ></v-radio>
                      <v-radio
                        label="Polygon (MATIC)"
                        value="MATIC"
                      ></v-radio>
                    </v-radio-group>

                    <v-text-field
                      v-model="tokenAddress"
                      :label="tokenNetwork === 'BSC' ? 'BSC Contract Address' : tokenNetwork === 'ETH' ? 'ETH Contract Address' : tokenNetwork === 'MATIC' ? 'Polygon (MATIC) Contract Address' : tokenNetwork === 'KCC' ? 'KuCoin (KCC) Contract Address' : ''"
                      :placeholder="tokenNetwork === 'BSC' ? 'Enter the BSC Contract Address' : tokenNetwork === 'ETH' ? 'Enter the ETH Contract Address' : tokenNetwork === 'MATIC' ? 'Enter the Polygon (MATIC) Contract Address': tokenNetwork === 'KCC' ? 'Enter the KuCoin (KCC) Contract Address': ''"
                      hint="You can provide this later if your contract is not yet deployed"
                      persistent-hint
                      maxlength="50"
                      outlined
                      :disabled="!tokenAddressFirst && tokenAddress !== '' && previewItem.total_supply > 0"
                      @change="lookupTotalSupply"
                    ></v-text-field>

                    <v-layout pb-4 :column="windowWidth < 770">

                      <v-chip v-if="!contractValid && !checkExists" :style="windowWidth < 770 ? 'max-width:290px' : ''"
                        class="ma-2"
                        label
                      >
                        <v-icon left>
                          mdi-help-rhombus-outline
                        </v-icon>
                        Unknown Contract Address
                      </v-chip>

                      <v-chip v-if="showTotalSupply" :style="windowWidth < 770 ? 'max-width:290px' : ''"
                        class="ma-2"
                        label
                      >
                        <v-icon left>
                          mdi-grain
                        </v-icon>
                        Total Supply: {{ numberWithCommas(totalSupply) }} {{ windowWidth > 770 ? tokenSymbol  : '' }}
                      </v-chip>

                      <v-spacer></v-spacer>

                      <v-chip v-if="showTotalBurned" :style="windowWidth < 770 ? 'max-width:290px' : ''"
                        class="ma-2"
                        label
                      >
                        <v-icon left>
                          mdi-fire
                        </v-icon>
                        Total Burned: {{ totalBurned }}%
                      </v-chip>

                      <v-spacer></v-spacer>

                      <v-chip v-if="showTotalHolders"
                        class="ma-2"
                        label
                      >
                        <v-icon left>
                          mdi-account-group
                        </v-icon>
                        Total Holders: {{ numberWithCommas(totalHolders) }}
                      </v-chip>

                    </v-layout>

                    <v-layout pt-4 pb-4 :column="windowWidth < 770" v-if="correctTotalSupply">

                      <v-text-field
                        v-model="totalSupply"
                        label="Total Supply"
                        maxlength="100"
                        outlined
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="totalBurned"
                        label="Total Burned"
                        maxlength="100"
                        outlined
                      ></v-text-field>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="totalHolders"
                        label="Total Holders"
                        maxlength="100"
                        outlined
                      ></v-text-field>

                    </v-layout>

                    <v-layout v-if="showTotalSupply" column align-center pb-4>
                      <v-btn color="orange" outlined small @click="correctTotalSupply = true" v-if="!correctTotalSupply">
                        Click here if {{ windowWidth > 770 ? 'this information is' : '' }} not correct
                      </v-btn>
                      <v-btn style="margin-top:-20px" color="green" outlined small @click="correctTotalSupply = false" v-if="correctTotalSupply">
                        Save this information
                      </v-btn>
                    </v-layout>

                    <v-text-field
                      v-model="tokenName"
                      label="Token Name"
                      placeholder="Enter the token name"
                      maxlength="50"
                      outlined
                      :disabled="contractValid && tokenAddress !== ''"
                      :rules="[v => !!v]"
                    ></v-text-field>

                    <v-text-field
                      v-model="tokenSymbol"
                      label="Token Symbol"
                      placeholder="Enter the token symbol"
                      maxlength="15"
                      outlined
                      :disabled="contractValid && tokenAddress !== ''"
                      :rules="[v => !!v]"
                      @change="checkUpperCase"
                    ></v-text-field>

                    <p class="text-center font-weight-medium" style="margin-top:-10px">
                      This is the unique link that can be used on the social channels.<br>
                      <span :style="windowWidth < 770 ? 'font-size:14px;' : 'font-size:16px'" class="wrap-text font-weight-bold">
                        {{ windowWidth > 770 ? tokenLinkSlug : tokenSlug.substr(0, 35) + '...' }}
                        <v-icon color="grey lighten-3" small style="cursor: pointer;margin-top:-2px" icon v-clipboard="() => tokenLinkSlug" @success="handleSuccess" @error="handleError">mdi-content-copy</v-icon>
                      </span>
                    </p>

                    <p class="font-weight-bold mt-4">
                      Links to Website and Social
                    </p>
                    <v-text-field dense
                      v-model="websiteLink"
                      label="Website"
                      placeholder="Enter the link to the website"
                      maxlength="50"
                      outlined
                    ></v-text-field>
                    <v-text-field dense
                      v-model="telegramLink"
                      label="Telegram"
                      placeholder="Enter link to Telegram"
                      maxlength="50"
                      outlined
                    ></v-text-field>
                    <v-text-field dense
                      v-model="twitterLink"
                      label="Twitter"
                      placeholder="Enter link to Twitter"
                      maxlength="50"
                      outlined
                    ></v-text-field>
                    <v-text-field dense
                      v-model="redditLink"
                      label="Reddit"
                      placeholder="Enter link to Reddit"
                      maxlength="50"
                      outlined
                    ></v-text-field>
                    <v-text-field dense
                      v-model="discordLink"
                      label="Discord"
                      placeholder="Enter link to Discord"
                      maxlength="50"
                      outlined
                    ></v-text-field>

                    <p  class="font-weight-bold ">
                      Token Logo URL or click to Upload
                    </p>

                    <v-layout row wrap pa-3>

                      <v-layout column align-center v-if="!changeLogo" style="margin-top:-10px">
                        <v-img style="max-height:100px;max-width:100px;min-height:100px;min-width:100px;"
                           :src="logoUrl"
                         ></v-img>
                      </v-layout>
                      <v-btn :color="dark ? '#388E3C' : 'green lighten-3'" v-if="!changeLogo" @click="changeLogoClicked" style="margin-top:20px">
                        Change Logo
                      </v-btn>

                      <v-text-field :style="windowWidth < 770 ? 'width:100%' : 'width:60%'"
                        v-model="logoUrl" v-if="!logoUpload && changeLogo"
                        label="Logo URL"
                        placeholder="Logo URL or click to upload"
                        maxlength="1000"
                        outlined
                        @change="previewLogoChanged"
                        @input="previewLogoChanged"
                        :rules="[v => !!v]"
                      ></v-text-field>
                      <v-file-input v-model="files" outlined small-chips required v-if="logoUpload && changeLogo" :style="windowWidth < 770 ? 'max-width:300px' : ' '"
                            accept="image/png,image/jpg,image/jpeg" @change="handleFiles"
                            show-size label="Select your logo"
                            hint="Recommended size 250x250. Max 500KB."
                            persistent-hint>
                      </v-file-input>
                    <v-spacer v-if="windowWidth > 770"></v-spacer>

                    <v-layout column :style="windowWidth < 770 ? 'width:100%;' : 'width:30%;margin-top:-5px'">

                      <v-btn v-if="!previewLogo"
                        :disabled="logoUrl.indexOf('http') > -1"
                        :color="dark ? '#388E3C' : 'green lighten-3'"
                        @click="logoUpload = !logoUpload"
                        :style="windowWidth < 770 ? 'width:100%' : 'margin-bottom:2px'">{{ !logoUpload ? 'Upload Logo' : 'Logo URL' }}
                      </v-btn>
                      <v-btn v-if="!previewLogo" style="margin-top:5px" @click="changeLogoClickedCancel" small>Cancel</v-btn>

                      <v-layout column align-center v-else-if="previewLogo && changeLogo" style="margin-top:-20px">
                        <v-img style="max-height:100px;max-width:100px;min-height:100px;min-width:100px;"
                           :src="logoUrl"
                         ></v-img>
                      </v-layout>
                    </v-layout>
                </v-layout>

                <p class="font-weight-bold mt-2">
                  Token Launch Information
                </p>

                <v-checkbox
                  v-model="checkPresale"
                  :label="`Will there be a pre-sale? ${checkPresale ? 'Yes' : 'No'}`"
                ></v-checkbox>

                <!-- ######################### PRESALE DATE #########################-->

                <v-layout :column="windowWidth < 770" v-if="checkPresale" pb-2>
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="presaleDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="presaleDate"
                          label="Token Presale Start Date"
                          append-icon="mdi-calendar-month"
                          readonly
                          outlined
                          v-on="on"
                          hint="You can always change this later if needed"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="presaleDate" :min="todayDate1" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(presaleDate)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>

                    <v-spacer v-if="windowWidth > 770"></v-spacer>

                    <v-dialog
                      ref="dialog1"
                      v-model="modal1"
                      :return-value.sync="presaleTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="presaleTime"
                          label="Token Presale Time (Your Local Time)"
                          append-icon="mdi-clock-time-four-outline"
                          readonly
                          outlined
                          hint="Listing will show both Local and UTC"
                          persistent-hint
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="modal1"
                        v-model="presaleTime"
                        full-width
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal1 = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog1.save(presaleTime)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-layout>

                  <v-text-field v-if="checkPresale"
                    v-model="tokenPresale"
                    label="Token Pre-sale Link"
                    placeholder="Enter pre-sale link"
                    maxlength="150"
                    hint="Put 'To be Announced' if still unknown, you can add this later"
                    persistent-hint
                    outlined
                  ></v-text-field>

                  <p v-if="checkPresale" class="mt-2"></p>

               <!-- #########################LAUNCH DATE #########################-->

                <v-layout :column="windowWidth < 770">
                    <v-dialog
                      ref="dialog2"
                      v-model="modal2"
                      :return-value.sync="launchDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="launchDate"
                          label="Token Launch Date"
                          append-icon="mdi-calendar-month"
                          readonly
                          outlined
                          v-on="on"
                          hint="You cann always change this later if needed"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="launchDate"  scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal2 = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialog2.save(launchDate)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>

                    <v-spacer v-if="windowWidth > 770"></v-spacer>

                    <v-dialog
                      ref="dialog3"
                      v-model="modal3"
                      :return-value.sync="launchTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="launchTime"
                          label="Token Launch Time (Your Local Time)"
                          append-icon="mdi-clock-time-four-outline"
                          readonly
                          outlined
                          hint="Listing will show both Local and UTC"
                          persistent-hint
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="modal3"
                        v-model="launchTime"
                        full-width
                        format="24hr"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal3 = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialog3.save(launchTime)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>

                  </v-layout>

                  <p class="font-weight-bold mt-2">
                    Write something special about this token
                  </p>

                  <tiptap-vuetify
                    v-model="tokenDescription"
                    :extensions="extensions"
                    placeholder="Write or paste your text here..."
                    maxlength="5000"
                    :toolbar-attributes="dark ? { color: 'grey darken-2' } : { color: 'grey lighten-2' }"
                  />

                  <br><br>

                    <v-btn @click="updateTokenDetails" large :color="dark ? '#388E3C' : 'green lighten-5'"  block :disabled="!valid">Update Token Details</v-btn>
                    <p class="mt-0"></p>
                    <v-btn @click="cancelUpdate" text block >Cancel</v-btn>
                    <p class="mt-0"></p>
                    <v-btn v-if="(this.tokenStatus === 0 || this.tokenStatus === 5)" color="red darken-1" text @click="deleteDialog = true" block>Delete</v-btn>

                  </v-form>
                </v-col>


                <!-- ########################## Update Promotion ########################### -->


                <v-col cols="12" sm="12" md="12" style="padding-top:0px" v-if="toggleView === 'promote'">
                  <v-form v-model="valid" ref="form" dense >

                    <v-layout column  pl-2 pr-2 pb-4>

                      <span style="font-size:18px" class="font-weight-bold mt-4">

                        {{ promoteYes && expDate > 0 ? 'Current Promotion Package' : 'Select a promotion package' }}


                        <v-chip :style="windowWidth < 770 ? 'margin-right:5px;' : 'margin-right:30px;'" v-if="!promotionPaid && promotionPackage !== 0"
                          :color="dark ? 'orange darken-1' : 'red lighten-2'" label :small="windowWidth > 770"
                          :x-small="windowWidth < 770"
                        >
                          Unpaid
                        </v-chip>

                        <v-chip :style="windowWidth < 770 ? 'margin-right:5px;' : 'margin-right:30px;'" v-if="packageExpired && promotionPackage !== 0"
                          :color="dark ? 'red darken-1' : 'red lighten-2'" label :small="windowWidth > 770"
                          :x-small="windowWidth < 770"
                        >
                          Expired
                        </v-chip>

                        <v-chip :style="windowWidth < 770 ? 'margin-right:5px;' : 'margin-right:30px;'" v-if="promotionPaid && !packageExpired && expDate > 0"
                          :color="dark ? 'green darken-1' : 'green lighten-2'" label :small="windowWidth > 770"
                          :x-small="windowWidth < 770"
                        >
                          Active
                        </v-chip>
                      </span>

                      <p style="font-size:16px" class="font-weight-medium" v-if="promoteYes && expDate > 0">
                        Started: {{ makeDate(publishDate) }} - Expires: {{ makeDateTime(expDate) }}
                      </p>

                      <v-list dense two-line rounded :color="dark ? 'grey darken-3' : 'green lighten-5'" >
                         <template v-for="(item, index) in items">

                           <v-list-item :style="windowWidth < 770 ? 'padding-left:0px' : ''"
                             :key="index"
                           >
                             <v-list-item-avatar style="border-radius: 2px">
                               <v-icon :size="windowWidth < 770 ? 25 : 35">{{ item.avatar }}</v-icon>
                             </v-list-item-avatar>

                             <v-list-item-content >
                               <span :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text" v-html="item.title"></span>
                               <v-list-item-subtitle style="margin-top:5px" v-html="item.title_price_bnb"></v-list-item-subtitle>
                             </v-list-item-content>

                             <!-- <v-list-item-content v-else-if="tokenNetwork === 'ETH'">
                               <span :style="windowWidth < 770 ? 'font-size:14px' : 'font-size:18px'" class="wrap-text" v-html="item.title"></span>
                               <v-list-item-subtitle style="margin-top:5px" v-html="item.title_price_eth"></v-list-item-subtitle>
                             </v-list-item-content> -->

                             <v-list-item-action>
                               <v-checkbox @click="checkSelected(index)" :disabled="!packageExpired && expDate !== 0"
                                 v-model="item.selected"
                               ></v-checkbox>
                             </v-list-item-action>
                           </v-list-item>

                           <v-divider
                             v-if="parseInt(index + 1) < items.length"
                             :key="index + 'd'"
                           ></v-divider>

                         </template>
                       </v-list>


                      <v-layout column pt-4>
                        <p class="mt-0"></p>
                        <p v-if="!promoteYes" class="mt-0"></p>
                        <v-btn v-if="expDate === 0 && getUser.networkChainID === '0x38' || (getUser.networkChainID === '0x38' && !promotionPaid)" :disabled="promotionPackage === 0" @click="transferBNBNow()" :loading="loadingData" :color="dark ? '#388E3C' : 'green lighten-3'" large>
                          <v-icon left size="25">mdi-arrow-right-bold</v-icon>Activate
                        </v-btn>
                        <v-btn v-if="getUser.networkChainID !== '0x38'" text :color="dark ? '#388E3C' : 'green lighten-1'" large>
                          <v-icon left >mdi-send</v-icon>Please, switch to the BSC Mainnet
                        </v-btn>
                        <p v-if="!promoteYes" class="mt-0"></p>
                        <v-btn v-if="packageExpired && getUser.networkChainID === '0x38'" :disabled="!promoteYes" @click="transferBNBNow()" :loading="loadingData" :color="dark ? '#388E3C' : 'green lighten-3'" large>
                          <v-icon left size="25">mdi-arrow-right-bold</v-icon>Renew
                        </v-btn>

                      </v-layout>

                    </v-layout>

                  </v-form>
                </v-col>


                <!-- ########################## Update Vetting ########################### -->


                <v-col cols="12" sm="12" md="12" style="padding-top:0px" v-if="toggleView === 'vetting'">
                  <v-form v-model="valid" ref="form" dense >

                    <p class="mt-2"></p>
                    <p class="font-weight-bold mt-2" v-if="previewItem.likes < 300">
                      Your token currently has {{ previewItem.likes }} Likes. <br> When the token reaches reach 300 Likes, Token Monster will start the vetting process.
                    </p>

                    <v-layout column pa-2 pt-4 style="width:100%">
                      <v-alert
                       border="top"
                       colored-border
                       color="#388E3C"
                       elevation="2"
                       :dark="dark"
                     >
                       <strong>Under Development - Coming Soon!</strong><br><br>
                       Token vetting will be made available by Token Monster. We will ask the token Owner to provide the following information.
                     </v-alert>
                   </v-layout>

                    <p class="font-weight-bold mt-2" v-if="previewItem.likes > 299">
                      Your token currently has {{ previewItem.likes }} Likes. <br>
                      Please submit the vetting information below.<br>
                      The vetting scrore will be visible to the users and improve the token's credibility.
                    </p>

                    <strong>Token Vetting</strong><br>

                    Telegram Admin verification<br>

                    Owners and Team are fully doxxed<br>

                    Proof of Liquidity Lock (Min. 2 Months or more)<br>

                    Provide LP Address<br>

                    Proof of ownersrship renounced?<br>

                    Listed on CoinGecko?<br>

                    Listed on CMC?<br><br>

                    Result will be a Vetting scrore<br>

                  </v-form>
                </v-col>


              </v-card>
            </v-layout>

          </v-sheet>
          <p class="mt-2"></p>
        </v-layout >

        <v-layout column align-center pt-1 v-else-if="myTokens.length !== 0 && !loadingData">

          <v-card
            :color="dark ? '#388E3C' : 'green lighten-5'"
            :dark="dark"
            :min-width="windowWidth < 770 ? '' : 800"
            :width="windowWidth < 770 ? '' : '100%'"
          >

          <p class="mt-0"></p>

          <p style="font-size:24px" class="text-center font-weight-bold" >Manage Tokens</p>

          <v-list :dark="dark" tile shaped >
              <v-layout pl-2 pr-2>
                <v-text-field
                  v-model="searchTokens"
                  outlined dense
                  :dark="dark"
                  placeholder="Type to search or filter..."
                  maxlength="100"
                  single-line
                  append-icon="mdi-magnify"
                ></v-text-field>
              </v-layout>
              <v-layout pl-2 pr-2>
                <v-subheader style="margin-top:-25px;padding-left:0px" class="subtitle-2 font-weight-bold">Showing last ({{ myTokens.length }})  items</v-subheader>
                <v-spacer></v-spacer>
                <v-btn small outlined color="grey" style="margin-top:-15px" @click="showMore">Load More
                </v-btn>
              </v-layout>
                <template v-for="(item, i) in filteredTokens">
                  <v-list-item link style="padding-right:5px;padding-left:5px"
                    :key="i" @click="editTokenItem(item)"
                  >
                    <v-list-item-icon style="margin-right: 16px;">
                      <v-layout column align-center :style="item.promoted ? '' : 'padding-left:16px'">
                        <v-list-item-avatar style="border-radius: 2px">
                           <v-img v-if="item.logo !== ''" :src="item.logo"></v-img>
                           <v-img v-if="item.logo === ''" :src="tempImage"></v-img>
                         </v-list-item-avatar>
                        <span v-if="item.promoted" style="color:#388E3C;font-weight:bold;font-size:11px">Promoted</span>
                      </v-layout>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold" >{{ item.name }} ({{ item.symbol }}) -

                        <span style="font-size:18px" class="font-weight-bold mt-4">

                          <v-chip :style="windowWidth < 770 ? 'margin-right:5px;' : 'margin-right:30px;'" v-if="!item.promoted_paid && item.promoted_package !== 0"
                            :color="dark ? 'orange darken-1' : 'orange lighten-2'" label :small="windowWidth > 770"
                            :x-small="windowWidth < 770"
                          >
                            Promotion Unpaid
                          </v-chip>

                          <v-chip :style="windowWidth < 770 ? 'margin-right:5px;' : 'margin-right:30px;'" v-if="item.expired_check && item.promoted_package !== 0"
                            :color="dark ? 'red darken-1' : 'red lighten-2'" label :small="windowWidth > 770"
                            :x-small="windowWidth < 770"
                          >
                            Promotion Ended
                          </v-chip>

                          <v-chip :style="windowWidth < 770 ? 'margin-right:5px;' : 'margin-right:30px;'" v-if="item.promoted_paid && !item.expired_check && item.expire_date > 0"
                            :color="dark ? 'green darken-1' : 'green lighten-2'" label :small="windowWidth > 770"
                            :x-small="windowWidth < 770"
                          >
                            Promotion Active
                          </v-chip>
                        </span>

                      </v-list-item-title>
                      <v-list-item-title v-if="item.promoted" :class="windowWidth < 770 ? 'caption' : 'subheading'">{{ tokenStatuses[item.status].name }} {{ tokenStatuses[item.status].name === 'Published' ? '-- ' + makeDate(item.publish_date) : '' }} -- Promotion Expires: {{ makeDate(item.expire_date) }}</v-list-item-title>
                      <v-list-item-title v-if="!item.promoted" :class="windowWidth < 770 ? 'caption' : 'subheading'">{{ tokenStatuses[item.status].name }} {{ tokenStatuses[item.status].name === 'Published' ? '-- ' + makeDate(item.publish_date) : '' }} -- Not Promoted</v-list-item-title>
                      <v-list-item-title :class="windowWidth < 770 ? 'caption' : 'subheading'">Impressions: {{ item.impressions }}  --- Views: {{ item.views }}  --- Likes: {{ item.likes }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-layout>

                        <v-layout column align-center :style="windowWidth < 770 ? 'margin-right:10px;margin-top:5px' : 'margin-right:30px;margin-top:5px'">
                          <v-icon v-if="item.network === 'ETH'" color="blue-grey lighten-1">
                            mdi-ethereum
                          </v-icon>
                          <v-img v-if="item.network === 'BSC'" src="/img/icons/binance-coin-logo.png" style="max-width:22px;max-height:22px;padding-right:10px">
                          </v-img>
                          <v-img v-if="item.network === 'MATIC'" src="/img/icons/polygon.png" style="max-width:22px;max-height:22px;padding-right:10px">
                          </v-img>
                          <v-img v-if="item.network === 'KCC'" src="/img/icons/kucoin.png" style="max-width:22px;max-height:22px;padding-right:10px">
                          </v-img>
                        </v-layout>

                        <v-btn icon v-if="tokenStatuses[item.status].id === 2">
                          <v-icon color="green lighten-1">
                            mdi-eye-check-outline
                          </v-icon>
                        </v-btn>
                        <v-btn icon v-if="tokenStatuses[item.status].id > 2">
                          <v-icon color="red lighten-1">
                            mdi-eye-off-outline
                          </v-icon>
                        </v-btn>

                        <v-menu bottom left>
                          <template v-slot:activator="{ on }">
                            <v-btn icon >
                              <v-icon color="grey darken-2" v-on="on">
                                mdi-dots-vertical
                              </v-icon>
                            </v-btn>
                          </template>

                          <v-list dense>
                            <v-list-item link
                              @click="editTokenItem(item)"
                            >
                            <v-list-item-icon>
                              <v-icon >mdi-square-edit-outline</v-icon>
                            </v-list-item-icon>
                              <v-list-item-title>Edit Token</v-list-item-title>
                            </v-list-item>

                            <!-- <v-list-item link
                              @click="previewNews(item)"
                            >
                            <v-list-item-icon>
                              <v-icon >mdi-eye-outline</v-icon>
                            </v-list-item-icon>
                              <v-list-item-title>Preview Token</v-list-item-title>
                            </v-list-item> -->

                            <v-list-item link v-if="tokenStatuses[item.status].id < 4"
                              @click="updateTokenItemStatus(item, item.status === 3 ? item.status - 1 : item.status +  1)"
                            >
                            <v-list-item-icon>
                              <v-icon v-if="tokenStatuses[item.status].id === 0">mdi-check-circle-outline</v-icon>
                              <v-icon v-if="tokenStatuses[item.status].id === 1">mdi-text-box-check-outline</v-icon>
                              <v-icon v-if="tokenStatuses[item.status].id === 2">mdi-pause-circle-outline</v-icon>
                              <v-icon v-if="tokenStatuses[item.status].id === 3">mdi-play-circle-outline</v-icon>
                            </v-list-item-icon>
                              <v-list-item-title>{{ tokenStatuses[item.status].id === 0 ? 'Approve' : tokenStatuses[item.status].id === 1 ? 'Publish' : tokenStatuses[item.status].id === 2 ? 'Pause' : tokenStatuses[item.status].id === 3 ? 'Resume' : 'N/A'}}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-layout>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="i + 'A'" :dark="dark" v-if="(i + 1) < myTokens.length"></v-divider>
                </template>
            </v-list>
          </v-card>
          <p class="mt-4"></p>
          <v-btn
            :style="windowWidth < 770 ? 'margin-bottom:10px' : 'margin-bottom:20px'"
            fixed
            fab
            :dark="dark"
            bottom
            right
            color="#388E3C"
            to="/promote"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-layout>

        </v-layout>
       </v-slide-y-transition>
     </v-layout>

     <!-- ############################################ DIALOGS ################################################# -->

     <v-dialog v-model="contractLoadingDialog" persistent max-width="400">
       <v-card
        :dark="dark"
       >
       <v-toolbar flat>
         <v-card-title style="font-size:18px" class="font-weight-bold">Loading Contract Data</v-card-title>
         <v-spacer></v-spacer>
         <v-btn icon @click="contractLoadingDialog = false">
           <v-icon>mdi-close</v-icon>
         </v-btn>
       </v-toolbar>

       <v-layout column align-center pa-4>
         <p style="font-size: 12px">Checking the contract informationon the {{ tokenNetwork }} network.</p>
         <v-progress-linear
          color="#388E3C"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
       </v-layout>

         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn
              color="grey darken-1"
              text
              @click="contractLoadingDialog = false"
            >
              Close
            </v-btn>
         </v-card-actions>

       </v-card>
     </v-dialog>

     <v-dialog v-model="renewProgressDialog" persistent max-width="400">
       <v-card
        :dark="dark"
       >
       <v-toolbar flat>
         <v-card-title style="font-size:18px" class="font-weight-bold">Processing Transaction</v-card-title>
       </v-toolbar>

       <v-layout column align-center pl-4 pr-4 pb-4>
         <v-progress-linear
            color="#388E3C"
            indeterminate
            rounded
            height="8"
          ></v-progress-linear>
       </v-layout>
       </v-card>
     </v-dialog>

     <v-dialog v-model="contractExistsDialog" persistent max-width="400">
       <v-card
        :dark="dark"
       >
       <v-toolbar flat>
         <v-card-title style="font-size:18px" class="font-weight-bold">Contract Address Exists!</v-card-title>
         <v-spacer></v-spacer>
         <v-btn icon @click="contractExistsDialogClose">
           <v-icon>mdi-close</v-icon>
         </v-btn>
       </v-toolbar>

       <v-layout column align-center pa-4>
         <p>The contract address that you are trying to add is already registered on Token Monster.<br>
            Please check the address.</p>
       </v-layout>

         <v-card-actions>
           <v-spacer></v-spacer>
           <v-btn
              color="grey darken-1"
              text
              @click="contractExistsDialogClose"
            >
              Close
            </v-btn>
           <v-btn color="green darken-3" text @click="contractExistsLearnMore">Learn more</v-btn>
         </v-card-actions>

       </v-card>
     </v-dialog>

    <v-snackbar :timeout="4000" :bottom="'bottom'" v-model="snackbar">
      <v-icon color="green">mdi-check-circle-outline</v-icon>
      <span style="margin-left:5px" >{{ snackbarText }}</span>
            <v-btn text color="pink" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import store from '@/store/index'
import { db } from '@/main'
import TokenMonsterAPI from '@/clients/TokenMonsterAPI'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import {scroller} from 'vue-scrollto/src/scrollTo'
const dateformat = require('dateformat')
export default {
  name: 'managetokens',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean,
    scrollY: Number
  },
  data () {
    return {
      lang: this.$store.state.lang,
      loading: true,
      loadingData: false,
      showBottomNav: false,
      snackbar: false,
      snackbarWarning: false,
      snackbarText: '',
      renewProgressDialog: false,
      contractLoadingDialog: false,
      contractExistsDialog: false,
      tokenObs: null,
      show: false,
      view: 1,
      nowList: new Date().getTime(),
      toggleView: 'details',
      updateTokenItem: false,
      newTokenItem: false,
      searchTokens: '',
      resultInterval: null,
      valid: false,
      newPostKey: {},
      limit: 20,
      myTokens: [],
      previewItem: {},
      tokenStatus: '',
      tokenStatuses: [
        {name: 'Draft', id: 0},
        {name: 'Approved', id: 1},
        {name: 'Published', id: 2},
        {name: 'Paused', id: 3},
        {name: 'Expired', id: 4},
        {name: 'Cancelled', id: 5}
      ],
      logoSrcIcon: '',
      tokenNetwork: '',
      tokenAddress: '',
      tokenAddressFirst: true,
      tokenName: '',
      tokenSymbol: '',
      tokenSlug: '',
      tokenLinkSlug: '',
      checkExists: false,
      contractValid: true,
      totalSupply: 0,
      decimals: 0,
      showTotalSupply: false,
      correctTotalSupply: false,
      totalHolders:  0,
      showTotalHolders: false,
      totalBurned: 0,
      showTotalBurned: false,
      websiteLink: '',
      telegramLink: 'https://t.me/',
      twitterLink: 'https://twitter.com/',
      redditLink: '',
      discordLink: '',
      logoUrl: '',
      logoUpload: false,
      changeLogo: false,
      previewLogo: false,
      logoImage: '',
      files: [],
      filesValid: false,
      todayDate1: new Date().toISOString().substr(0, 10),
      todayDate: new Date().toISOString().substr(0, 10),
      publishDate: 0,
      presaleDate: 0,
      presaleTime: 0,
      launchDate: 0,
      launchTime: 0,
      expDate: 0,
      nowExp: 0,
      packageExpired: false,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      checkPresale: false,
      tokenPresale: '',
      tokenDescription: '',
      promoteYes: false,
      promotionPackage: 0,
      promotionPaid: false,
      tokenTxId: '',
      showTxFields: false,
      countDown: 5,
      items: this.$store.state.packages,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    }
  },
  components: {
    TiptapVuetify
  },
  watch: {
    windowWidth () {
      if (this.windowWidth < 768) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
    },
    mmConnected () {
      if (!this.mmConnected) {
        this.$router.push('/')
      }
    },
    binanceConnected () {
      if (!this.binanceConnected) {
        this.$router.push('/')
      }
    },
    walletConnected () {
      if (!this.walletConnected) {
        this.$router.push('/')
      }
    },
    tokenNetwork () {
      if (this.tokenAddressFirst && this.tokenAddress !== '') {
        this.lookupTotalSupply()
      }
    }
  },
  computed: {
    getLanguage () {
      return this.$store.state.user.language
    },
    getPlatform () {
      // console.log(this.$store)
      // return this.$store.getters.state.device.platform
      return this.$store.state.device.platform
    },
    getUser () {
      return this.$store.state.user
    },
    getAccounts () {
      return this.$store.state.user.accounts
    },
    mmConnected () {
      return this.$store.state.user.mmConnected
    },
    binanceConnected () {
      return this.$store.state.user.binanceConnected
    },
    walletConnected () {
      return this.$store.state.user.walletConnected
    },
    getPaymentAddress () {
      return this.$store.state.paymentAddress
    },
    filteredTokens () {
      return this.myTokens.filter(item => {
        // console.log(item)
        if (this.searchTokens !== '') {
          return item.name.toLowerCase().indexOf(this.searchTokens.toLowerCase()) > -1 || item.symbol.toLowerCase().indexOf(this.searchTokens.toLowerCase()) > -1 || item.description.toLowerCase().indexOf(this.searchTokens.toLowerCase()) > -1// filter on dd fields??
        }
        return true
      })
    }
  },
  beforeDestroy () {
    clearInterval(this.resultInterval)
  },
  destroyed () {
    if (this.tokenObs !== null) this.tokenObs()
  },
  mounted () {
  },
  created () {
    store.commit('ShowNav')
    // store.commit('ShowNavHome')
    // console.log(this.getPlatform)
    if (!this.mmConnected && !this.binanceConnected && !this.walletConnected) {
      this.$router.push('/')
    }
    this.logoSrcIcon = './img/home/no_items.png'
    setTimeout(() => {
      this.loading = false
    }, 2000)
    this.init()
  },
  methods: {
    init () {
      if (this.windowWidth < 768) {
        this.showBottomNav = false
      } else {
        this.showBottomNav = false
      }
      for (var i in this.items) {
        this.items[i].selected = false
      }
      this.loadMyTokens('new')
    },
    loadMyTokens (type) {
      if (type === 'new') {
        this.loadingData = true
      }
      // console.log(this.getUser)
      if (this.tokenObs !== null) this.tokenObs()
      let query = db.collection('tokens').where('uid', '==', this.getUser.uid).where('status', '>', -1).orderBy('status').orderBy('created', 'desc').limit(this.limit)
      this.tokenObs = query.onSnapshot(querySnapshot => {
        // console.log(`Received query snapshot of size ${querySnapshot.size}`)
        if (querySnapshot.empty) {
          console.log('No matching documents.')
          this.loadingData = false
          return
        }
        this.myTokens = []
        querySnapshot.forEach(doc => {
          // console.log(doc.id, '=>', doc.data())
          var obj = doc.data()
          obj.id = doc.id
          obj.statusText = this.tokenStatuses[doc.data().status].name
          obj.expired_check = this.nowList > obj.expire_date
          this.myTokens.push(obj)
          this.loadingData = false
        })
        // console.log(this.myTokens)
        // ...
      }, err => {
        console.log(`Encountered error: ${err}`)
        this.loadingData = false
      })
    },
    showMore () {
      this.limit += 10
      this.loadMyTokens('new')
    },
    editTokenItem (item) {
      // console.log(item)
      this.newPostKey.id = item.id
      this.previewItem = item
      this.updateTokenItem = true

      this.tokenStatus = item.status
      this.tokenNetwork = item.network
      this.tokenAddress = item.contract_address
      this.tokenAddressFirst = item.contract_address === ''
      this.tokenSlug = item.slug
      this.tokenLinkSlug =  'https://tokenmonster.app/token/' + this.tokenSlug
      this.totalSupply = item.total_supply
      this.showTotalSupply = item.total_supply > 0
      this.totalBurned = '-'
      this.showTotalBurned = item.total_supply > 0
      this.totalHolders = '-'
      this.showTotalHolders = item.total_holders > 0
      this.tokenName = item.name
      this.tokenSymbol = item.symbol

      this.websiteLink =  item.link_website
      this.telegramLink = item.link_telegram
      this.twitterLink = item.link_twitter
      this.redditLink = item.link_reddit
      this.discordLink = item.link_discord

      this.logoUrl = item.logo
      this.previewLogo = true
      this.changeLogo = false
      this.logoUpload = !this.logoUrl.indexOf('http') > -1

      this.checkPresale = item.presale
      this.tokenPresale = item.presale_link
      this.publishDate = item.publish_date // automated from package selection
      this.presaleDate = item.launch_date_presale === undefined ? 0 : dateformat(new Date(item.launch_date_presale), 'yyyy-mm-dd')
      this.presaleTime = item.launch_date_presale === undefined ? 0 : dateformat(new Date(item.launch_date_presale), 'HH:MM')
      this.launchDate = dateformat(new Date(item.launch_date), 'yyyy-mm-dd')
      this.launchTime = dateformat(new Date(item.launch_date), 'HH:MM')
      this.expDate = item.expire_date // automated from package selection
      this.nowExp = new Date().getTime()
      this.packageExpired = this.nowExp > this.expDate
      if (this.expDate === 0) {
        this.packageExpired = false
      }

      this.tokenDescription = item.description

      this.promoteYes = item.promoted
      this.promotionPackage = item.promoted_package
      this.promotionPaid = item.promoted_paid

      if (this.promotionPackage > 0) {
        this.items[item.promoted_package - 1].selected = true
      }
      if (this.tokenAddress !== '') {
        this.lookupHoldersBurnEdit()
      }
      this.view = 2
      this.scrollToTop()
    },
    scrollToTop () {
      const firstScrollTo = scroller()
      setTimeout(() => {
        firstScrollTo('#managetokens')
      }, 200)
    },
    updateTokenDetails () {

      this.loadingData = true

      let searchkeys = []
      let splitName = this.tokenName.split(' ')
      for (var i in splitName) {
        searchkeys.push(splitName[i].toLowerCase())
      }
      searchkeys.push(this.tokenName.toLowerCase())
      searchkeys.push(this.tokenSymbol.toLowerCase())
      searchkeys.push(this.tokenAddress.toLowerCase())
      searchkeys.push(this.tokenNetwork.toLowerCase())

      let dispatchObj = {
        // postkey: this.newPostKey.id,
        // uid: this.currentUser.uid,
        name: this.tokenName,
        symbol: this.tokenSymbol,
        network: this.tokenNetwork,
        contract_address: this.tokenAddress,
        slug: this.tokenSlug,
        link_website: this.websiteLink,
        link_telegram: this.telegramLink,
        link_twitter: this.twitterLink,
        link_reddit: this.redditLink,
        link_discord: this.discordLink,
        keywords: searchkeys,
        logo: this.logoUrl,
        launch_date_presale: this.checkPresale ? new Date(this.presaleDate + ' ' + this.presaleTime).getTime() : 0,
        launch_date: new Date(this.launchDate + ' ' + this.launchTime).getTime(),
        presale: this.checkPresale,
        presale_link: this.tokenPresale,
        // publish_date: this.publishDate, // X Days before Launch Date if pakcage 1,2,3
        // expire_date: this.expDate, // Look at the package
        description: this.tokenDescription,
        status: this.tokenStatus,
        /* promoted: this.promoteYes,
        promoted_package: this.promotionPackage,
        promoted_paid: false,
        promoted_tx_id: '',
        */
        modified_by: this.getUser.displayName,
        modified: new Date().getTime(),
        total_supply: parseInt(this.totalSupply),
        total_holders: parseInt(this.totalHolders),
        total_burned: parseFloat(this.totalBurned),
        total_decimals: this.decimals
      }
      // console.log(dispatchObj)
      db.collection('tokens').doc(this.newPostKey.id).update(dispatchObj)
      .then(() => {
          // console.log('News Item in bucket updated')
          this.snackbarText = 'Token Details Updated!'
          this.snackbar = true
          this.loadMyTokens('update')
        })
      .catch(error => {
          console.log(error)
        })

    },
    checkSelected (index) {
      // console.log(this.items[index].selected)
      var count = 0
      if (this.items[index].selected) {
        this.promoteYes = true
        this.promotionPackage = index + 1
      } else {
        count += 1
      }
      for (var i in this.items) {
        if (parseInt(i) !== index) {
          this.items[i].selected = false
          count += 1
        }
      }
      if (count === 5) {
        this.promoteYes = false
        this.promotionPackage = 0
      }
    },
    transferBNBNow () {
      // console.log('BNB Transaction')
      // Check Network ChainID
      // console.log(window.ethereum.networkVersion, 'window.ethereum.networkVersion'); ///  must be 56

      if (this.getUser.walletProvider === 'MetaMask' || this.getUser.walletProvider === 'WalletConnect') {
        window.ethereum.request({
          method: 'eth_sendTransaction',
          params: [
            {
              input: 'SEND BNB',
              from: this.getAccounts[0],
              to: this.getPaymentAddress,
              value: this.items[this.promotionPackage - 1].price_hex,
              gas: '0x5208',
              gasPrice: '0xBA43B7400',
              chainId: '0x38'
            },
          ],
        })
        .then((txHash) => {
          this.renewProgressDialog = true
          this.tokenTxId = txHash
          this.showTxFields = true
          this.resultInterval = setInterval(() => {
          this.countDown -= 1
            if (this.countDown === 0) {
              clearInterval(this.resultInterval)
              this.updateTxId()
            }
          }, 1000)
        })
        .catch((error) => console.log(error))

      } else if (this.getUser.walletProvider === 'BinanceChainWallet') {
        window.BinanceChain.request({
           method: 'eth_sendTransaction',
           params: [
             {
               from: this.getAccounts[0],
               to: this.getPaymentAddress,
               value: this.items[this.promotionPackage - 1].price_hex,
               gas: '0x5208',
               gasPrice: '0xBA43B7400'
             },
           ],
         })
         .then((txHash) => {
           this.renewProgressDialog = true
           this.tokenTxId = txHash
           this.showTxFields = true
           this.resultInterval = setInterval(() => {
           this.countDown -= 1
             if (this.countDown === 0) {
               clearInterval(this.resultInterval)
               this.updateTxId() // updateTokenPromotionDetails
             }
           }, 1000)
         })
         .catch((error) => console.log(error))
      }
    },
    updateTxId () { // updateTokenPromotionDetails
      // console.log('Update Tx Id')
      let publishTokenDate = new Date().getTime()
      let expireTokenDate = 0
      if (this.promoteYes) {
        // Check the package and network etc.
        if (this.promotionPackage === 1) {
          publishTokenDate = new Date(this.launchDate + ' ' + this.launchTime).getTime() - 3 * 24 * 60 * 60 * 1000
          expireTokenDate = publishTokenDate + 7 * 24 * 60 * 60 * 1000
        } else if (this.promotionPackage === 2) {
          publishTokenDate = new Date(this.launchDate + ' ' + this.launchTime).getTime() - 7 * 24 * 60 * 60 * 1000
          expireTokenDate = publishTokenDate + 14 * 24 * 60 * 60 * 1000
        } else if (this.promotionPackage === 3) {
          publishTokenDate = new Date(this.launchDate + ' ' + this.launchTime).getTime() - 15 * 24 * 60 * 60 * 1000
          expireTokenDate = publishTokenDate + 30 * 24 * 60 * 60 * 1000
        } else if (this.promotionPackage === 4) {
          // publishTokenDate = new Date().getTime()
          expireTokenDate = new Date().getTime() + 15 * 24 * 60 * 60 * 1000
        } else if (this.promotionPackage === 5) {
          // publishTokenDate = new Date().getTime()
          expireTokenDate = new Date().getTime() + 30 * 24 * 60 * 60 * 1000
        }
      }
      let dispatchObj = {
        publish_date: publishTokenDate, // X Days before Launch Date if pakcage 1,2,3
        expire_date: expireTokenDate,
        promoted: true,
        promoted_package: this.promotionPackage,
        promoted_tx_id: this.tokenTxId,
        promoted_paid: true
      }
      db.collection('tokens').doc(this.newPostKey.id).update(dispatchObj)
      .then(() => {
        // console.log('Promotion and Tx Id in token bucket updated')
        this.renewProgressDialog = false
        this.snackbarText = 'Promotion Activated!'
        this.snackbar = true
        setTimeout(() => {
            this.cancelUpdate()
        }, 2000)
      })
      .catch(error => {
          console.log(error)
        })
    },
    updateTokenItemStatus (item, status) {
      // console.log(item)
      let dispatchObj = {
        status: status
      }
      db.collection('tokens').doc(item.id).update(dispatchObj)
      .then(() => {
          // console.log('Item Status in token bucket updated')
          // this.snackbarText = this.lang[this.getLanguage].NEWS_ARTICLE_UPDATED
          // this.snackbar = true
        })
      .catch(error => {
          console.log(error)
        })
    },
    changeLogoClicked () {
      this.changeLogo = true
      this.logoUpload = false
      this.previewLogo = false
      this.logoUrlOld = this.logoUrl
      this.logoUrl = ''
      // console.log(this.logoUrlOld)
    },
    changeLogoClickedCancel() {
      this.changeLogo = false
      this.logoUpload = true
      this.previewLogo = true
      this.logoUrl = this.logoUrlOld
    },
    cancelUpdate () {
      this.updateTokenItem = false
      this.view = 1
      // set the packages back to not selected
      for (var i in this.items) {
        this.items[i].selected = false
      }
    },
    lookupTotalSupply () {
      this.totalSupply = 0
      this.contractValid =  true
      if (this.tokenAddress !== '') {
        // Check in fb if contract address already used\
        let query = db.collection('tokens').where('contract_address', '==', this.tokenAddress).limit(1)
        query.get()
        .then(snapshot => {
          if (snapshot.empty) {
            console.log('No matching documents.')
            this.contractLoadingDialog = true
            this.checkExists = false

            if (this.tokenNetwork == 'KCC') {
              this.getTokenInfoKCC()
              return
            }

            Promise.resolve(TokenMonsterAPI.getTotalSupply(this.tokenAddress, this.tokenNetwork))
            .then(result => {
              // console.log(result)
              if (result.data.data.result === "0") {
                this.contractLoadingDialog = false
                this.contractValid =  false
                this.showTotalSupply = false
                this.showTotalHolders = false
                this.showTotalBurned = false
                this.tokenName = ''
                this.tokenSymbol = ''
                return
              } else {
                // console.log(parseInt(result.data.result) / 1000000000)
                var tempTotalSupply = result.data.data.result

                Promise.resolve(TokenMonsterAPI.getTotalHolders(this.tokenAddress, this.tokenNetwork))
                .then(result => {
                  // console.log(result.data.data.toString())
                  var html = result.data.data.toString()
                  var startTitle = html.indexOf('<title>')
                  var endTitle = html.indexOf('</title>')
                  var tokenTotalName = html.substr(startTitle + 7, endTitle)
                  var tab = RegExp("\\t", "g");
                  tokenTotalName = tokenTotalName.replace(tab, '')
                  var nameIndexEnd = tokenTotalName.indexOf('(')
                  var nameIndexEnd2 = tokenTotalName.indexOf(')')
                  var tokenName = tokenTotalName.substr(1, nameIndexEnd - 2)
                  tokenName = tokenName.replace(/(\r\n|\n|\r)/gm, '')
                  var check = nameIndexEnd2 - nameIndexEnd - 1
                  var tokenSymbol = tokenTotalName.substr(nameIndexEnd + 1, check)
                  /* console.log('startTitle')
                  console.log(startTitle)
                  console.log('endTitle')
                  console.log(endTitle)
                  console.log(tokenTotalName)
                  console.log(tokenName) */
                  this.tokenName = tokenName
                  this.tokenSymbol = tokenSymbol

                  var startIndex = html.indexOf('<div class="mr-3">')
                  // var stopIndex = html.indexOf('addresses')
                  var stopIndex = 0
                  var holders = 0
                  if (this.tokenNetwork === 'BSC') {
                    stopIndex = this.getIndicesOf('addresses', html)
                    holders = html.substr(startIndex + 18, (stopIndex[1] - 1) - (startIndex + 18))
                  } else {
                    stopIndex = html.indexOf('sparkholderscontainer')
                    holders = html.substr(startIndex + 18, (stopIndex - 17) - (startIndex + 18))
                  }
                  /*console.log('startIndex')
                  console.log(startIndex)
                  console.log('stopIndex')
                  console.log(stopIndex) */
                  // console.log(holders)
                  this.totalHolders = parseInt(holders.replaceAll(',', ''))
                  this.showTotalHolders = true
                  this.contractLoadingDialog = false

                  var startDecimals = html.indexOf("'decimals':")
                  var decTemp
                  var decimals
                  if (startDecimals === -1) {
                    startDecimals = html.indexOf("&decimal=")
                    decTemp = html.substr(startDecimals + 9, 3)
                    decimals = decTemp.replaceAll('\'', '')
                    /* console.log('startDecimals')
                    console.log(startDecimals)
                    console.log(decTemp)
                    console.log(decimals) */
                    this.decimals = parseInt(decimals)
                  } else {
                    decTemp = html.substr(startDecimals + 12, 4)
                    decimals = decTemp.replaceAll('\'', '').replace(',', '')
                    /* console.log('startDecimals')
                    console.log(startDecimals)
                    console.log(decTemp)
                    console.log(decimals) */
                    this.decimals = parseInt(decimals)
                  }

                  this.totalSupply = Math.round(parseInt(tempTotalSupply) / Math.pow(10, this.decimals))
                  this.showTotalSupply = true
                })
                .catch(err => {
                  this.loading = false
                  console.log('Error getting documents.', err)
                  // show friendly error in user screen
                })

                Promise.resolve(TokenMonsterAPI.getTotalBurned(this.tokenAddress, this.tokenNetwork))
                .then(result => {
                  // console.log(result.data.data.toString())
                  var html = result.data.data.toString()
                  var startBurnIndex = html.indexOf('0x00000000000000000000')
                  // console.log('startBurnIndex')
                  // console.log(startBurnIndex)
                  var textBurn = html.substr(startBurnIndex, 270)
                  // console.log('textBurn')
                  // console.log(textBurn)
                  var endBurnIndex = textBurn.indexOf('%')
                  var finalTextBurn = textBurn.substr(endBurnIndex - 7, 7)
                  // console.log('finalTextBurn')
                  // console.log(finalTextBurn)
                  if  (startBurnIndex === -1) {
                    this.totalBurned = 0
                    this.showTotalBurned = true
                  } else {
                    finalTextBurn = finalTextBurn.replace('>', '')
                    this.totalBurned = parseFloat(finalTextBurn)
                    this.showTotalBurned = true
                  }
                  // this.contractLoadingDialog = false
                })
                .catch(err => {
                  this.loading = false
                  console.log('Error getting documents.', err)
                  // show friendly error in user screen
                })
              }
            })
            .catch(err => {
              this.loading = false
              console.log('Error getting documents.', err)
              // show friendly error in user screen
            })
          }
          snapshot.forEach(() => {
            // console.log('Token Address already exists in Token Monster')
            // console.log(doc.id, '=>', doc.data())
            this.contractLoadingDialog = false
            this.contractValid = false
            this.checkExists = true
            this.contractExistsDialog = true
          })
        })
        .catch(err => {
          console.log('Error getting documents', err)
        })
      } else {
        this.contractLoadingDialog = false
        this.contractValid = true
        this.showTotalSupply = false
        this.showTotalHolders = false
        this.showTotalBurned = false
        this.tokenName = ''
        this.tokenSymbol = ''
        this.totalSupply = 0
        this.totalBurned = 0
        this.totalHolders = 0
      }
    },
    getTokenInfoKCC () {
      console.log('Yai, it\'s a KCC token')

      Promise.resolve(TokenMonsterAPI.getTokenInfoKCC(this.tokenAddress, this.tokenNetwork))
      .then(result => {
        console.log(result) // result.data.data.data.tokenInfo
        // result.data.data.data.holderCnt
        var tokenInfo
        if (result.data.data.code === 1) {

          tokenInfo = result.data.data.data.tokenInfo
          this.tokenName = tokenInfo.f
          this.tokenSymbol = tokenInfo.s
          this.decimals = parseInt(tokenInfo.d)
          this.totalSupply = parseInt(tokenInfo.t)
          this.totalHolders = result.data.data.data.holderCnt
          this.totalBurned = 0

          if (typeof tokenInfo.l !== 'undefined') {
            this.logoUrl = tokenInfo.l
            this.previewLogoChanged()
          }

          this.contractLoadingDialog = false
          this.contractValid =  true
          this.showTotalSupply = true
          this.showTotalHolders = true
          this.showTotalBurned = true

        } else {
          this.contractLoadingDialog = false
          this.contractValid =  false
          this.showTotalSupply = false
          this.showTotalHolders = false
          this.showTotalBurned = false
          this.tokenName = ''
          this.tokenSymbol = ''
          this.logoUrl = ''
          this.previewLogoChanged()
        }
      })
      .catch(err => {
        this.loading = false
        console.log('Error getting KCC token info.', err)
        // show friendly error in user screen
      })
    },
    lookupHoldersBurnEdit () {
      // Lookup the holders when editing for the user
      //
      if (this.tokenNetwork == 'KCC') {
        this.getTokenInfoKCC()
        return
      }

      Promise.resolve(TokenMonsterAPI.getTotalHolders(this.tokenAddress, this.tokenNetwork))
      .then(result => {
        // console.log(result.data.data.toString())
        var html = result.data.data.toString()
        // var startTitle = html.indexOf('<title>')
        // var endTitle = html.indexOf('</title>')
        // var tokenTotalName = html.substr(startTitle + 7, endTitle)
        // var tab = RegExp("\\t", "g");
        // tokenTotalName = tokenTotalName.replace(tab, '')
        // var nameIndexEnd = tokenTotalName.indexOf('(')
        // var nameIndexEnd2 = tokenTotalName.indexOf(')')
        // var tokenName = tokenTotalName.substr(1, nameIndexEnd - 2)
        // tokenName = tokenName.replace(/(\r\n|\n|\r)/gm, '')
        // var check = nameIndexEnd2 - nameIndexEnd - 1
        // var tokenSymbol = tokenTotalName.substr(nameIndexEnd + 1, check)
        /* console.log('startTitle')
        console.log(startTitle)
        console.log('endTitle')
        console.log(endTitle)
        console.log(tokenTotalName)
        console.log(tokenName) */
        // this.tokenName = tokenName
        // this.tokenSymbol = tokenSymbol

        var startIndex = html.indexOf('<div class="mr-3">')
        // var stopIndex = html.indexOf('addresses')
        var stopIndex = 0
        var holders = 0
        if (this.tokenNetwork === 'BSC') {
          stopIndex = this.getIndicesOf('addresses', html)
          holders = html.substr(startIndex + 18, (stopIndex[1] - 1) - (startIndex + 18))
        } else {
          stopIndex = html.indexOf('sparkholderscontainer')
          holders = html.substr(startIndex + 18, (stopIndex - 17) - (startIndex + 18))
        }
        /*console.log('startIndex')
        console.log(startIndex)
        console.log('stopIndex')
        console.log(stopIndex) */
        // console.log(holders)
        this.totalHolders = parseInt(holders.replaceAll(',', ''))
        this.showTotalHolders = true
        this.contractLoadingDialog = false

        var startDecimals = html.indexOf("'decimals':")
        var decTemp
        var decimals
        if (startDecimals === -1) {
          startDecimals = html.indexOf("&decimal=")
          decTemp = html.substr(startDecimals + 9, 3)
          decimals = decTemp.replaceAll('\'', '')
          console.log('startDecimals')
          console.log(startDecimals)
          console.log(decTemp)
          console.log(decimals)
          this.decimals = parseInt(decimals)
        } else {
          decTemp = html.substr(startDecimals + 12, 4)
          decimals = decTemp.replaceAll('\'', '').replace(',', '')
          console.log('startDecimals')
          console.log(startDecimals)
          console.log(decTemp)
          console.log(decimals)
          this.decimals = parseInt(decimals)
        }

        Promise.resolve(TokenMonsterAPI.getTotalBurned(this.tokenAddress, this.tokenNetwork))
        .then(result => {
          // console.log(result.data.data.toString())
          var html = result.data.data.toString()
          var startBurnIndex = html.indexOf('0x00000000000000000000')
          // console.log('startBurnIndex')
          // console.log(startBurnIndex)
          var textBurn = html.substr(startBurnIndex, 270)
          // console.log('textBurn')
          // console.log(textBurn)
          var endBurnIndex = textBurn.indexOf('%')
          var finalTextBurn = textBurn.substr(endBurnIndex - 7, 7)
          // console.log('finalTextBurn')
          // console.log(finalTextBurn)
          if  (startBurnIndex === -1) {
            this.totalBurned = 0
            this.showTotalBurned = true
          } else {
            finalTextBurn = finalTextBurn.replace('>', '')
            this.totalBurned = parseFloat(finalTextBurn)
            this.showTotalBurned = true
          }
          // this.contractLoadingDialog = false
        })
        .catch(err => {
          this.loading = false
          console.log('Error getting documents.', err)
          // show friendly error in user screen
        })
      })
      .catch(err => {
        this.loading = false
        console.log('Error getting documents.', err)
        // show friendly error in user screen
      })
    },
    previewLogoChanged () {
      if (this.logoUrl.indexOf('http') > -1 || this.logoUrl !== '') {
        this.previewLogo = true
      } else {
        this.previewLogo = false
      }
    },
    handleFiles () {
      // console.log(this.files)
      if (this.files === undefined || this.files === null) {
        // console.log('this.files undefined')
        this.logoUrl = ''
        this.previewLogo = false
        this.filesValid = false

      } else if (this.files.size > 500000) { // === 500 KB
        this.files = []
        this.showRowAlert = true
        this.showRowAlertText = 'Maximum file size of 500KB exeeded for ' + this.files.name + '!'

      } else {
        this.filesValid = true
        this.logoUrl = this.files.name
        this.useImage()
      }
    },
    useImage () {
      // console.log(this.files)
      if (this.logoUrl === '') return
      this.logoUrl = this.files.name
      this.getBase64(this.files).then(
        data => this.logoUrl = data
      )
      this.previewLogoChanged()
    },
    checkUpperCase () {
      this.tokenSymbol = this.tokenSymbol.toUpperCase()
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      })
    },
    contractExistsDialogClose () {
      this.tokenAddress = ''
      this.contractExistsDialog = false
    },
    contractExistsLearnMore () {
      this.tokenAddress = ''
      this.contractExistsDialog = false
      this.learnMoreDialog = true
    },
    getIndicesOf(searchStr, str, caseSensitive) {
        var searchStrLen = searchStr.length
        if (searchStrLen == 0) {
            return []
        }
        var startIndex = 0, index, indices = []
        if (!caseSensitive) {
            str = str.toLowerCase()
            searchStr = searchStr.toLowerCase();
        }
        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            indices.push(index)
            startIndex = index + searchStrLen
        }
        return indices;
    },
    handleSuccess(e) {
      console.log(e);
      this.snackbarText = 'Link copied to clipboard.'
      this.snackbar = true
    },
    handleError(e) {
      console.log(e);
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    makeDate (date) {
      return dateformat(new Date(date), 'dd mmm, yyyy')
    },
    makeDateTime (date) {
      return dateformat(new Date(date), 'dd mmm, yyyy HH:MM')
    },
    timeDifference(previous) {

      var current = Math.round(new Date() / 1000)
      console.log(current)
      var msPerMinute = 60
      var msPerHour = msPerMinute * 60
      var msPerDay = msPerHour * 24
      var msPerMonth = msPerDay * 30
      var msPerYear = msPerDay * 365
      var elapsed = current - previous

      if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
      }
      else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago'
      }
      else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago'
      }
      else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago'
      }
      else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago'
      }
      else {
        return Math.round(elapsed/msPerYear ) + ' years ago'
      }
    }
  }
}
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 2s
  }
  .fade-enter, .fade-leave-active {
    opacity: 0
  }
  .wrapperDark{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrapperLight{
    background-color: #FFF;
    display: flex;
    justify-content: center;
  }
  .wrap-text {
    word-break: normal;
  }
</style>
